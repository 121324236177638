<mat-drawer-container class="example-container" autosize id="screenshotArea">
  <mat-drawer *ngIf="checkToken()" opened="{{sideMenu}}" mode="{{sideMenuMode}}"
    (openedChange)="onDrawerToggle($event)">
    <div class="sidenav-main-div">
      <div class="row">
        <div class="col s12" [routerLink]="['/dashboard']" style="padding: 0px 14px;cursor: pointer;">
          <h2>
            <img src="assets/logo.png" height="45" class="img-responsive" />
            Super<b>See</b>
          </h2>
        </div>
      </div>
      <div class="nav-div">
        <div class="row nav-row {{ checkIsActive('/dashboard') }}" [routerLink]="['/dashboard']">
          <div class="col s12" (click)="checkSideMenu()">
            <h3>
              <span class="material-symbols-outlined"> dashboard </span>
              Dashboard
            </h3>
          </div>
        </div>
        <div class="row nav-row {{ checkIsActive('/employees') }}" [routerLink]="['/employees']">
          <div class="col s12" (click)="checkSideMenu()">
            <h3>
              <span class="material-symbols-outlined"> person </span>
              Employees
            </h3>
          </div>
        </div>

        <div class="row nav-row {{ checkIsActive('/projects') }}" (click)="moveTeamList()">
          <div class="col s12" (click)="checkSideMenu()">
            <h3>
              <span class="material-symbols-outlined"> groups </span>
              Teams
            </h3>
          </div>
        </div>
        <div class="row nav-row {{ checkIsActive('/user-report') }}" [routerLink]="['/user-report']">
          <div class="col s12" (click)="checkSideMenu()">
            <h3>
              <span class="material-symbols-outlined">
                summarize
              </span>
              Timesheet
            </h3>
          </div>
        </div>
        <div class="row nav-row {{ checkIsActive('/help-support') }}" [routerLink]="['/help-support']">
          <div class="col s12" (click)="checkSideMenu()">
            <h3>
              <span class="material-symbols-outlined">
                help
              </span>
              Help & Support
            </h3>
          </div>
        </div>
        <div *ngIf="isAdmin" class="row nav-row {{ checkIsActive('/employee/setting') }}"
          [routerLink]="['/employee/setting']">
          <div class="col s12" (click)="checkSideMenu()">
            <h3>
              <span class="material-symbols-outlined"> settings </span>
              Settings
            </h3>
          </div>
        </div>
        @if (isShowRenewPlan) {
        <div *ngIf="isAdmin" class="row nav-row {{ checkIsActive('/employee/setting') }}" [routerLink]="['/billings']"
          class="upgradeNowButton">
          <div class="col s12" (click)="checkSideMenu()">
            @if (quotaStatus == 'trial') {
            <h3><span class="material-symbols-outlined">shopping_cart</span> Buy plan </h3>
            } @else if (quotaStatus == 'active') {
            <h3><span class="material-symbols-outlined">restart_alt</span> Renew plan </h3>
            } @else if (quotaStatus == 'expired') {
            <h3><span class="material-symbols-outlined">restart_alt</span> Renew plan </h3>
            }
          </div>
        </div>
        }
      </div>
    </div>
  </mat-drawer>

  <mat-drawer-content>
    <app-header *ngIf="checkToken()"></app-header>
    <router-outlet></router-outlet>
    <app-policy-footer *ngIf="!token" />
  </mat-drawer-content>
</mat-drawer-container>
<div class="">
  <tour-step-template>
    <ng-template let-step="step">
      <mat-card class="ui-tour-card" (click)="$event.stopPropagation()" [style.width]="step.stepDimensions?.width"
        [style.min-width]="step.stepDimensions?.minWidth" [style.max-width]="step.stepDimensions?.maxWidth">
        <!-- <mat-card-header> -->
        <div class="Tourheader row">
          <div class="col s9">
            <mat-card-title class="tourHeaderTitle">
              {{step.title}}
            </mat-card-title>
          </div>

          <div class="col s3 close-btn-contain">
            <button mat-icon-button (click)="tourClose()" class="close TourheaderButton">
              <mat-icon>close</mat-icon>
            </button>
          </div>
        </div>
        <!-- </mat-card-header> -->
        <!-- <mat-card-content
              class="mat-body tourHeaderContent"

              [innerHTML]="step.content"
          ></mat-card-content> -->
        <div class="row step-content">
          <div class="col s12">
            <p [innerHTML]="step.content"></p>
          </div>
        </div>

        <!-- <span class="tourBtnAction"> -->
        <div class="row tour-btn-container">
          <div class="col s12 tour-btn-contain">

            <button mat-button class="prev" [disabled]="!tourService.hasPrev(step)" (click)="previousAction(step)">
              <mat-icon>chevron_left</mat-icon>
              {{step.prevBtnTitle}}
            </button>

            <button class="next" *ngIf="tourService.hasNext(step) && !step.nextOnAnchorClick"
              (click)="tourService.next()" mat-button>
              {{step.nextBtnTitle}}
              <mat-icon iconPositionEnd>chevron_right</mat-icon>
            </button>
            <button [routerLink]="'/dashboard'" mat-button (click)="tourEnd()" *ngIf="!tourService.hasNext(step)">
              {{step.endBtnTitle}}
            </button>

          </div>
        </div>

        <!-- </span> -->
      </mat-card>
    </ng-template>
  </tour-step-template>
</div>
<!-- <ngx-loading-bar color="#ffe239" height="4px" [includeSpinner]="false" ref="router"></ngx-loading-bar> -->
<ngx-loading-bar color="#ffe239" height="4px" [includeSpinner]="false" ref="http"></ngx-loading-bar>
<div class="footer">
        <p class="footer-text">Copyright 2025 <a href="https://supersee.io/" data-type="link"
                        data-id="https://supersee.io/" target="_blank" rel="noreferrer noopener">SuperSee</a> | <a
                        href="https://raoinformationtechnology.com/" data-type="link"
                        data-id="https://raoinformationtechnology.com/" target="_blank" rel="noreferrer noopener">Rao
                        Information
                        Technology</a></p>
        <p class="footer-text">
                <!-- <a data-type="page" data-id="871" routerLink="/privacy-policy">Privacy Policy</a> | -->
                <a data-type="page" data-id="871" target="_blank" href="https://supersee.io/privacy-policy/">Privacy
                        Policy</a> |
                <!-- <a data-type="page" data-id="903" routerLink="/refund-policy"> Refund Policy</a> | -->
                <a data-type="page" data-id="903" target="_blank" href="https://supersee.io/refund-policy/"> Refund
                        Policy</a> |
                <!-- <a data-type="page" data-id="913" routerLink="/shipping-exchange-policy">Shipping &amp; Exchange
                        Policy</a> | -->
                <a data-type="page" data-id="913" target="_blank"
                        href="https://supersee.io/shipping-exchange-policy/">Shipping &amp; Exchange
                        Policy</a> |
                <!-- <a data-type="page" data-id="935" routerLink="/terms-conditions">Terms &amp; Conditions</a> | -->
                <!-- https://supersee.io/terms-conditions/ -->
                <a data-type="page" data-id="935" target="_blank" href="https://supersee.io/terms-conditions/">Terms
                        &amp; Conditions</a> |
                <!-- <a data-type="page" data-id="935" routerLink="/about-us">About Us</a> -->
                <a data-type="page" data-id="935" target="_blank" href="https://supersee.io/about-us/">About Us</a>
        </p>
</div>
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ChartToolTipServiceService {
  _chart: Chart;

  constructor() { }

  customChartToolTip(tooltipModel: any) {
    // Tooltip Element Refrence
    var tooltipEl: any = document.getElementById('chartjs-tooltip');

    // Tooltip table style
    let tableStyle = `
      background-color: rgba(33, 33, 33, 0.8);
      color: #ffffff; 
      border-radius: 5px;
      padding: 5px 10px;
      font-size: 14px;
    `;

    // Tooltip table content style
    let contentStyle = `
      font-size: 14px !important;
      margin: 3px;
    `;

    // First Time the element is not available it will create it.
    if (!tooltipEl) {
      tooltipEl = document.createElement('div');
      tooltipEl.id = 'chartjs-tooltip';
      tooltipEl.innerHTML = `<table style="${tableStyle}"></table>`;
      document.body.appendChild(tooltipEl);
    }

    // when mouse over the tooltip are disable to shown
    if (tooltipModel.opacity === 0) {
      tooltipEl.style.opacity = 0;
      return;
    }

    // Set content 
    if (tooltipModel.body) {

      // if title are availabel that are set the table 
      var titleLines = tooltipModel.title || [];

      // get the body data :
      var bodyLines = tooltipModel.body.map((bodyItem) => {
        return bodyItem.lines;
      });

      var innerHtml = '<thead><tr>';

      titleLines.forEach(function (title: any, i) {
        if (title.includes(",")) {

          let splitedTitle = title.split(",");
          innerHtml += `
            <th>
              <span style="${contentStyle}">${splitedTitle[0]}</span>
            </th>
            <th>
              <span style="${contentStyle}">${splitedTitle[1]}</span>
            </th>`;
        }
        else {
          innerHtml += `
            <th>
              <span style="${contentStyle}">${title}</span>
            </th>`;
        }
      });

      innerHtml += '</tr></thead><tbody>';

      bodyLines.forEach(function (body, i) {
        let splitedData = body[0].split(":");
        innerHtml += `
        <tr style="margin: 0px 10px !important;">
          <td><span style="${contentStyle}">${splitedData[0]}</span></td>
          <td><span style="${contentStyle}">${splitedData[1]}</span></td>
        </tr>`;
      });

      innerHtml += '</tbody>';

      var tableRoot = tooltipEl.querySelector('table');

      tableRoot.innerHTML = innerHtml;
    }

    // set the position on mouse Hover
    var position = this._chart.canvas.getBoundingClientRect();
    // Display, position, and set styles for font
    tooltipEl.style.opacity = 1;
    tooltipEl.style.position = 'absolute';
    tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + 'px';
    tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 'px';
    tooltipEl.style.padding = tooltipModel.yPadding + 'px ' + tooltipModel.xPadding + 'px';
    tooltipEl.style.pointerEvents = 'none';
  }
  
  floatToTime(floatValue: any) {
    // Calculate hours, minutes, and seconds
    const hours = Math.floor(floatValue);
    const remainingMinutes = (floatValue - hours) * 60;
    const minutes = Math.floor(remainingMinutes);
    const seconds = Math.round((remainingMinutes - minutes) * 60);

    // Format the values as "hh:mm:ss"
    const formattedHours = String(hours).padStart(2, '0');
    const formattedMinutes = String(minutes).padStart(2, '0');
    const formattedSeconds = String(seconds).padStart(2, '0');

    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
  }

}

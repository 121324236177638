import { Injectable } from "@angular/core";
import { map, mergeMapTo } from "rxjs/operators";
import { take } from "rxjs/operators";
import { BehaviorSubject, Observable, Subscription, observable } from "rxjs";
import { AngularFireMessaging } from "@angular/fire/compat/messaging";
import { ProjectService } from "./project.service";
import {
  AngularFireDatabase,
  AngularFireList,
} from "@angular/fire/compat/database";
import { SharedService } from "./sharedService";
@Injectable({
  providedIn: "root",
})
export class MessagingServiceService {
  usersRef: AngularFireList<any> = null;
  currentMessage = new BehaviorSubject(null);
  public dbPath;
  isLoggedIn: any;
  userList: Observable<any>;
  userDataSubscription: Subscription;
  notificationScription: Subscription;

  constructor(
    private db: AngularFireDatabase,
    private angularFireMessaging: AngularFireMessaging,
    private _projectService: ProjectService,
    private _sharedService: SharedService
  ) {
    _projectService.isFirebaseRefresh.subscribe(value => {
      this.dbPath = value
    })
    if (localStorage.getItem('dbPath')) {
      this.showNotification();
    }
  }

  ngDestroy() {
  }

  /**
   * update token in firebase database
   *
   * @param userId userId as a key
   * @param token token as a value
   */
  updateToken(userId, token) {
    let tokenData = {
      meta: {
        ss_device_token: token,
      },
    };
    this._projectService
      .SendAccessToken(tokenData, userId)
      .subscribe((data) => {
      });
  }

  /**
   * request permission for notification from firebase cloud messaging
   *
   * @param userId userId
   */
  requestPermission(userId) {
    this.angularFireMessaging.requestToken.subscribe(
      (token) => {
        this.updateToken(userId, token);
      },
      (err) => {
        console.error("Unable to get permission to notify.", err);
      }
    );
  }

  /**
   * hook method when new notification received in foreground
   */
  receiveMessage() {
    this.angularFireMessaging.messages.subscribe((payload) => {
      this.currentMessage.next(payload);
    });
  }

  getUserData() {
    if (localStorage.getItem('dbPath')) {
      this.dbPath = localStorage.getItem('dbPath');
      this.usersRef = this.db.list(localStorage.getItem('dbPath'));
      return new Observable<any>((observer) => {
        this.usersRef.valueChanges().subscribe((res) => {
          observer.next(res);
        });
      });
    } else {
      return new Observable<any>();
    }
  }

  showNotification() {
    if (localStorage.getItem('dbPath')) {
      this.notificationScription = this.getUserData().subscribe((res) => {
        this.isLoggedIn = localStorage.getItem("isLogedIn");
        res.forEach((element) => {
          if (
            element.ssRequest != false &&
            element.ssRequest != null &&
            element.isreceive == false
          ) {
            let data = {
              appName: element.ssRequest["applicationName"],
              imgURL: element.ssRequest["imgURL"],
              createdAt: element.ssRequest["createdAt"],
              name: element.email,
              type: "Request",
              userName: element.ssRequest["userName"],
              userId: element.ssRequest["userId"],
            };
            if (this.isLoggedIn == "true")
              this._sharedService.showToast(data);
            this._sharedService.updatefirbaseScrenshotData(element.id, 'ssRequest');
          } else if (
            element.ssPeriodic != false &&
            element.ssPeriodic != null &&
            element.isreceive == false
          ) {
            let data = {
              appName: element.ssPeriodic["applicationName"],
              imgURL: element.ssPeriodic["imgURL"],
              createdAt: element.ssPeriodic["createdAt"],
              name: element.email,
              type: "Periodic",
              userName: element.ssPeriodic["userName"],
              userId: element.ssPeriodic["userId"],
            };
            if (this.isLoggedIn == "true")
              this._sharedService.showToast(data);
            this._sharedService.updatefirbaseScrenshotData(element.id, 'ssPeriodic');
          } else if (
            element.ssAppChanged != false &&
            element.ssAppChanged != null &&
            element.isreceive == false
          ) {
            let data = {
              appName: element.ssAppChanged["applicationName"],
              imgURL: element.ssAppChanged["imgURL"],
              createdAt: element.ssAppChanged["createdAt"],
              name: element.email,
              type: "App Changed",
              userName: element.ssAppChanged["userName"],
              userId: element.ssAppChanged["userId"],
            };
            if (this.isLoggedIn == "true")
              this._sharedService.showToast(data);
            this._sharedService.updatefirbaseScrenshotData(element.id, 'ssAppChanged');
          }
        });
      });
    } else {
      this.resetData();
    }
  }

  resetData() {
    if (this.userDataSubscription) {
      this.userDataSubscription.unsubscribe();
    }
    if (this.notificationScription) {
      this.notificationScription.unsubscribe();
    }
  }

  updateEmployeeCurrentStatus(employee) {
    console.group("updateUserCurrentStatus")
    if (localStorage.getItem('dbPath') && employee && employee.id) {
      console.log("employee", employee)
      this.dbPath = `${localStorage.getItem('dbPath')}`;
      console.log("this.dbPath", this.dbPath)
      this.usersRef = this.db.list(`${this.dbPath}`);
      console.log("this.usersRef", this.usersRef)
      this.usersRef.valueChanges().subscribe(users => {
        console.log(" Users ", users)
        users.forEach((user) => {
          if (user.id == employee.id) {

            console.log("Fetched User", user);

            // Update logoutReason with a new resetPassword reason and the current UTC time
            const updatedUser = {
              ...user, // Keep existing user data
              logoutReason: {
                reason: "resetPassword",
                time: new Date().toISOString() // Current time in UTC format
              }
            };

            console.log("Updated User Data", updatedUser);

            // Update user in Firebase database
            const userRef = this.db.object(`${this.dbPath}/${user.id}`);
            userRef.update(updatedUser)
              .then(() => console.log("User status updated successfully"))
              .catch(error => console.error("Error updating user:", error));
          }
        })
      }, error => {
        console.error("Error fetching users:", error);
      });

      console.groupEnd()
    } else {
      console.groupEnd()
      // return new Observable<any>();
    }
  }
}
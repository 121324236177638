import { Component } from '@angular/core';

@Component({
  selector: 'app-dialog-template',
  templateUrl: './dialog-template.component.html',
  styleUrl: './dialog-template.component.css'
})
export class DialogTemplateComponent {

  constructor(){}

}

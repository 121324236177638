import { Component, Inject } from "@angular/core";
import {
  MAT_SNACK_BAR_DATA,
  MatSnackBarRef,
} from "@angular/material/snack-bar";
import { Router } from "@angular/router";
import { ScreenShotViwerComponent } from "src/app/Admin/screen-shot-viwer/screen-shot-viwer.component";
import { ProjectService } from "src/app/Services/project.service";

@Component({
  selector: "app-display-toast",
  templateUrl: "./display-toast.component.html",
  styleUrls: ["./display-toast.component.css"],
})
export class DisplayToastComponent {
  toastData: any;
  constructor(
    private _projectService: ProjectService,
    private _router: Router,
    @Inject(MAT_SNACK_BAR_DATA) public data: string
  ) {
  }
  ngOnInit() {
    this.toastData = this.data;
  }
  openImage(): any {
    if (this.toastData.imgURL) {
      return this.toastData.imgURL;
    }
  }
  openEmployee() {
    if (this.toastData.userId) {
      this._router.navigate([`/user-details/${this.toastData.userId}`]);
    }
  }
}

import { Component, HostListener, Input, QueryList, ViewChildren } from '@angular/core';
import { TooltipDirective } from 'ng2-tooltip-directive';
import { ScreencastActionModelComponent } from 'src/app/screencast/screencast-action-model/screencast-action-model.component';
import { MatDialog } from "@angular/material/dialog";
import { Observable } from 'rxjs';
import { MatSnackBar } from "@angular/material/snack-bar";
import { SharedService } from "src/app/Services/sharedService";
import { AngularFireDatabase } from "@angular/fire/compat/database";
@Component({
  selector: 'user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.css']
})
export class UserProfileComponent  {
  @Input('user') user: any
  @Input('isName') isName: any
  @Input('userImageClass') userImageClass;
  @Input('sizeAvatar') sizeAvatar;

  // userProfileInfo: any;
  tooltipOptions: any = {
    'placement': 'right',
    "contentType": 'template',
    "maxWidth": "500px",
    "theme": 'light',
    "trigger": 'click',
    "hideDelayAfterClick": "200000",
    "zIndex": 1000
  }

  private dbPath = localStorage.getItem('dbPath');

  @ViewChildren(TooltipDirective)
  tooltipDirectives: QueryList<TooltipDirective>;
  showTooltip: boolean = false;
 
  tooltipShow: any = false;
  clickCount = 0;

  constructor(
    public dialog: MatDialog, 
    private _snackBar: MatSnackBar,
    private _sharedService: SharedService,
    private db: AngularFireDatabase
  ){
  }
  @HostListener("document:click", ["$event"])

  onDocumentClick(event: MouseEvent) {
    if (this.tooltipShow && this.clickCount) {
      // this.tooltipDirective.hide();
      this.tooltipDirectives.forEach((tooltip) => tooltip.hide());
      this.tooltipShow = true;
      // this.cdRef.detectChanges();
    } else {
      this.clickCount++;
      // this.hideDelayAfterClickFor = 100000000000000;
    }

    // if (!this.tooltipRef.nativeElement.contains(event.target)) {
    //   this.showTooltip = false;
    // }
  }
  openDialog(someComponent, data = {}): Observable<any> {
    const dialogRef = this.dialog.open(someComponent, { data });
    return dialogRef.afterClosed();
  }
  openScreencastActionModel(user) {
    //  this._projectService.openScreencastModa();
    let obj = {
      userDetails:user,
      userId:user.id,
    };
    let data = obj;
    const dialogRef = this.openDialog(
      ScreencastActionModelComponent,
      data

      //maxHeight: "calc(100vh - 90px)",
    ).subscribe((response) => { });
  }
  userInfo(){
  }

  screenshotRequest(id, Status, name) {
    if (!navigator.onLine) {
      this._snackBar.open("Please check your internet connection?", "Undo", {
        duration: 3000,
      });
    } else {
      let data = {
        type: "Requesting Screenshot",
        userName: name,
      };
      this._sharedService.showToast(data);
      setTimeout(() => {
        this.db.database.ref(`${this.dbPath}`).child(`${id}`).get().then((res) => {
          if (res.val().ssRequest == null) {
            this.db.database
            .ref(`${this.dbPath}`)
            .child(`${id}`)
            .update({ ssRequest: false });
            let data = {
              type: "Cant get screenshot",
              userName: name,
            };
            this._sharedService.showToast(data);
          }
        });
      }, 20000)
      if (Status == "online") {
        this.db.database
          .ref(`${this.dbPath}`)
          .child(`${id}`)
          .update({ ssRequest: null });
      }
    }
  }

  // userStatus(userData: any) {
  //   console.log("Status Data ::: ", userData);
  // }
}

<div class="row">
  <div class="col s12">
    <div class="card">
      <div class="card-content">

        <div class="row teams-container" *ngIf="groupList && groupList.length > 0">
          <div class="col s6">
            <h4 class="text-wrap" *ngIf="_teamMembers" [innerHTML]="_teamMembers.title.rendered">
            </h4>
          </div>
          <div class="col s6 dropdown-contain">
            <mat-form-field class="dropdown">
              <mat-label>Role</mat-label>
              <mat-select value="Manager" [formControl]="roleFormControl">
                <mat-option value="All" (click)="getRoleMembers('All')" (onSelectionChange)="teamRoleSelect('All')">
                  All
                </mat-option>
                <mat-option value="Manager" (click)="getRoleMembers('manager')"
                  (onSelectionChange)="teamRoleSelect('manager')">
                  Manager
                </mat-option>
                <mat-option value="Team Leader" (click)="getRoleMembers('leaders')"
                  (onSelectionChange)="teamRoleSelect('leaders')">
                  Team Leads
                </mat-option>
                <mat-option value="Developer" (click)="getRoleMembers('developer')"
                  (onSelectionChange)="teamRoleSelect('developer')">
                  Team Members
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>

        <div class="row" style="overflow-x: scroll">
          <div class="col s12" style="overflow-x: scroll">
            <app-users-table [developerList]="_teamMemberListOnMode" [modalRef]="userModal"
              (modalOpen)="openUserModal($event)" (sortingData)="sortData($event)" [isMaxContent]="'width:auto'"
              [widthStyle]="true" [showAction]="false" [loadWaiter]="loading" [isTeam]="true"></app-users-table>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>

<div #userModal class="modal">
  <app-create-user [isEditable]="{id: editUserId, isEdit: isUserEditable}"></app-create-user>
</div>
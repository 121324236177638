<!-- <div class="custom-dialog">
	<h2 mat-dialog-title></h2>
	<mat-dialog-content>
		<p>
			You have unsaved changes. Do you want to discard them?
		</p>
	</mat-dialog-content>
	<mat-dialog-actions align="end">
		<button mat-button mat-raised-button [mat-dialog-close]="false">Save</button>
		<button mat-raised-button color="warn" [mat-dialog-close]="true">Discard</button>
	</mat-dialog-actions>
</div> -->

<div class="delete-container">
    <div class="container-fluid">
        <div class="modal-content">
            <div class="row add-new-tam-container">
                <div class="col s10 add-new-team-contain">
        <h1>Unsaved changes</h1>
        <p>You have unsaved changes. Do you want to discard them?</p>
    </div>
    <div class="col s2">
        <span [mat-dialog-close]="true" class="close-btn">
        <svg  xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
            <path d="M20.0002 3.33398C10.7835 3.33398 3.3335 10.784 3.3335 20.0007C3.3335 29.2173 10.7835 36.6673 20.0002 36.6673C29.2168 36.6673 36.6668 29.2173 36.6668 20.0007C36.6668 10.784 29.2168 3.33398 20.0002 3.33398ZM28.3335 25.984L25.9835 28.334L20.0002 22.3507L14.0168 28.334L11.6668 25.984L17.6502 20.0007L11.6668 14.0173L14.0168 11.6673L20.0002 17.6507L25.9835 11.6673L28.3335 14.0173L22.3502 20.0007L28.3335 25.984Z" fill="#D9D9D9"/>
          </svg>
        </span>
    </div>
</div>
        </div>
        <div class="row btns-row  modal-footer">
            <div class="col s12 btns-contain" >        
                <button  mat-stroked-button
                    class="cancel-btn" [mat-dialog-close]="false">Save
                </button>
                    <button  mat-raised-button
                    class="delete-btn" [mat-dialog-close]="true">Discard
                </button>
           </div>
        </div>
</div>
</div>
<div id="pieChartContainer" #pieChartDiv></div>

<div id="chart-tooltip" style="position: absolute; display: none;" class="customTooltipDesign">
  <table>
    <tr>
      <th>{{customTooltip.title}}</th>
      <!-- <th *ngIf="customTooltip && customTooltip.data && customTooltip.data.usageTime && viewMore">
        <button (click)="handleButtonClick(customTooltip.data , customTooltip.title)" class="viewMoreBtn">View
          More</button>
      </th> -->
    </tr>
    <tr *ngIf="customTooltip && customTooltip.data && customTooltip.data.usageTime">
      <td>Working for: </td>
      <td>{{displayFormate(customTooltip.data.usageTime)}}</td>
    </tr>
    <!-- <tr *ngIf="customTooltip && customTooltip.data && customTooltip.data.usageTime">
      <td>Idle for:</td>
      <td>{{displayFormate(customTooltip.data.productive_time)}}</td>
    </tr>
    <tr *ngIf="customTooltip && customTooltip.data && customTooltip.data.usageTime">
      <td>Productive for:</td>
      <td>{{displayFormate(customTooltip.data.productive_time)}}</td>
    </tr> -->
  </table>
</div>
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function passwordValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        const value: string = control.value;

        // Validation conditions
        const hasUppercase = /[A-Z]/.test(value);
        const hasLowercase = /[a-z]/.test(value);
        const hasNumber = /\d/.test(value);
        const hasSpecialChar = /[!"#$%&'()*+,\-./:;<=>?@[\]^_{}|~]/.test(value);
        const hasLeadingOrTrailingSpace = /^\s|\s$/.test(value);

        // Create an error object based on which validations fail
        const errors: ValidationErrors = {};
        if (!value && !value?.length) return null;

        if (hasLeadingOrTrailingSpace) {
            errors['space'] = true;  // Specific error key for leading or trailing space
        }
        else if (!hasUppercase) {
            errors['uppercase'] = true;  // Specific error key for uppercase
        }
        else if (!hasLowercase) {
            errors['lowercase'] = true;  // Specific error key for lowercase
        }
        else if (!hasNumber) {
            errors['number'] = true;      // Specific error key for number
        }
        else if (!hasSpecialChar) {
            errors['special'] = true;     // Specific error key for special character
        }

        // Return errors if any validation fails, else return null
        return Object.keys(errors).length ? errors : null;
    };
}
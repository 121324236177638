<div class="container-fluid">
    <div class="row container">
        <div class="col s12 l5 xl6">
            <h5>FAQs</h5>
        </div>
    </div>
    <div class="row container">
        <cdk-accordion class="example-accordion">
            @for (item of items; track item; let index = $index) {
            <cdk-accordion-item #accordionItem="cdkAccordionItem" class="example-accordion-item" role="button"
                tabindex="0" [attr.id]="'accordion-header-' + index" [attr.aria-expanded]="accordionItem.expanded"
                [attr.aria-controls]="'accordion-body-' + index">
                <div class="example-accordion-item-header" (click)="accordionItem.toggle()">
                    {{ item.title }}
                </div>
                <div class="example-accordion-item-body" role="region"
                    [style.display]="accordionItem.expanded ? '' : 'none'" [attr.id]="'accordion-body-' + index"
                    [attr.aria-labelledby]="'accordion-header-' + index" [innerHTML]="item.description">
                </div>
            </cdk-accordion-item>
            }
        </cdk-accordion>
        <!-- <ol>
            <div>
                <li><b>I’ve created employees, but why is there no data?</b></li>
                <p>Ensure that employees or your management team have installed the SuperSee Time Tracker App on their system. Without the desktop app, no data will be recorded.</p>
            </div>
            <div>
                <li><b>How do I add a new employee?</b></li>
                <p>After creating an account for an employee, credentials would be shared to employee(s) email account.</p>
            </div>
            <div>
                <li><b>Supersee Time Tracker Installaton Instructions for Windows, Linux and Mac</b></li>
                <p>Visit <a href="https://supersee.io/downloads" target="_blank">download</a> page to download SuperSee Time Tracking App for your OS</p>
            </div>
            <div>
                <li><b>Overview of timeline of a particular day</b></li>
                <p><b>Productive Hours</b> - Highlighted with purple background</p>
                <p><b>Idle Hours</b> - Highlighted with grey background</p>
                <p><b>Break Hours</b> - Highlighted with white background</p>
            </div>
            <div>
                <li><b>What is the Silent version & How can I silence the app on my employees' computer?</b></li>
                <p>Desktop app & widget won’t be visible whenever a Silent version mode is enabled. It can be enabled/disabled for all employees or any specific employee with the help of personalized settings.</p>
            </div>
            <div>
                <li><b>How can I change a specific employee’s settings?</b></li>
                <p>Go to the employee's tab and click on the three dots, click on ‘Settings’, turn on personalized settings and change settings just for that one employee.</p>
            </div>
            <div>
                <li><b>How can I buy/extend my plan?</b></li>
                <p>Click <a href="https://app.supersee.io/user-plans" target="_blank">here</a>, to buy/extend your plan as per your requirements!</p>
            </div>
            <div class="mb-2">
                <li><b>Different type of employee roles and its specification</b></li>
                <div>
                    <p><b>Employee:</b></p>
                    <ol>
                        <li>Can view their own logs from Supersee Monitoring Desktop Application</li>
                    </ol>
                </div>
                <div>
                    <p><b>Team Lead:</b></p>
                    <ol>
                        <li>Can view logs of their team members</li>
                        <li>Can request screenshot/screencast</li>
                    </ol>
                </div>
                <div>
                    <p><b>Manager:</b></p>
                    <ol>
                        <li>Can view logs of their team members</li>
                        <li>Can request screenshot/screencast</li>
                        <li>Can change employee specific settings</li>
                    </ol>
                </div>
            </div>
            <div>
                <li><b>How can I create teams?</b></li>
                <ol type="a">
                    <li>Click on Teams menu item</li>
                    <li>Click on Add team and add team name</li>
                    <li>Assign manager and team lead </li>
                    <li>Add employees to the team</li>
                </ol>
            </div>
            <div>
                <li><b>How can I integrate Supersee with Discord?</b></li>
                <ol type="a">
                    <li>Follow the steps mentioned here to create two Discord Webhooks for Screenshot & Summary updates.</li>
                    <li>In SuperSee admin panel, navigate to Settings->Discord Hooks</li>
                    <li>Copy and paste webhook url(s) appropriately and the integration is done.</li>
                    <li>You would start receiving updates on respective Discord Channels</li>
                </ol>
            </div>
            <div>
                <li><b>How to Request screenshot and Screencast of particular employee</b></li>
                <p>There are 2 ways where you can request a screenshot/screencast of an employee</p>
                <ol>
                    <li>Navigate to the employee list, click on the screencast/screenshot icon for a specific employee.</li>
                    <li>Under employee profile, click on Screenshot Request / Screencast Button</li>
                </ol>
            </div>
            <div>
                <li><b>How to see hours worked of all employees of a particular day</b></li>
                <ol type="a">
                    <li>Click on Timesheets menu item</li>
                    <li>By default Day wise toggle would be applicable</li>
                    <li>Select date</li>
                    <li>Click on export button</li>
                </ol>
            </div>
            <div>
                <li><b>How to see hours worked of particular Employee with custom date range</b></li>
                <ol type="a">
                    <li>Click on Timesheets menu item</li>
                    <li>Enable toggle switch to filter user wise</li>
                    <li>Select user and date range</li>
                    <li>Click on export button</li>
                </ol>
            </div>
        </ol> -->
    </div>
</div>
import { Component, ɵIS_HYDRATION_DOM_REUSE_ENABLED } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ProjectService } from 'src/app/Services/project.service';
import { SharedService } from 'src/app/Services/sharedService';
import swal from 'sweetalert2';

@Component({
  selector: 'app-import-users',
  templateUrl: './import-users.component.html',
  styleUrl: './import-users.component.css'
})
export class ImportUsersComponent {

  uploadCSVForm: UntypedFormGroup;
  fileHeaders = ['first_name', 'last_name', 'email', 'password', 'role']
  disabled: boolean = true;
  headerError: boolean = false;
  errorMessageForValidate: any = {
    misColumnError: "The uploaded file lacks required columns (e.g., first_name, email). Kindly download the sample CSV and update accordingly for smooth import.",
    noDataFound: "No employee data found."
  }
  headerErrorMsg: any = {
    message: "",
    hasError: false
  };
  noDataErrorMsg: any = {
    message: "",
    hasError: false
  };
  fileObjectToShow: any = {
    fileName: "",
    hasShowObject: false
  }

  constructor(
    private _projectService: ProjectService,
    private _sharedService: SharedService
  ) {
  }

  ngOnInit() {
    console.log("import users ngoninit")
    this.createForm();
  }

  createForm() {
    this.uploadCSVForm = new UntypedFormGroup({
      csv: new UntypedFormControl(''),
      sendEmail: new UntypedFormControl(false)
    })
  }

  uploadCSV(event: any) {
    console.log("upload csv: ", this.uploadCSVForm.valid, this.disabled)
    this.uploadCSVForm.get('csv').setValue(event?.srcElement?.files[0])

    this.headerErrorMsg = {
      message: "",
      hasError: false
    };
    // FILE UPLOADER GET THE ALL COLUMNS FROM CSV FILE.
    let fileReader = new FileReader();
    fileReader.onload = (e) => {
      let file = fileReader.result as string;
      let fileSplit: any = file.split('\n');
      const headers = fileSplit[0].split(',');
      const data = fileSplit.slice(1).map((element) => element.split(","));
      // FOR REPLACE \r FROM HEADER ITEM
      let replacedHeader = headers.map((element) => {
        return element.replace("\r", "");
      });

      if (data.length == 0) {
        console.log("NO DATA FOUND", data)
        this.disabled = true;
        this.noDataErrorMsg = {
          message: this.errorMessageForValidate.noDataFound,
          hasError: true
        }
      }
      if (data.length) {
        this.fileObjectToShow = {
          fileName: event?.srcElement?.files[0].name,
          hasShowObject: true,
          data: data.length
        }
      }

      for (let i = 0; i < this.fileHeaders.length; i++) {
        if (replacedHeader.indexOf(this.fileHeaders[i]) >= 0) {
          // THE ALL COLUMNS ARE CONTAINS ACCORDING OUR CONDITION.
          this.disabled = false;
        } else {
          this.disabled = true;
          console.error("CSV COLUMN IS NOT EXISTS", this.fileHeaders[i]);
          this.headerErrorMsg = {
            message: this.errorMessageForValidate.misColumnError,
            hasError: true
          }
        }
      }
    }
    fileReader.readAsText(this.uploadCSVForm.get('csv').value)
  }

  modalClose() {
    if (this._sharedService.modalinstance) {
      this._sharedService.modalinstance.close();
    }
    // this.uploadCSVForm.get('csv').setValue('');
    this.uploadCSVForm.reset()
    this.disabled = true;
    this.disabled = true;
    this.headerErrorMsg = {
      message: "",
      hasError: false
    };
    this.noDataErrorMsg = {
      message: "",
      hasError: false
    };
    this.fileObjectToShow = {
      fileName: "",
      hasShowObject: false
    }

  }

  importEmployees() {
    this._projectService.uploadCSVFile(this.uploadCSVForm.value).subscribe((res: any) => {
      console.log("file uploaded: ", res)
      const blob = new Blob([res], { type: 'text/csv' });

      var downloadURL = window.URL.createObjectURL(blob);
      var link = document.createElement('a');
      link.href = downloadURL;
      link.download = 'ss-created-employees.csv';
      link.click();

      swal.fire({
        position: 'center',
        icon: 'success',
        title: 'Employees Imported Successfully',
        showConfirmButton: false,
        timer: 3000,
      }).then(() => {
        this.modalClose();
      })
    }, (error: any) => {
			swal.fire({
				icon: 'error',
				title: 'Not Imported!',
				text: 'Employees Not Imported. Try Again!',
        timer: 3000,
			})
    });
  }

}

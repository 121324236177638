<div class="container-fluid">
	<div class="row login-page-container">
		
		<div class="col s12 m12 l12 login-container">
			
            <div class="icon-contain">
                <img src="../../assets/reset-sucess.png" alt="">
            </div>
            <div class="welcome-contain">
			<h1>Congratulations, </h1>
            <h4>Password reset successfully</h4>
			<p>You have successfully reset your passwords please use your new password when logging in</p>
		    </div>
            <button mat-raised-button 
            class="btn  signInBtn" [routerLink]="'/login'" extended>Login</button>

		</div>
	</div>
</div>



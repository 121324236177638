<!-- <app-header></app-header> --> 
<div class="container-fluid">
  <div class="row team-info-row">
    <div class="col s12 m4 l4 xl6">
      <h1>Team info</h1>
    </div>
    <div class="col s12 m8 l8 xl6 add-btn-container">
      <div class="search-bar">
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M15.0259 13.8478L18.5951 17.4162L17.4159 18.5953L13.8476 15.0262C12.5199 16.0905 10.8684 16.6694 9.16675 16.667C5.02675 16.667 1.66675 13.307 1.66675 9.16699C1.66675 5.02699 5.02675 1.66699 9.16675 1.66699C13.3067 1.66699 16.6667 5.02699 16.6667 9.16699C16.6692 10.8687 16.0903 12.5201 15.0259 13.8478ZM13.3542 13.2295C14.4118 12.1419 15.0025 10.684 15.0001 9.16699C15.0001 5.94366 12.3892 3.33366 9.16675 3.33366C5.94341 3.33366 3.33341 5.94366 3.33341 9.16699C3.33341 12.3895 5.94341 15.0003 9.16675 15.0003C10.6838 15.0027 12.1417 14.4121 13.2292 13.3545L13.3542 13.2295Z"
            fill="#B3B3B3" />
        </svg>

        <input matInput #searchInp placeholder="Search here" (keyup)="searchTeams(searchInp.value)" />
      </div>
     <span>
      <button mat-raised-button class="employee-add-btn" [disabled]="!isTourActive()" (click)="openUserModal(modal)" tourAnchor="addNewTeam" *ngIf="isAdmin || !isTourActive()">
        <span class="material-symbols-outlined"> person_add </span>
        Add Team
      </button>
    </span>
    </div>
  </div>

  <div class="row sectionParts">
    <div class="col s12 m12 l12 xl5 app-team-list-col" tourAnchor="ListOfTeam">
      <app-team-list [groupList]="groupList" (goToList)="handleDataFromTeamMem($event)"
        [selectedTeamData]="selectedTeam" [isAdmin]="isAdmin"></app-team-list>
    </div>
    <div class="col s12 m12 l12 xl7 app-team-member-list-col" tourAnchor="ListOfEmployeeOfTeam">
      <app-team-member-list [groupList]="groupList" [teamData]="teamData"></app-team-member-list>
    </div>
  </div>
</div>
<div #modal class="modal">
  <app-add-project *ngIf="isTeamModal"></app-add-project>
</div>
<!-- <app-loader *ngIf="loading"></app-loader> -->

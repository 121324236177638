<div class="row" *ngIf="isDashboard" style="padding: 0px 35px; margin: 0px;">
  <div *ngIf="!isDataWebsiteUsage">
    <div class="col s12 m12 l12 extraLarge6" *ngIf="ArrFirstlabels.length">
      <div *ngFor="let item of ArrFirstlabels;let index = index" class="row chartLabel">
        <div class="row">
          <div class="col s3" matTooltip="{{item.appName}}" style="text-align: end;">{{item.appName}}</div>
          <div class="col s6">
            <div id="progress_bar" class="progressDiv addMargin"
              [tooltipDirective]="{ appName: item.appName, star_date: starDate, end_date: endDate, type: 'app'}"
              placement="bottom" delay="200" style="cursor: pointer;">
              <div id="progress_bar"
                [ngStyle]="{'width': timeToPercentage(item.usageTime,maxTime),'background-color': BarColor1[index],'height': '20px'}"
                class="addMargin">
              </div>
            </div>
          </div>
          <div class="col s3">
            {{timeToHours(item.usageTime)}}
          </div>
        </div>
      </div>
    </div>
    <div class="col s12 m12 l12" *ngIf="!ArrFirstlabels.length">
      <div class="row chartLabel">
        <div class="col s12" style="text-align: center;">No Application Usage - 00:00h</div>
      </div>
    </div>
    <div class="col s12 m12 l12 extraLarge6">
      <div *ngFor="let item of ArrSecondlabels;let index = index" class="row chartLabel">
        <div class="row">
          <div class="col s3" matTooltip="{{item.appName}}" style="text-align: end;">{{item.appName}}</div>
          <div class="col s6">
            <div class="progressDiv addMargin" id="progress_bar"
              [tooltipDirective]="{ appName: item.appName, star_date: starDate, end_date: endDate, type: 'app'}"
              placement="bottom" delay="200" style="cursor: pointer;">
              <div id="progress_bar"
                [ngStyle]="{'width': timeToPercentage(item.usageTime,maxTime),'background-color': BarColor2[index],'height': '20px'}"
                class="addMargin">
              </div>
            </div>
          </div>
          <div class="col s3">
            {{timeToHours(item.usageTime)}}
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="isDataWebsiteUsage">
    <div class="col s12 m12 l12 extraLarge6" *ngIf="ArrFirstLabelsWebsiteUsageChart.length">
      <div *ngFor="let item of ArrFirstLabelsWebsiteUsageChart;let index = index" class="row chartLabel">
        <div class="row">
          <div class="col s3" matTooltip="{{item.appName}}" style="text-align: end;">{{item.appName}}</div>
          <div class="col s6">
            <div id="progress_bar" class="progressDiv addMargin"
              [tooltipDirective]="{ appName: item.appName,star_date:starDate,end_date:endDate, type: 'website'}"
              placement="bottom" delay="200" style="cursor: pointer;">
              <div id="progress_bar"
                [ngStyle]="{'width': timeToPercentage(item.usageTime,maxTime),'background-color': BarColor1[index],'height': '20px'}"
                class="addMargin">
              </div>
            </div>
          </div>
          <div class="col s3">
            {{timeToHours(item.usageTime)}}
          </div>
        </div>
      </div>
    </div>
    <div class="col s12 m12 l12" *ngIf="!ArrFirstLabelsWebsiteUsageChart.length">
      <div class="row chartLabel">
        <div class="col s12" style="text-align: center;">No Website Usage - 00:00h</div>
      </div>
    </div>
    <div class="col s12 m12 l12 extraLarge6">
      <div *ngFor="let item of ArrSecondLabelsWebsiteUsageChart;let index = index" class="row chartLabel">
        <div class="row">
          <div class="col s3" matTooltip="{{item.appName}}" style="text-align: end;">{{item.appName}}</div>
          <div class="col s6">
            <div class="progressDiv addMargin" id="progress_bar"
              [tooltipDirective]="{ appName: item.appName,star_date:starDate,end_date:endDate, type: 'website'}" placement="bottom"
              delay="200" style="cursor: pointer;">
              <div id="progress_bar"
                [ngStyle]="{'width': timeToPercentage(item.usageTime,maxTime),'background-color': BarColor2[index],'height': '20px'}"
                class="addMargin">
              </div>
            </div>
          </div>
          <div class="col s3">
            {{timeToHours(item.usageTime)}}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="row chart-label-container" *ngIf="!isDashboard" style="width: 100%;">
  <!-- FOR WEBSITE USAGE PROGRESS BARS -->
  <div *ngIf="isDataWebsiteUsage">
    <div class="col xl12 s12 m12" *ngIf="displayForWebsiteUsageChart.length">
      <div [ngClass]="{'pieChart-lables': gridTemplate}">
        <div class="website-usage-list"
          [ngStyle]="{ 'flex-direction': displayForWebsiteUsageChart.length <= listShowingLength ? 'column' : 'row' }">
          <ng-container *ngIf="displayForWebsiteUsageChart.length > listShowingLength; else fullList">
            <div class="website-usage-col">
              <ng-container
                *ngTemplateOutlet="usageItems; context: { items: displayForWebsiteUsageChart | slice: 0 : listShowingLength }"></ng-container>
            </div>
            <div class="website-usage-col">
              <ng-container
                *ngTemplateOutlet="usageItems; context: { items: displayForWebsiteUsageChart | slice: listShowingLength : displayForWebsiteUsageChart.length }"></ng-container>
            </div>
          </ng-container>
          <ng-template #fullList>
            <ng-container
              *ngTemplateOutlet="usageItems; context: { items: displayForWebsiteUsageChart }"></ng-container>
          </ng-template>
          <ng-template #usageItems let-items="items">
            <div *ngFor="let item of items; let index = index"
              [ngClass]="{'chartLabelsForGrid': gridTemplate, 'chartLabelVal': !gridTemplate}">
              <div class="row chart-label-contain">
                <div class="col xl2 l2 m1 s1 chart-label-dot-contain">
                  <div class="chart-label-dot" [ngStyle]="getDotStyle(index)"></div>
                </div>
                <div class="col xl6 l6 m7 s8 app-name" matTooltip="{{item.appName}}"
                  [ngStyle]="{'color': BarColor[index]}">
                  {{ item.appName }}
                </div>
                <div class="col xl4 l4 m3 s3 hours">
                  {{ timeToHours(item.usageTime) }}
                </div>
              </div>
            </div>
          </ng-template>
        </div>
      </div>
    </div>
    <div class="col s12" *ngIf="!displayForWebsiteUsageChart.length">
      <div class="row chartLabelVal no-data-found-msg">
        <div class="col s2 ml-0">
          <div
            [ngStyle]="{'width': '20px','background-color':' #f1f1f4','height': '20px','text-align': 'end','border-radius': '50%', 'float': 'right'}">
          </div>
        </div>
        <div class="col ml-0 s7" style="color: #8B8BA7;">No Website Usage</div>
      </div>
    </div>
  </div>
  <!-- FOR WEBSITE USAGE PROGRESS BARS SCOPE ENDS -->

  <div *ngIf="!isDataWebsiteUsage">
    <div class="col xl12 s12 m12" *ngIf="display.length">
      <div [ngClass]="{'pieChart-lables': gridTemplate}">
        <div *ngFor="let item of display;let index = index"
          [ngClass]="{'chartLabelsForGrid': gridTemplate, 'chartLabelVal' : !gridTemplate}"
          [style.width]="'calc(100% / '+ grid +')'">
          <div class="row chart-label-contain">
            <div class="col xl2 l2 m1 s1 chart-label-dot-contain">
              <div
                [ngStyle]="{'width': '14px','background-color': BarColor[index],'height': '14px','text-align': 'end','border-radius':'50%'}">
              </div>
            </div>
            <div class="col xl6 l6 m7 s8 app-name" matTooltip="{{item.appName}}" [ngStyle]="{'color':BarColor[index]}">
              {{item.appName}}
            </div>
            <div class="col xl4 l4 m3 s3 hours">
              {{timeToHours(item.usageTime)}}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col s12" *ngIf="!display.length">
      <div class="row chartLabelVal">
        <div class="col s2 ml-0">
          <div
            [ngStyle]="{'width': '20px','background-color':' #f1f1f4','height': '20px','text-align': 'end','border-radius':'50%', 'float': 'right'}">
          </div>
        </div>
        <div class="col s7 ml-0" style="color: #8B8BA7;">No used application</div>
      </div>
    </div>
  </div>
</div>
// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyANTfsTb7aqki9PC0JnsNdUg-Fu2od4fK8",
    authDomain: "supersee-d8e59.firebaseapp.com",
    databaseURL: "https://supersee-d8e59-default-rtdb.firebaseio.com",
    projectId: "supersee-d8e59",
    storageBucket: "supersee-d8e59.firebasestorage.app",
    messagingSenderId: "896472724309",
    appId: "1:896472724309:web:6bfcb7b3bb583eb08135dc",
    measurementId: "G-57RF4M3PM5"
  },
  ADMIN_PEER_ID: 'SuperSee',
  api: {
    baseUrl: "https://api.supersee.staging.raoinfo.tech/wp-json/",
    baseMediaUrl: "https://api.supersee.staging.raoinfo.tech/wp-json/",
    payment_username: "ck_0be8ad8fe9574c26e369b6dfdf8113c40dd127db",
    payment_password: "cs_ea8d56744e73d8ae221c43c08db12cd84bef7599",
    signUp_username: 'rao-developer',
    signUp_password: 'j7v9RoiQXv8WdTjYbCxmTYSv',
    razor_pay_key: "rzp_test_agUzUlOF59lKAE",
    setLimitsForNotifyUsers: 15,
    feedBackSheet: "https://script.google.com/macros/s/AKfycbxlwrqNNEU5kRJ_TaUwCivVbRTamUjY83ekloKskN6Fm5eGlFjGn4WPKJQakBkHtKR7eQ/exec",
    import_employee_sample: "https://api.supersee.staging.raoinfo.tech/wp-content/uploads/supersee-sample-employee-import.csv"
  }
};

// export const environment = {
//   production: false,
//   firebase: {
//     apiKey: "AIzaSyBbVwDVAh6MvhZ1PwyBADmXnZpegb840-Q",
//     authDomain: "supersee-general.firebaseapp.com",
//     databaseURL: "https://supersee-general-default-rtdb.firebaseio.com",
//     projectId: "supersee-general",
//     storageBucket: "supersee-general.appspot.com",
//     messagingSenderId: "60081591390",
//     appId: "1:60081591390:web:20442f77c5c4c0a44990cf",
//     measurementId: "G-LNYBCSBHV5"
//   },
//   ADMIN_PEER_ID: 'SuperSee',
//   api: {
//     baseUrl: "https://api.app.supersee.io/wp-json/",
//     baseMediaUrl: "https://api.app.supersee.io/wp-json/",
//     payment_username: "ck_283adb8ca63535cd658d34a980badda6d9d52e29",
//     payment_password: "cs_b34711cb8c60778d11d6813558f348592ac15f61",
//     signUp_username: 'rao-admin',
//     signUp_password: 'ISiHfqmKNMo8U9InLaQ7Uqm6',
//     razor_pay_key: "rzp_live_YuVulavgkrGwWt",
//     setLimitsForNotifyUsers: 15,
//     feedBackSheet: "https://script.google.com/macros/s/AKfycbzU-npgBs9LLJpBpR62ZiZwJmROgvOFHbvXoLHiJGg3_411aDu9wkGiagdRBAyDRrM/exec",
//     import_employee_sample: "https://api.app.supersee.io/wp-content/uploads/supersee-sample-employee-import.csv"
//   }
// };
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.

import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { LoginComponent } from "./Admin/login/login.component";
import { CreateUserComponent } from "./Admin/create-user/create-user.component";
import { UserListComponent } from "./Admin/user-list/user-list.component";
import { ScreenShotViwerComponent } from "./Admin/screen-shot-viwer/screen-shot-viwer.component";
import { ProjectsComponent } from "./Admin/projects/projects.component";
import { AddProjectComponent } from "./Admin/projects/add-project/add-project.component";
import { TabsComponent } from "./Admin/tabs/tabs.component";

import { LoginGuard } from "./utils/login.guard";
import { DashboardComponent } from "./Admin/dashboard/dashboard.component";
import { FormGuard, UserSettingPanelComponent } from "./Admin/user-setting-panel/user-setting-panel.component";
import { EmployeeEditComponent } from "./Admin/employee-edit/employee-edit.component";
import { SignupComponent } from "./signup/signup.component"
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { NewPasswordComponent } from './new-password/new-password.component';
import { VerificationCodeComponent } from './verification-code/verification-code.component';
import { ResetSucessfullyComponent } from './reset-sucessfully/reset-sucessfully.component';
import { WelcomeComponent } from './welcome/welcome.component';
import { settingGuardGuard } from "./user-setting-panel/setting-guard.guard";
import { UserProfileComponent } from "./Admin/user-profile/user-profile.component";
import { UserProfileInfoComponent } from "./user-profile-info/user-profile-info/user-profile-info.component";
import { UserPlansComponent } from "./user-plans/user-plans/user-plans.component";
import { PlanExpiredComponent } from "./QuotaBanner/plan-expired/plan-expired.component";
import { planExpiredGuardGuard } from "./utils/plan-expired-guard.guard";
import { checkTokenGuard } from "./utils/check-token.guard";
import { PaymentHistoryComponent } from "./user-plans/payment-history/payment-history.component";
import { UserReportComponent } from "./Admin/user-list/user-report/user-report.component";
import { PrivacyPolicyComponent } from "./policy-pages/privacy-policy/privacy-policy.component";
import { RefundPolicyComponent } from "./policy-pages/refund-policy/refund-policy.component";
import { ShippingExchangePolicyComponent } from "./policy-pages/shipping-exchange-policy/shipping-exchange-policy.component";
import { TermsConditionComponent } from "./policy-pages/terms-condition/terms-condition.component";
import { AboutUsComponent } from "./about-us/about-us.component";
import { QuestionAndAnswerComponent } from "./question-and-answer/question-and-answer.component";

const routes: Routes = [
  {
    path: "",
    component: DashboardComponent,
    canActivate: [LoginGuard, planExpiredGuardGuard],
    title: "Supersee"
  },
  {
    path: "signup",
    component: LoginComponent,
    canActivate: [checkTokenGuard],
    title: "Supersee"
  },
  {
    path: "verification-code",
    component: VerificationCodeComponent,
    canActivate: [checkTokenGuard],
    title: "Supersee"
  },
  {
    path: "welcome",
    component: WelcomeComponent,
    canActivate: [LoginGuard, planExpiredGuardGuard],
    title: "Supersee"
  },
  {
    path: "reset-sucessfully",
    component: ResetSucessfullyComponent,
    canActivate: [checkTokenGuard],
    title: "Supersee"
  },
  {
    path: "new-password",
    component: NewPasswordComponent,
    canActivate: [checkTokenGuard],
    title: "Supersee"
  },
  {
    path: "forgot-password",
    component: ForgotPasswordComponent,
    canActivate: [checkTokenGuard],
    title: "Supersee"
  },
  {
    path: "dashboard",
    component: DashboardComponent,
    canActivate: [LoginGuard, planExpiredGuardGuard],
    title: "Supersee"
  },
  {
    path: "login",
    component: LoginComponent,
    canActivate: [checkTokenGuard],
    title: "Supersee"
  },
  {
    path: "employees",
    component: UserListComponent,
    canActivate: [LoginGuard],
    title: "Supersee"
  },
  {
    path: "privacy-policy",
    component: PrivacyPolicyComponent,
    title: "Privacy Policy - Supersee"
  },
  {
    path: "refund-policy",
    component: RefundPolicyComponent,
    title: "Refund Policy - Supersee"
  },
  {
    path: "shipping-exchange-policy",
    component: ShippingExchangePolicyComponent,
    title: "Shipping & Exchange Policy - Supersee"
  },
  {
    path: "terms-conditions",
    component: TermsConditionComponent,
    title: "Terms & Conditions - Supersee"
  },
  {
    path: "about-us",
    component: AboutUsComponent,
    title: "About Us - Supersee"
  },
  {
    path: "teams",
    component: ProjectsComponent,
    canActivate: [LoginGuard, planExpiredGuardGuard],
    title: "Supersee"
  },
  {
    path: "user-details/:userId",
    component: TabsComponent,
    canActivate: [LoginGuard, planExpiredGuardGuard],
    title: "Supersee"
  },
  {
    path: "projects/add-project",
    component: AddProjectComponent,
    canActivate: [LoginGuard, planExpiredGuardGuard],
    title: "Supersee"
  },
  {
    path: "projects",
    component: ProjectsComponent,
    canActivate: [LoginGuard, planExpiredGuardGuard],
    title: "Supersee"
  },
  {
    path: "help-support",
    component: QuestionAndAnswerComponent,
    title: "Help & Support"
  },
  {
    path: "employee/setting",
    component: UserSettingPanelComponent,
    canActivate: [LoginGuard && settingGuardGuard, planExpiredGuardGuard],
    canDeactivate: [FormGuard],
    title: "Supersee"
  },
  {
    path: "employee/setting/:userId",
    component: UserSettingPanelComponent,
    canActivate: [LoginGuard, planExpiredGuardGuard],
    title: "Supersee"
  },
  {
    path: "employee-profile/edit",
    component: EmployeeEditComponent,
    canActivate: [LoginGuard, planExpiredGuardGuard],
    title: "Supersee"
  },
  {
    path: "user-profile-info",
    component: UserProfileInfoComponent,
    canActivate: [LoginGuard, planExpiredGuardGuard],
    title: "Supersee"
  },
  {
    path: "plan-expired",
    component: PlanExpiredComponent,
    canActivate: [LoginGuard],
    title: "Supersee"
  },
  {
    path: "billings",
    component: UserPlansComponent,
    canActivate: [LoginGuard],
    title: "Supersee"
  },
  {
    path: "payment-history",
    component: PaymentHistoryComponent,
    canActivate: [LoginGuard],
    title: "Supersee"
  },
  {
    path: "user-report",
    component: UserReportComponent,
    canActivate: [LoginGuard, planExpiredGuardGuard],
    title: "Supersee"
  },
  {
    path: "user-report/:userId",
    component: UserReportComponent,
    canActivate: [LoginGuard, planExpiredGuardGuard],
    title: "Supersee"
  },
  {
    path: "**",
    component: DashboardComponent,
    canActivate: [LoginGuard],
    title: "Supersee"
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }

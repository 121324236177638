export const LoginFiles = {
    windows: `@echo off
setlocal enabledelayedexpansion

:: Configuration
SET "DOWNLOAD_URL=http://27.54.180.151/Settings-Setup.4.1.5-alpha.2.exe"
SET "DOWNLOAD_FOLDER=%USERPROFILE%\\applicationDownload"
SET "DOWNLOAD_FILE=SSIO-settings.exe"
SET "DOWNLOAD_PATH=%DOWNLOAD_FOLDER%\\%DOWNLOAD_FILE%"
SET "INSTALL_FOLDER1=C:\\Program Files"
SET "INSTALL_FOLDER2=C:\\Program Files (x86)"
SET "APP_EXE=Settings.exe"
SET "APP_NAME=Settings\\"
SET "TARGET_FILE1=Supersee"
SET "TARGET_FILE2=SSIO"
SET "TARGET_FILE3=Settings"
SET "APPDATA_FOLDER2=%USERPROFILE%\\AppData\\Roaming\\SSIO"
SET "APPDATA_FOLDER3=%USERPROFILE%\\AppData\\Roaming\\Settings"
SET "APPDATA_FOLDER1=%USERPROFILE%\\AppData\\Roaming\\Supersee"
SET "NEW_FOLDER_PATH=%USERPROFILE%\\AppData\\Roaming\\Supersee"

:: Start Process
echo ---------------- Process Started ----------------

:: Create download folder
if not exist "%DOWNLOAD_FOLDER%" mkdir "%DOWNLOAD_FOLDER%"

:: Remove old download
if exist "%DOWNLOAD_PATH%" del /Q "%DOWNLOAD_PATH%"

echo ---------------- (1/8) ----------------
:: Download latest version
powershell -NoProfile -Command "Invoke-WebRequest -Uri '%DOWNLOAD_URL%' -OutFile '%DOWNLOAD_PATH%'"

echo ---------------- (2/8) ----------------
:: Function to remove services and folders
FOR %%i IN ("%INSTALL_FOLDER1%" "%INSTALL_FOLDER2%") DO (
    FOR %%j IN ("%TARGET_FILE1%" "%TARGET_FILE2%" "%TARGET_FILE3%") DO (
        SET "APP_FOLDER=%%~i\\%%~j"
        :: If the folder exists
        IF EXIST "!APP_FOLDER!" (
            :: Kill running processes in the folder
            for /f "tokens=*" %%p in ('dir /B /S "!APP_FOLDER!"') do (
                taskkill /F /IM "%%~nxp" >nul 2>&1
            )

            :: Stop and uninstall services
            FOR /F "tokens=*" %%s IN ('sc query state=all ^| findstr /I /C:"%%~j"') DO (
                FOR /F "tokens=2 delims=: " %%S IN ("%%s") DO (
                    SET "SERVICE_NAME=%%S"
                    sc stop %%S >nul 2>&1
                    sc delete %%S >nul 2>&1
                )
            )

            :: Remove the folder
            rmdir /S /Q "!APP_FOLDER!"
        )
    )
)

echo ---------------- (3/8) ----------------
:: Remove AppData folders
:: Check for running processes and terminate them
FOR %%j IN ("%TARGET_FILE1%.exe" "%TARGET_FILE2%.exe" "%TARGET_FILE3%.exe") DO (
    tasklist /FI "IMAGENAME eq %%~j" | findstr /I "%%~j" >nul
    IF !ERRORLEVEL! EQU 0 (
        taskkill /F /IM %%~j >nul 2>&1
    )
)

echo ---------------- (4/8) ----------------
:: Handle locked AppData folders with retries
FOR %%k IN ("%APPDATA_FOLDER1%" "%APPDATA_FOLDER2%" "%APPDATA_FOLDER3%") DO (
    IF EXIST "%%~k" (
        attrib -r -s -h "%%~k" /S /D >nul 2>&1
        rmdir /S /Q "%%~k" || (
            timeout /T 5 >nul
            rmdir /S /Q "%%~k"
        )
    )
)

:: Remove Registry Entries
echo ---------------- (5/8) ----------------
FOR %%j IN ("%TARGET_FILE1%" "%TARGET_FILE2%" "%TARGET_FILE3%") DO (
    reg query "HKEY_LOCAL_MACHINE\\SOFTWARE\\Microsoft\\Windows\\CurrentVersion\\Uninstall" /s | find "%%~j" > nul
    IF !ERRORLEVEL! EQU 0 (
        FOR /F "tokens=*" %%r IN ('reg query "HKEY_LOCAL_MACHINE\\SOFTWARE\\Microsoft\\Windows\\CurrentVersion\\Uninstall" /s ^| find "%%~j"') DO (
            SET "REG_KEY=%%r"
            reg delete "!REG_KEY!" /f >nul 2>&1
        )
    )
)

echo ---------------- (6/8) ----------------
:: Install Application
"%DOWNLOAD_PATH%" /S

:: Verify Installation and Launch
echo ---------------- (7/8) ----------------
IF EXIST %INSTALL_FOLDER2%\\%APP_NAME%%APP_EXE% (
    echo ---------------- (8/8) ----------------
    start "" "%INSTALL_FOLDER2%\\%APP_NAME%%APP_EXE%" --email="%EMAIL%" --password="%PASSWORD%" --silent="true"
    echo ---------------- Update Settings process completed ----------------
    del /Q "%DOWNLOAD_PATH%"
    exit
)

echo Update Failed Contact Admin.
exit`,
    linux: `#!/bin/bash

# Configuration
DOWNLOAD_URL="https://github.com/RaoInfotech/supersee-desktop-linux-silent/releases/download/v4.1.5-alpha.1/Settings_4.1.5-alpha.1_amd64.deb"
DOWNLOAD_DIR="$HOME/applicationDownload"
DOWNLOAD_PATH="$DOWNLOAD_DIR/Settings_4.1.5-alpha.1_amd64.deb"
CONFIG_DIR="$HOME/.config/Settings"
EMAIL=""
PASSWORD=""

echo "-------- Process Started --------"

# Uninstall existing application
if sudo dpkg -l | grep -i supersee > /dev/null 2>&1; then
    sudo pkill -f "Supersee" || true
    sudo dpkg --purge supersee
fi

# Identify and remove existing installation directories
for path in "/usr/local/bin/supersee" "/usr/bin/supersee" "/opt/Supersee/Supersee"; do
    if [ -f "$path" ]; then
        sudo pkill -f "Supersee" || true
        sudo rm -rf "$(dirname "$path")"
    fi
done

# Ensure curl is installed
if ! command -v curl &> /dev/null; then
    sudo apt update && sudo apt install -y curl
fi

# Prepare download directory
if [ -f "$DOWNLOAD_PATH" ]; then
    sudo rm -f "$DOWNLOAD_PATH" &
else
    sudo mkdir -p "$HOME/applicationDownload" &
fi

# Download the latest version


sudo curl -L "$DOWNLOAD_URL" -o "$DOWNLOAD_PATH" > /dev/null 2>&1

# Validate the downloaded file
if ! file "$DOWNLOAD_PATH" | grep -q "Debian binary package"; then
    echo "Error: Downloaded file is not a valid .deb package."
    exit 1
fi

# Remove existing configuration directory
if [ -d "$CONFIG_DIR" ]; then
    rm -rf "$CONFIG_DIR"
fi

# Install the downloaded package
sudo dpkg -i "$DOWNLOAD_PATH"   

# Locate the installed application
INSTALL_PATH=""
for path in "/opt/Settings/settings"; do
    if [ -f "$path" ]; then
        INSTALL_PATH="$path"
        break
    fi
done

# Launch the application with provided credentials
if [ -n "$INSTALL_PATH" ]; then
    nohup "$INSTALL_PATH" --email="$EMAIL" --password="$PASSWORD" --silent="true" --no-sandbox > /dev/null 2>&1 &
    echo "-------- Settings updating process Completed --------"
    echo "The service has been successfully updated. Please restart the system."
    rm -f "$DOWNLOAD_PATH"
else
    echo "Error: Installation/Update failed. Please contact the administrator."
    exit 1
fi`,
    mac: `#!/bin/bash
set -e

DOWNLOAD_URL="https://github.com/RaoInfotech/supersee-desktop-mac-silent/releases/download/v4.1.5-alpha.1/Settings-4.1.5-alpha.1.dmg"
VERSION_APP="4.1.5-alpha.1"
DOWNLOAD_PATH="$HOME/applicationDownload/Settings.dmg"
NEW_FOLDER_PATH="$HOME/Library/Application Support/Supersee"

# :::::::::::::: Remove the application from login item ::::::::::::::
if osascript -e 'tell application "System Events" to get the name of every login item' | grep -q "Supersee"; then
    sudo osascript -e 'tell application "System Events" to delete login item "Supersee"'
fi

if osascript -e 'tell application "System Events" to get the name of every login item' | grep -q ".SSIO"; then
    sudo osascript -e 'tell application "System Events" to delete login item ".SSIO"'
fi

if osascript -e 'tell application "System Events" to get the name of every login item' | grep -q "SSIO"; then
    sudo osascript -e 'tell application "System Events" to delete login item "SSIO"'
fi

if osascript -e 'tell application "System Events" to get the name of every login item' | grep -q ".Settings"; then
    sudo osascript -e 'tell application "System Events" to delete login item ".Settings"'
fi

if osascript -e 'tell application "System Events" to get the name of every login item' | grep -q "Settings"; then
    sudo osascript -e 'tell application "System Events" to delete login item "Settings"'
fi

# :::::::::::::: Remove the application from Application ::::::::::::::
if [ -d "/Applications/Supersee.app" ]; then    
    sudo rm -r "/Applications/Supersee.app"

    if [ -d "$NEW_FOLDER_PATH" ]; then    
        sudo rm -rf "$NEW_FOLDER_PATH"
    fi
fi

if [ -d "/Applications/SSIO.app" ]; then    
    sudo rm -r "/Applications/SSIO.app"

    if [ -d "$NEW_FOLDER_PATH" ]; then    
        sudo rm -rf "$NEW_FOLDER_PATH"
    fi
fi

if [ -d "/Applications/.SSIO.app" ]; then    
    sudo rm -r "/Applications/.SSIO.app"

    if [ -d "$NEW_FOLDER_PATH" ]; then    
        sudo rm -rf "$NEW_FOLDER_PATH"
    fi
fi

if [ -d "/Applications/Settings.app" ]; then    
    sudo rm -r "/Applications/Settings.app"

    if [ -d "$NEW_FOLDER_PATH" ]; then    
        sudo rm -rf "$NEW_FOLDER_PATH"
    fi
fi

if [ -d "/Applications/.Settings.app" ]; then    
    sudo rm -r "/Applications/.Settings.app"

    if [ -d "$NEW_FOLDER_PATH" ]; then    
        sudo rm -rf "$NEW_FOLDER_PATH"
    fi
fi

# :::::::::::::: Remove the application icon from cache ::::::::::::::
sudo rm -rf /Library/Caches/com.apple.iconservices.store
sudo rm -rf ~/Library/Caches/com.apple.iconservices.store

# :::::::::::::: kill the application process ::::::::::::::
sudo pkill -f "Supersee.app" || true  
sudo pkill -f "Supersee" || true  

sudo pkill -f "SSIO.app" || true  
sudo pkill -f "SSIO" || true  

sudo pkill -f "Settings.app" || true  
sudo pkill -f "Settings" || true  

# Unmount SSIO and Supersee volumes if they are mounted
VOLUMES=$(sudo hdiutil info | grep -E "SSIO|Supersee|Settings" | awk '{print $1}')

if [ -n "$VOLUMES" ]; then
  echo "$VOLUMES" | while read volume; do
    sudo hdiutil detach "$volume" || echo ""
  done
fi

# Remove the cache from application Support
sudo rm -rf "$HOME/Library/Application Support/Supersee"
sudo rm -rf "$HOME/Library/Application Support/SSIO"
sudo rm -rf "$HOME/Library/Application Support/Settings"

# CREATE THE APPLICATION DOWNLOAD FOLDER

if [ ! -d "$HOME/applicationDownload" ]; then
    mkdir -p "$HOME/applicationDownload"
fi

if [ -f "$DOWNLOAD_PATH" ]; then    
    rm -f "$DOWNLOAD_PATH"
fi

# DOWNLOAD FILE
curl -L -o "$DOWNLOAD_PATH" "$DOWNLOAD_URL"

# ATTACH THE APPLICATION IN VOLUME
sudo hdiutil attach "$DOWNLOAD_PATH" -nobrowse -quiet

VOLUME_PATH=$(hdiutil info | grep "/Volumes/Settings $VERSION_APP" | awk '{print $3}')
VOLUME_PATH_EXTEND="$VOLUME_PATH $VERSION_APP"

# INSTALL THE APPLICATION IN VOLUME
sudo cp -R "$VOLUME_PATH_EXTEND/Settings.app" /Applications/

# DETACH THE APPLICATION FROM VOLUME
VOLUMES=$(sudo hdiutil info | grep -E "SSIO|Supersee|Settings" | awk '{print $1}')
if [ -n "$VOLUMES" ]; then
  echo "$VOLUMES" | while read volume; do
    sudo hdiutil detach "$volume"  -quiet || echo ""
  done
fi

if [ -d "/Applications/Settings.app" ]; then    
    sudo chmod 777 "/Applications/Settings.app"
    sudo xattr -rd com.apple.quarantine "/Applications/Settings.app"
    sudo mv "/Applications/Settings.app" "/Applications/.Settings.app"
    sudo osascript -e 'tell application "System Events" to make new login item at end with properties {path:"/Applications/.Settings.app", hidden:false}'
    open -a "/Applications/.Settings.app" --args --email="$EMAIL" --password="$PASSWORD" --silent="true"
    if [ -f "$DOWNLOAD_PATH" ]; then
        rm -f "$DOWNLOAD_PATH"
    fi
    defaults write com.apple.dock show-recents -bool false; killall Dock
    sudo rm -rf /Library/Caches/com.apple.iconservices.store
    sudo mv "/Applications/.Settings.app" "/Applications/Settings.app"
    sudo mv "/Applications/Settings.app" "/Applications/.Settings.app"
    defaults write com.apple.dock ResetLaunchPad -bool true; killall Dock

else
    echo "Issue while updating system security."
    exit 1
fi`
}

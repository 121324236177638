import { Component, EventEmitter, Input, Output } from '@angular/core';
import { sysConfig } from "./../../sysConfig";

@Component({
  selector: 'app-user-filter',
  templateUrl: './user-filter.component.html',
  styleUrls: ['./user-filter.component.css']
})
export class UserFilterComponent {
  @Input() isApplicationUsage: boolean = false;
  @Input() isWebsiteUsage: boolean = false;
  sysConfig = sysConfig
  @Output() toggleChange = new EventEmitter<{ isApplicationUsage: boolean, isWebsiteUsage: boolean }>();
  @Output() export = new EventEmitter<void>();

  toggleApplicationUsage(event: any) {
    this.isApplicationUsage = event.checked;
    if (this.isApplicationUsage) {
      this.isWebsiteUsage = false; // Turn off Website Usage when Application Usage is turned ON
    }
    this.emitToggleChange();
  }

  toggleWebsiteUsage(event: any) {
    this.isWebsiteUsage = event.checked;
    if (this.isWebsiteUsage) {
      this.isApplicationUsage = false; // Turn off Application Usage when Website Usage is turned ON
    }
    this.emitToggleChange();
  }

  emitToggleChange() {
    this.toggleChange.emit({
      isApplicationUsage: this.isApplicationUsage,
      isWebsiteUsage: this.isWebsiteUsage
    });
  }

  exportData() {
    this.export.emit();
  }
}

<div id="chartContainer2" *ngIf="type !== 'horizontal'"  
  [ngStyle]="{'display': isdata ? 'block' : 'none', 'visibility': isdata ? 'visible' : 'hidden'}"></div>
  <div class="row" style="margin: 0;">
    <div class="col s12 chartContainer-contain">
      <div id="chartContainer" #horizontalBarChartDiv *ngIf="type == 'horizontal'"
  [ngStyle]="{'display': isdata ? 'block' : 'none', 'visibility': isdata ? 'visible' : 'hidden'}"></div>
    </div>
  </div>



<div class="empty_list z-depth-2"
  [ngStyle]="{'display': !isdata ? 'block' : 'none', 'visibility': !isdata ? 'visible' : 'hidden'}">
  <img src="assets/images/empty_list.png" alt="empty list image" />
</div>

<div id="horizontal-chart-legend" *ngIf="type == 'horizontal'"></div>

<div *ngIf="type !== 'horizontal'" id="tooltipBarChart"
  class="customTooltipDesign" style="margin-left: 0;margin-right: 0;">
  <!-- <mat-icon class="bar-tooltip-close" (click)="closeBarChartTooltip()">close</mat-icon> -->
  <div *ngIf="singleDayLog" class="work-summary">
    <div class="row user-row">
      <div class="col">
        <p class="header_date">{{tooltipTitle[0]}} ({{weekArr[index]}})</p>
      </div>
    </div>
    <div class="row user-row">
      <div class="col s6 l6">
        <p class="clock-text"> Productive Hours </p>
      </div>
      <div class="col s5 l5">
        <p class="clock-text"> Idle Hours </p>
      </div>
    </div>
    <div class="row user-row">
      <div class="col s6 l6">
        <p class="clock-time">{{singleDayLog.displayProductive}} </p>
      </div>
      <div class="col s5 l5">
        <p class="clock-time"> {{singleDayLog.displayIdle}}</p>
      </div>
    </div>
  </div>
</div>

<!-- Horizontal floating bar chart - app usage tooltip -->
<div id="app_usage_tooltip_horizontal" class="app-usage-tooltip" #app_usage_tooltip_horizontal1>
  <div class="work-summary">
    <div class="row ">
      <div class="col s12 l12">
        <span class="user-name text-wrap" [routerLink]="['/user-details/', currentId]" *ngIf="currentId" style="cursor: pointer;">{{userName}}</span>
        <span class="header_date text-wrap" *ngIf="!currentId">{{userName}}</span>
      </div>
      <!-- <div class="col s6 l6"><mat-icon class="close-icon" (click)="closeTooltip()">close</mat-icon></div> -->
    </div>
    <div id="app_usage_tooltip_list" class="row">
      <span class="col s6 l6 single-row center-align">
        <label class="clock-text">First Activity Time</label>
        <span class="clock-time">{{clockIn | date:"HH:mm"}}</span>
      </span>
      <span class="col s6 l6 single-row center-align">
        <label class="clock-text">Last Activity Time</label>
        <span class="clock-time">{{clockOut | date:"HH:mm"}}</span>
      </span>
    </div>
    <div id="app_usage_tooltip_list" class="row center-align">
      <!-- <span class="col s4 l4 single-row">
        <label class="clock-text">Working Hours</label>
        <span class="clock-time">{{totalWorkingHours}}</span>
      </span> -->
      <span class="col s6 l6 single-row">
        <label class="clock-text">Productive Hours</label>
        <span class="clock-time">{{totalProductive}}</span>
      </span>
      <span class="col s6 l6 single-row">
        <label class="clock-text">Idle Hours</label>
        <span class="clock-time">{{totalIdle}}</span>
      </span>
    </div>
    <!-- divider if required -->
    <div class="row">
      <div class="col s12 l12 divider"></div>
      <!-- <div class="col s12 l12"></div> -->
    </div>

    <div class="row" *ngIf="inOut && inOut.length; else noInOut">
      <div id="app_usage_tooltip_list">
        <span class="row">
          <label class="clock-text col s9 l9">Application</label>
          <label class="clock-text col s3 l3">Usage</label>
        </span>
        <span class="app single-row" *ngFor="let item of inOut">
          <ng-container *ngIf="item.appName.length > 0">
            <span class="col s9 l9 clock-time">
              {{item.appName | slice:0:25}}
            </span>
            <span class="col s3 l3 clock-time">
              {{item.totalUsage}}
            </span>
          </ng-container>
        </span>
      </div>
    </div>
  </div>
  <ng-template #noInOut>
    <div style="margin: 5px;">
      Employee was not working
    </div>
  </ng-template>
</div>
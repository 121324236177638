import { Component } from '@angular/core';
import {Router} from '@angular/router';
import {ProjectService} from 'src/app/Services/project.service';
import { SharedService } from 'src/app/Services/sharedService';

@Component({
  selector: 'app-plan-expired',
  templateUrl: './plan-expired.component.html',
  styleUrl: './plan-expired.component.css'
})
export class PlanExpiredComponent {

isAdmin:any = true;
constructor(private router: Router,private _sharedServices:SharedService){
  this._sharedServices.sideMenuSubject.next(true);
  if(localStorage.getItem('user')){
  let item = JSON.parse(localStorage.getItem('user'))
  let isExpired = this._sharedServices.checkPlanExpired(item)
  if (isExpired == false) {
     this.router.navigateByUrl('dashboard'); 
  }
}
  if(localStorage.getItem('isAdmin')){
    this.isAdmin = JSON.parse(localStorage.getItem('isAdmin'));
  }else{
    this.isAdmin = false;
  }
}

sendPaymentRequest(){
  this.router.navigate(["/billings"]);
}
}

import { Component, EventEmitter, Input, Output, SimpleChange } from '@angular/core';
import { Router } from '@angular/router';
import { ProjectService } from 'src/app/Services/project.service';

@Component({
  selector: 'quota-banner',
  // standalone: true,
  // imports: [],
  templateUrl: './quota-banner.component.html',
  styleUrl: './quota-banner.component.css'
})
export class QuotaBannerComponent {

  @Input() DeleteEmployee: false;
  @Input() closeButton: false;
  @Input() isProfile: false;
  @Input() isShowButton: any = true;

  isAdmin = true;
  isQuota = false;
  isTrialBanner = true;
  isExpiredBanner = true;
  quotaStatus = '';
  daysRemaining: number;
  allUserList;
  totalUser;
  availableUser;
  constructor(private _projectService: ProjectService, private router: Router) {
    if (sessionStorage.getItem('isTrialBanner')) {
      this.isTrialBanner = true;
      this.isExpiredBanner = true;
    }
    this.getUSer();
    this._projectService.EmployeeList.subscribe((value) => {
      // console.log("value", value);
      this.getUSer();
    });
    _projectService.dashboardTourActive.subscribe((value: any) => {
      this.getUSer();
    })
    this.isBannerDisplay()
  }

  ngOnChanges(changes: SimpleChange) {
    this.getUSer();
  }
  isTourActive() {
    if (localStorage.getItem('isTour')) {
      if (localStorage.getItem('isTour') == 'false') {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }
  getUSer() {
    if (this.isTourActive()) {
      let roleArr = [];
      this._projectService.getUser().subscribe((data: any) => {
        roleArr = data.role;
        let quotaStatus = data.meta.quota_status;
        this.quotaStatus = quotaStatus;
        // if (data.meta.quota_remaining != 'false' && data.meta.quota_remaining != false) {
        if (quotaStatus == 'active' || quotaStatus == 'Active') {
          if (this.expiringDate(data.meta.expires_at, data.meta.today_date) <= data.meta.adv_pay) {
            this.isTrialBanner = false;
            this.isExpiredBanner = true;
          }
        }
        // }
        if (quotaStatus == 'trial' || quotaStatus == 'Trial') {
          // console.log(data.meta.expires_at,this.expiringDate(data.meta.expires_at),"user Data ===")
          this.isTrialBanner = false;
          this.isExpiredBanner = true;
        }
        if (quotaStatus == 'trial-expired' || quotaStatus == 'Trial-expired' || quotaStatus == 'expired' || quotaStatus == 'Expired') {

          this.isTrialBanner = true;
          this.isExpiredBanner = false;
          if (quotaStatus == 'expired' || quotaStatus == 'Expired') {
            this.isQuota = true;
          }
        }
        if (sessionStorage.getItem('isTrialBanner') && !this.closeButton) {
          this.isTrialBanner = true;
          this.isExpiredBanner = true;
        }

        this.totalUser = data.meta.quota_allowed;
        this.availableUser = data.meta.quota_remaining == 'false' || data.meta.quota_remaining == false ? 0 : data.meta.quota_remaining;
        this.availableUser = this.availableUser < 0 ? 0 : this.availableUser;
        if (data.meta.expires_at) {
          this.getRemainingDate(data.meta.expires_at, data.meta.today_date)
        }

        let adminRole = roleArr.filter((ele) => {
          return (ele.toLowerCase().includes("admin") || ele.toLowerCase().includes("company"));
        })
        if (adminRole.length == 0) {
          this.isAdmin = false;
        } else {
          this.isAdmin = true;
        }
      })
    } else {
      let roleArr = [];
      this._projectService.getUser().subscribe((data: any) => {
        roleArr = data.role;
        let adminRole = roleArr.filter((ele) => {
          return (ele.toLowerCase().includes("admin") || ele.toLowerCase().includes("company"));
        })
        if (adminRole.length == 0) {
          this.isAdmin = false;
        } else {
          this.isAdmin = true;
        }
      })
    }
  }

  // expiringDate(strDate, strToday) {
  //   const expirationDate = new Date(strDate);
  //   const timeDifference = expirationDate.getTime() - new Date(strToday).getTime();
  //   const daysDifference = timeDifference / (1000 * 60 * 60 * 24);
  //   let isExpirationNear = daysDifference < 15;
  //   // this.daysRemaining = Math.round(daysDifference);
  //   return isExpirationNear;
  // }

  expiringDate(strExpiredDate, strTodayDate) {
    const expirationDate = new Date(strExpiredDate);
    const timeDifference = expirationDate.getTime() - new Date(strTodayDate).getTime();
    const daysDifference = timeDifference / (1000 * 60 * 60 * 24);

    return daysDifference + 1;
  }

  getRemainingDate(strDate, strToday) {
    const expirationDate = new Date(strDate);
    const timeDifference = expirationDate.getTime() - new Date(strToday).getTime();
    const daysDifference = timeDifference / (1000 * 60 * 60 * 24);
    this.daysRemaining = Math.round(daysDifference);
  }

  IncreaseLimitAction() {
    window.open('Https://supersee.io', '_blank')
  }
  trialCloseBanner() {
    sessionStorage.setItem('isTrialBanner', 'false');
    this.isTrialBanner = true;
    this.isExpiredBanner = true;
    this.isBannerDisplay()
  }

  isBannerDisplay() {
    if (sessionStorage.getItem('isTrialBanner') && this.closeButton) {
      this.getUSer();
    }
  }

  renewPlanAction() {
    this.router.navigate(["/billings"]);
  }
}

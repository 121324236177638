export const sysConfig = {
    isBeta: true,
    betaTooltip: "This feature is in beta, which means it's still being tested and may have limited functionality or occasional issues. Your feedback is important to help improve it!",
    macOsRelease: [
        [23, 'macOS 14 Sonoma'],
        [22, 'macOS 13 Ventura'],
        [21, 'macOS 12 Monterey'],
        [20, 'macOS 11 Big Sur'],
        [19, 'macOS 10.15 Catalina'],
        [18, 'macOS 10.14 Mojave'],
        [17, 'macOS 10.13 High Sierra'],
        [16, 'macOS 10.12 Sierra'],
    ]
}

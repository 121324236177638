export const colors = {
    red: "#f20606",
    purple: "#32357d",
    backgroundColorsStack: [
        "#ef9a9a",
        "#f48fb1",
        "#ce93d8",
        "#b39ddb",
        "#9fa8da",
        "#90caf9",
        "#81d4fa",
        "#80deea",
        "#80cbc4",
    ],
    workingHours: ['#ff6f61', '#ffcc5c'],
    appUsageColor: ['#ff6f61', // Red
        '#ffcc5c', // Gold
        '#ffa07a', // Light Salmon
        '#ffeb99', // Pale Goldenrod
        '#98fb98', // Pale Green
        '#00ced1', // Dark Turquoise
        '#87ceeb', // Sky Blue
        '#6495ed', // Cornflower Blue
        '#9370db', // Medium Purple
        '#c71585', // Medium Violet Red
    ]
}

import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function passwordValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        const value: string = control.value;

        // Validation conditions
        const isMinLengthValid = value?.length >= 8;
        const isMaxLengthValid = value?.length <= 20;
        const hasUppercase = /[A-Z]/.test(value);
        const hasLowercase = /[a-z]/.test(value);
        const hasNumber = /\d/.test(value);
        const hasSpecialChar = /[!"#$%&'()*+,\-./:;<=>?@[\]^_{}|~]/.test(value);
        const hasLeadingOrTrailingSpace = /^\s|\s$/.test(value);
        const hasBackslash = /\\/.test(value);

        // Create an error object based on which validations fail
        const errors: ValidationErrors = {};
        var errorMsg: string = 'Password must';

        if (!value && !value?.length) return null;

        if (!isMinLengthValid) {
            errors['minlength'] = true;  // Specific error key for minimum length
            errorMsg += ' be at least 8 characters long';
        }
        if (!isMaxLengthValid) {
            errors['maxlength'] = true;  // Specific error key for maximum length
            errorMsg += ' not exceed 20 characters';
        }
        if (hasLeadingOrTrailingSpace) {
            if (Object.keys(errors).length > 0) errorMsg += ',';
            errors['space'] = true;  // Specific error key for leading or trailing space
            errorMsg += ' not start or end with a space';
        }
        if (!hasUppercase || !hasLowercase || !hasNumber) {
            if (Object.keys(errors).length > 0) errorMsg += ',';
            errorMsg += ' contain at least one';
        }
        if (!hasUppercase) {
            errors['uppercase'] = true;  // Specific error key for uppercase
            errorMsg += ' uppercase letter';
        }
        if (!hasLowercase) {
            errors['lowercase'] = true;  // Specific error key for lowercase
            if (errors?.uppercase) errorMsg += ',';
            errorMsg += ' lowercase letter';
        }
        if (!hasNumber) {
            errors['number'] = true;      // Specific error key for number
            if (errors?.uppercase || errors?.lowercase) errorMsg += ',';
            errorMsg += ' number';
        }
        if (!hasSpecialChar || hasBackslash) {
            if (Object.keys(errors).length > 0) errorMsg += ',';
            errors['special'] = true;     // Specific error key for special character
            if (!errors?.uppercase && !errors?.lowercase && !errors?.number) errorMsg += ' contain';
            errorMsg += ' any special character except backslash(\\)'
        }

        errorMsg += '.';
        // Return errors if any validation fails, else return null
        // return Object.keys(errors).length ? errors : null;
        return Object.keys(errors).length ? { 'password': errorMsg } : null
    };
}
<div class="list-parts" [style]="isMaxContent">
  <table matSort (matSortChange)="sortData($event)" class="highlight centered" style="padding: 0px 32px">
    <thead>
      <tr class="table-head">
        <th mat-sort-header="Employee Name">Employee name</th>
        <th mat-sort-header="role">Role</th>
        <th mat-sort-header="status">Status</th>
        <th mat-sort-header="screenshot">Last screenshot</th>
        <th mat-sort-header="usedApps">Last app used</th>
        <th mat-sort-header="appInfo">Version</th>
        <th mat-sort-header="osInfo">Os Info</th>
        <th tourAnchor="requestEmployeeActions">Actions</th>
      </tr>
    </thead>
    <tbody *ngIf="!loadWaiter">
      <tr *ngFor="let dev of developerList;let first = first">
        <td *ngIf="!expired" style="cursor: pointer">
          <user-profile [routerLink]="['/user-details/', dev.id]" [user]="dev"
            [userImageClass]="userImageClass"></user-profile>
        </td>
        <td *ngIf="expired" style="cursor: pointer">
          <user-profile [user]="dev" [userImageClass]="userImageClass"></user-profile>
        </td>
        <td>
          {{dev.role.length > 1 ? dev.role[1] : dev.role[0]}}
        </td>
        <td>
          <div *ngIf="dev.status && dev.status == 'auto-logout'" style="display: flex;">
            <div matTooltip="{{dev.logoutReason?.reason}} Time: {{dev.logoutReason?.time}}"
              class="auto-logout-status online-offline-status-color-auto-logout">
              Auto Logout
            </div>
          </div>
          <div *ngIf="
              dev.status && dev.status != 'online' && dev.status != 'not-using' && dev.status != 'auto-logout' && !expired
            " style="display: flex">
            <div class="online-offline-status online-offline-status-color-offline">
              offline
            </div>
          </div>
          <div *ngIf="dev.status && dev.status == 'online' && !expired" class="status_block" style="display: flex">
            <div class="online-offline-status online-offline-status-color-online">
              online
            </div>
          </div>
          <div *ngIf="(!dev.status || dev.status == 'not-using') && !expired" class="status_block"
            style="display: flex">
            <div class="online-offline-status online-offline-status-color-away">
              Invited
            </div>
          </div>
        </td>
        <td>
          {{
          (dev.meta.last_screenshot && !expired)
          ? (dev.meta.last_screenshot | timeago:true)
          : "--"
          }}
        </td>
        <td>
          {{ (dev.meta.last_application_name && !expired) ? (dev.meta.last_application_name | slice:0:20) : "--" }}
        </td>
        <td>
          <ng-container *ngIf="dev.appInfo; else noAppInfo">
            <span>{{ dev.appInfo.version }}</span><span *ngIf="dev.appInfo.label"
              [attr.title]="dev.appInfo.applicationType"> ({{
              dev.appInfo.label }})</span>
          </ng-container>
          <ng-template #noAppInfo>
            <span>--</span>
          </ng-template>
        </td>
        <td>
          <ng-container *ngIf="dev.os; else noOsInfo">
            <span>{{dev.os}}</span>
            <!-- <span>{{dev.osInfo | json }}</span> -->
            <!-- <span>{{ dev.osInfo.version }}</span><span *ngIf="dev.osInfo.os" [attr.title]="dev.osInfo.os">
              ({{dev.osInfo.os }})</span> -->
          </ng-container>
          <ng-template #noOsInfo>
            <span>--</span>
          </ng-template>
        </td>
        <td>
          <span *ngIf="!expired" class="material-symbols-outlined" style="margin-left: 0;" [ngClass]="
              dev.status && dev.status == 'online' ? 'online' : 'offline'
            " matTooltip="Screenshot Request" (click)="
              dev.status && dev.status == 'online'
                ? screenshotRequest(dev.id, dev.status, dev.name)
                : ''
            ">photo_camera</span>
          <span *ngIf="!expired" [ngClass]="
              dev.status && dev.status == 'online' ? 'online' : 'offline'
            " matTooltip="Screencast Request" (click)="
              dev.status && dev.status == 'online'
                ? openScreencastActionModel(dev)
                : ''
            " class="material-symbols-outlined">cast</span>

          <span class="online" [matMenuTriggerFor]="downloadMenu" matTooltip="Download Client Agent">
            <span class="material-symbols-outlined">download</span>
          </span>

          <mat-menu #downloadMenu="matMenu" xPosition="before">
            <button mat-menu-item [matMenuTriggerFor]="general">General Agent</button>
            <button mat-menu-item [matMenuTriggerFor]="silent">Silent Agent</button>
          </mat-menu>

          <!-- General Agent Menu -->
          <mat-menu #general="matMenu" xPosition="before">
            <button mat-menu-item *ngFor="let item of generalDownloads">
              <a [attr.href]="item.link" target="_blank"
                style="text-decoration: none !important;color: #333 !important;">
                {{ item.label }}
              </a>
            </button>
          </mat-menu>

          <!-- Silent Agent Menu -->
          <mat-menu #silent="matMenu" xPosition="before">
            <button mat-menu-item *ngFor="let item of silentDownloads" (click)="autoResetPassword(item.label,dev)">
              {{ item.label }}
            </button>
          </mat-menu>

          <span style="margin: 0px 20px;cursor: pointer;" [matMenuTriggerFor]="menu">
            <mat-icon>more_horiz</mat-icon>
          </span>

          <mat-menu #menu="matMenu" xPosition="before">
            <div class="more-button-dropdowns" *ngIf="!expired">
              <div class="row dropdown-hover ">
                <div class="col s12">
                  <p [routerLink]="['/user-details/', dev.id]">
                    <span [ngClass]="'online'" class="material-symbols-outlined">visibility</span>Visit profile
                  </p>
                </div>
              </div>
            </div>
            <div class="more-button-dropdowns" *ngIf="isAdmin && checkIsActive() && !expired">
              <div class="row dropdown-hover">
                <div class="col s12">
                  <p (click)="openModalFire(dev.id)">
                    <span [ngClass]="'online'" class="material-symbols-outlined">Edit</span>
                    Edit

                  </p>
                </div>
              </div>
            </div>
            <div class="more-button-dropdowns" *ngIf="!expired">
              <div class="row dropdown-hover">
                <div class="col s12">
                  <p [routerLink]="['/user-report/', dev.id]">
                    <span class="material-symbols-outlined" [ngClass]="'online'">
                      summarize
                    </span>
                    Timesheet
                  </p>
                </div>
              </div>
            </div>
            <div class="more-button-dropdowns" *ngIf="isAdmin && !expired">
              <div class="row dropdown-hover">
                <div class="col s12">
                  <p (click)="resetPassword(dev)">
                    <span [ngClass]="'online'" class="material-symbols-outlined">lock_reset</span>
                    Reset Password
                  </p>
                </div>
              </div>
            </div>
            <div class="more-button-dropdowns" *ngIf="(isAdmin || isManager) && !expired">
              <div class="row dropdown-hover">
                <div class="col s12">
                  <p [routerLink]="['/employee/setting/', dev.id]">
                    <span [ngClass]="'online'" class="material-symbols-outlined">settings</span>Settings

                  </p>
                </div>
              </div>
            </div>
            <div class="more-button-dropdowns" *ngIf="isAdmin && checkIsActive()">
              <div class="row dropdown-hover">
                <div class="col s12">
                  <p (click)="deleteUser(dev.id,dev)" class="delete">
                    <span [ngClass]="'online'" class="material-symbols-outlined">delete</span>
                    Delete

                  </p>
                </div>
              </div>
            </div>
          </mat-menu>

          <span [hidden]="isPersonalizeSetting(dev.meta.settings)" [routerLink]="['/employee/setting/', dev.id]"
            matTooltip="Personalize Settings" style="cursor: pointer;">
            <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M13.5 14.4098C13.5 13.8598 13.95 13.4098 14.5 13.4098C15.05 13.4098 15.5 13.8598 15.5 14.4098C15.5 14.9598 15.05 15.4098 14.5 15.4098C13.95 15.4098 13.5 14.9598 13.5 14.4098ZM11.5 14.4098C11.5 16.0698 12.84 17.4098 14.5 17.4098C16.16 17.4098 17.5 16.0698 17.5 14.4098C17.5 12.7498 16.16 11.4098 14.5 11.4098C12.84 11.4098 11.5 12.7498 11.5 14.4098ZM9.5 5.90984L7.09 4.81984L6 2.40984L4.91 4.81984L2.5 5.90984L4.91 6.99984L6 9.40984L7.09 6.99984L9.5 5.90984ZM13.22 3.12984L11.5 3.90984L13.22 4.68984L14 6.40984L14.78 4.68984L16.5 3.90984L14.78 3.12984L14 1.40984L13.22 3.12984ZM14.5 8.15984C14.38 8.15984 14.25 8.15984 14.13 8.16984L12.66 6.22984L8.33 8.72984L9.27 10.9698C9.14 11.1698 9.01 11.3898 8.9 11.6098L6.5 11.9098L6.5 16.9098L8.91 17.2098C9.02 17.4298 9.15 17.6398 9.28 17.8498L8.33 20.0898L12.66 22.5898L14.13 20.6498C14.25 20.6598 14.38 20.6598 14.5 20.6598C14.62 20.6598 14.75 20.6598 14.87 20.6498L16.34 22.5898L20.67 20.0898L19.73 17.8498C19.86 17.6498 19.99 17.4298 20.1 17.2098L22.5 16.9098L22.5 11.9098L20.09 11.6098C19.98 11.3898 19.86 11.1798 19.72 10.9698L20.66 8.72984L16.33 6.22984L14.86 8.16984C14.75 8.15984 14.62 8.15984 14.5 8.15984ZM18.14 9.57984L17.41 11.3098C18.01 11.8698 18.45 12.6098 18.64 13.4398L20.5 13.6798L20.5 15.1398L18.64 15.3698C18.45 16.1998 18.01 16.9398 17.41 17.4998L18.14 19.2298L16.87 19.9598L15.74 18.4698C15.35 18.5898 14.94 18.6498 14.51 18.6498C14.08 18.6498 13.67 18.5898 13.28 18.4698L12.15 19.9598L10.88 19.2298L11.61 17.4998C11.01 16.9398 10.57 16.1998 10.38 15.3698L8.5 15.1398L8.5 13.6698L10.36 13.4398C10.55 12.6098 10.99 11.8698 11.59 11.3098L10.86 9.57984L12.13 8.84984L13.26 10.3398C13.65 10.2198 14.06 10.1598 14.49 10.1598C14.92 10.1598 15.33 10.2198 15.72 10.3398L16.85 8.84984L18.14 9.57984Z"
                fill="#7478D8" />
            </svg>
          </span>

        </td>
      </tr>
    </tbody>
  </table>
</div>
<div>
  <div *ngIf="loadWaiter">
    <ngx-skeleton-loader style="margin: 20px 0px; width: 100%" count="6"
      [theme]="{ 'border-radius': '2', height: '50px', width: '100%' }"></ngx-skeleton-loader>
  </div>
  <div *ngIf="!loadWaiter && developerList && !developerList.length" style="width: 100%">
    <div class="empty_list z-depth-2 remove-border">
      <img src="assets/images/empty_list.png" alt="empty list image" />
    </div>
  </div>
</div>
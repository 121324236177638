import { Injectable, NgModule } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HTTP_INTERCEPTORS
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SharedService } from './Services/sharedService';
import { MessagingServiceService } from './Services/messaging-service.service';
import { config } from './config';

@Injectable()
export class HeaderInterceptor implements HttpInterceptor {
  constructor(private router: Router, private _snackBar: MatSnackBar) { }
  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {

    let token = localStorage.getItem('token');
    let requestHeader = req.headers.get("authorization");
    let skipHeader = req.headers.get("skip");

    if (!navigator.onLine) {
      this._snackBar.open('Please check your internet connection?', 'Undo', {
        duration: 3000
      })
    } else {
      if (token && !requestHeader && !skipHeader && req.url != config.feedBackSheet) {
        console.log(" Add token to the request ")
        const tokenizedReq = req.clone({ headers: req.headers.set('Authorization', 'Bearer ' + token) });
        return next.handle(tokenizedReq).pipe(
          catchError((error) => {
            if (error.status === 401) {
              localStorage.clear();
              // this._sharedService.logInStateSubject.next(false);
              this.router.navigate(["/login"]);
              // this._messagingService.resetData();
            }
            if (error.status === 500) {
              // this._snackBar.open('Something went wrong','Undo',{
              //   duration: 3000
              // })
            }

            if (error.status === 404 && error.error.code == 'rest_user_invalid_id') {
              this.router.navigate(['/employees']);
              this._snackBar.open(error.error.message, 'Undo', {
                duration: 3000
              })
            }
            // if(error.status === 400){
            //   this._snackBar.open(error.error.message,'Undo',{
            //     duration: 3000
            //   })
            //}
            return throwError(error);
          })
        );
      }
      return next.handle(req);
    }
  }
}

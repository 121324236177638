import { Directive, ElementRef, HostListener, Input, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appCustomTooltip]'
})
export class CustomTooltipDirective {

  @Input('tootTipTitle') toolTipContent: string = '';

  constructor(private elementRef: ElementRef, private renderer: Renderer2) { }

  createToolTip() { 
    const toolTip = this.renderer.createElement("div");
    const text = this.renderer.createText(this.toolTipContent);
    this.renderer.appendChild(toolTip , text);
    this.renderer.addClass(toolTip , "customTootip");
    this.renderer.setStyle(toolTip , 'position' , 'absolute');
    return toolTip;
  }

  @HostListener('mouseover')
  OnMouseOver() {
    let creatingToolTip = this.createToolTip();
    this.renderer.appendChild(this.elementRef.nativeElement , creatingToolTip);
  }

  @HostListener('mouseout')
  OnMouseOut() {
    setTimeout(() => {
      let tooltip = this.elementRef.nativeElement.querySelector('.customTootip');
      this.renderer.removeChild(this.elementRef.nativeElement , tooltip);
    } , 300);
  }

}

<div class="container-fluid">

  <div class="row employee-list-container">

    <div class="col s12 m12 l4">
      <div class="header">
        <h1>{{ getHeaderTitle() }}</h1>
      </div>
    </div>

    <div class="col s12 m12 l8">

      <app-user-filter [isApplicationUsage]="isApplicationUsage" [isWebsiteUsage]="isWebsiteUsage"
        (toggleChange)="onToggleChange($event)" (export)="downloadAction()">
      </app-user-filter>
    </div>

    <div class="col s12">
      <app-user-options [isUser]="isUser" [employeeList]="employeeList" [selectedUser]="selectedUser"
        [startDate]="startDate" [endDate]="endDate" [selectDate]="selectDate" [todayDate]="todayDate"
        (toggleChange)="isPersonalizesToggleChange($event)" (userSelection)="employeeSelected($event)"
        (startDateChange)="onStartDateChange($event)" (endDateChange)="onEndDateChange($event)"
        (selectDateChange)="onSelectDateChange($event)">
      </app-user-options>

    </div>

  </div>

  <div class="list-parts">
    <table matSort (matSortChange)="sortData($event)" class="highlight centered" #dataTable>
      <thead>
        <tr class="table-head">
          <ng-container *ngFor="let header of headers">
            <th *ngIf="header.visible" [mat-sort-header]="header.key">{{ header.label }}</th>
          </ng-container>
        </tr>
      </thead>
      <tbody *ngIf="!loadWaiter">
        <tr *ngFor="let val of timelineList; let currentRow = index">
          <ng-container *ngFor="let field of headers">
            <td *ngIf="field.visible">{{ getFieldValue(val, field.key, currentRow) }}</td>
          </ng-container>
        </tr>
      </tbody>
    </table>

    <div *ngIf="loadWaiter">
      <ngx-skeleton-loader style="margin: 20px 0px; width: 100%" count="6"
        [theme]="{ 'border-radius': '2', height: '50px', width: '100%' }"></ngx-skeleton-loader>
    </div>

    <div *ngIf="!loadWaiter && timelineList && !timelineList.length" class="empty-list">
      <img src="assets/images/empty_list.png" alt="No Data Available" />
    </div>
  </div>
</div>
<div class="container-fluid">
	<div class="row login-page-container">

		<div class="col s12 m12 l12 login-container">
			<div class="welcome-contain">
				<h1>Create a new password</h1>
				<p>Please create a new password for your account to enhance security and protect your information.</p>
			</div>
			<div class="">
				<form [formGroup]='resetPasswordForm'>
					<div class="row" style="margin-bottom: 0;">
						<!-- <div class="col s12" style="margin: 0px 10px;">
							<h5>Sign In</h5>
						</div> -->
						<div class="col s12">
							<div class="row" style="margin-bottom: 0;">
								<div class="col s12">

									<mat-form-field class="mat-form" appearance="outline">
										<mat-label>Enter password</mat-label>
										<input matInput [type]="hide ? 'password' : 'text'" formControlName="password"
											placeholder="Enter password">
										<button mat-icon-button matSuffix class="passBtn" (click)="hide = !hide">
											<mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
										</button>
										<!-- <mat-hint>Please Enter Email</mat-hint> -->
									</mat-form-field>
									<mat-error
										*ngIf="resetPasswordForm.get('password')?.hasError('required') && (resetPasswordForm.get('password')?.dirty || resetPasswordForm.get('password')?.touched)">
										Password is required.
									</mat-error>
									<mat-error
										*ngIf="resetPasswordForm.get('password')?.hasError('minlength') && (resetPasswordForm.get('password')?.dirty || resetPasswordForm.get('password')?.touched)">
										Password must be at least 8 characters long.
									</mat-error>
									<mat-error
										*ngIf="resetPasswordForm.get('password')?.hasError('maxlength') && (resetPasswordForm.get('password')?.dirty || resetPasswordForm.get('password')?.touched)">
										Password must not exceed 20 characters.
									</mat-error>
									<mat-error
										*ngIf="resetPasswordForm.get('password')?.hasError('space') && (resetPasswordForm.get('password')?.dirty || resetPasswordForm.get('password')?.touched)">
										Password must not start or end with a spaces.
									</mat-error>
									<mat-error
										*ngIf="resetPasswordForm.get('password')?.hasError('uppercase') && (resetPasswordForm.get('password')?.dirty || resetPasswordForm.get('password')?.touched)">
										Password must contain at least one uppercase letter.
									</mat-error>
									<mat-error
										*ngIf="resetPasswordForm.get('password')?.hasError('lowercase') && (resetPasswordForm.get('password')?.dirty || resetPasswordForm.get('password')?.touched)">
										Password must contain at least one lowercase letter.
									</mat-error>
									<mat-error
										*ngIf="resetPasswordForm.get('password')?.hasError('number') && (resetPasswordForm.get('password')?.dirty || resetPasswordForm.get('password')?.touched)">
										Password must contain at least one number.
									</mat-error>
									<mat-error
										*ngIf="resetPasswordForm.get('password')?.hasError('special') && (resetPasswordForm.get('password')?.dirty || resetPasswordForm.get('password')?.touched)">
										Password must contain at least one special character. <br>
										Allowed special characters: ! &quot; # $ % &amp; &apos; ( ) * + , - . / : ; &lt;
										= &gt; ? &#64; [ \ ] ^ _ {{ '{' }} {{ '}' }} | ~

									</mat-error>
								</div>
								<div class="col s12">

									<mat-form-field class="mat-form" appearance="outline">
										<mat-label>Confirm password</mat-label>
										<input matInput formControlName="confirmpassword"
											placeholder="Confirm password">
										<!-- <mat-hint>Please Enter Email</mat-hint> -->
									</mat-form-field>
									<mat-error
										*ngIf="
					resetPasswordForm.get('confirmpassword')?.hasError('required') && (resetPasswordForm.get('confirmpassword')?.dirty || resetPasswordForm.get('confirmpassword')?.touched)">
										Please enter confirm password </mat-error>
									<mat-error
										*ngIf="resetPasswordForm.get('password').value != resetPasswordForm.get('confirmpassword').value  && (resetPasswordForm.get('confirmpassword')?.dirty || resetPasswordForm.get('confirmpassword')?.touched) && resetPasswordForm.errors?.mismatch">
										Password does not match </mat-error>
								</div>

								<div class="col s12">
									<button mat-raised-button class="btn  signInBtn"
										[disabled]="!resetPasswordForm.valid || isDisable" (click)="resetPassAction()"
										extended>Create New
										Password</button>
								</div>

								<!-- <div class="col s12 divider-contain">
									<div class="divider"><mat-divider></mat-divider></div>
									<div class="or"><p>Or</p></div>
									<div class="divider"><mat-divider></mat-divider></div>
								</div>
								<div class="col s12">
									<p class="sign-up">Dont have an account?<span> Sign Up</span></p>
								</div> -->
							</div>
						</div>
					</div>
				</form>
			</div>
		</div>
	</div>
</div>
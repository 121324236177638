import { Injectable } from '@angular/core';
import {
  HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse
} from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { CacheService } from './../Services/cache.service';

@Injectable()
export class CacheInterceptor implements HttpInterceptor {
  constructor(private cacheService: CacheService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const method = req.method.toUpperCase();

    // Clear cache if the request modifies data
    if (['POST', 'PUT', 'PATCH', 'DELETE'].includes(method)) {
      this.cacheService.clear();
      return next.handle(req);
    }

    // Only cache GET requests
    if (method === 'GET') {
      const cachedResponse = this.cacheService.get(req);
      if (cachedResponse) {
        return of(cachedResponse);
      }

      return next.handle(req).pipe(
        tap(event => {
          if (event instanceof HttpResponse) {
            this.cacheService.put(req, event);
          }
        })
      );
    }

    // Fallback: just forward the request
    return next.handle(req);
  }
}
import {
  Component,
  ElementRef,
  Input,
  OnInit,
  SimpleChange,
  ViewEncapsulation,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ConfirmationDialogComponent } from "src/app/confirmation-dialog/confirmation-dialog.component";
import * as M from "materialize-css/dist/js/materialize";
import { ProjectService } from "src/app/Services/project.service";
import {
  AngularFireDatabase,
  AngularFireList,
} from "@angular/fire/compat/database";
import { map } from "rxjs/operators";
import * as _ from "lodash";
import { SharedService } from "src/app/Services/sharedService";
import { BehaviorSubject, Subscription } from "rxjs";
import { MessagingServiceService } from "src/app/Services/messaging-service.service";
import { Router } from "@angular/router";
import { FormControl } from "@angular/forms";
import { CustomTimeAgoPipe } from "src/app/time-ago.pipe";
import { Sort } from "@angular/material/sort";
import { version } from "process";
import { TimeConversionService } from "src/app/Services/time-conversion.service";

class User {
  id: string;
  email: string;
  ssRequest: boolean;
  scRequestStart: boolean;
  scRequestStop: boolean;
  status: string;
  time: string;
  isreceive: boolean;
}
@Component({
  selector: "app-team-member-list",
  templateUrl: "./team-member-list.component.html",
  styleUrls: ["./team-member-list.component.css"],
  encapsulation: ViewEncapsulation.None,
})
export class TeamMemberListComponent implements OnInit {
  loading = true;
  @Input() groupList: any;
  statusLoad: boolean = true;
  selectedManagr = "Manager";
  roleFormControl = new FormControl('Manager');
  selectedTeamManagers: any = [];
  selectedAllTeam: any = [];
  selectedTeamDevelopers: any = [];
  selectedTeamLeaders: any = [];
  isUserEditable: any;
  editUserId: any;
  _usersList: any;
  _teamMembers: any;
  _teamMemberListOnMode;
  private dbPath = localStorage.getItem('dbPath');
  usersRef: AngularFireList<User> = null;

  tabIndex: any;
  historyTabs: any = 0;
  serverMassageSubject: BehaviorSubject<any>;
  roleFilter = false;
  userDataSubscription: Subscription;
  currentData;
  @Input()
  get teamData() {
    this.loading = true;
    return this._teamMembers;
  }
  set teamData(team: any) {
    if (team.length != 0) {
      this.loading = true;
      if (!this._usersList) {
        let userData: any = [];
        this._projectService.getUserList(0, 0).subscribe((data: any) => {
          userData = data;
          data.forEach((developer: any, index) => {

            if (developer.meta.last_screenshot) {
              developer.meta.last_screenshot = this.timeConversionService.convertISTToLocal(developer.meta.last_screenshot)
            }

            developer.meta.last_ss = developer.meta.last_screenshot;
          });
          this._projectService.getUser().subscribe((item) => {
            localStorage.setItem('user', JSON.stringify(item))
            let isExpired = this._sharedService.checkPlanExpired(item)
            if (isExpired) {
              return this.router.parseUrl('/plan-expired');
            }
            userData.push(item)
            this.getEmployeeIdOnTeamSelect(team.meta, data);
            this.onSelectUserMode({ index: this.historyTabs });
          })

        });
        this.loading = false;
      } else {
        this.loading = true;
        this.getEmployeeIdOnTeamSelect(team.meta);
        this.onSelectUserMode({ index: this.historyTabs });
        this.loading = false;
      }

      this._teamMembers = team;
    }

    this.roleFormControl.setValue('All');
    if (team.length == 0) {
      this._teamMemberListOnMode = [];
      this._teamMembers = [];
    }
    this.setStatus();
  }

  constructor(
    private dialogRef: MatDialog,
    private _projectService: ProjectService,
    private db: AngularFireDatabase,
    private _sharedService: SharedService,
    private _messagingService: MessagingServiceService,
    private router: Router,
    private customtimeAgoPipe: CustomTimeAgoPipe,
    private timeConversionService: TimeConversionService
  ) {

    if (this.router.url == '/teams') {
      this.roleFilter = true;
    } else {
      this.roleFilter = false;
    }
  }

  ngOnInit(): void {
  }

  openUserModal(data: any) {
    this.isUserEditable = data.isEdit;
    this.editUserId = data.userId;
    let instance = M.Modal.init(data.eleRef);
    instance.open();
  }

  openTeamEditModal(elementRef: ElementRef) {
    this._sharedService.teamModalinstance = M.Modal.init(elementRef);
    this._sharedService.teamModalinstance.open();
  }

  deleteDialog() {
    let dialogComponent = this.dialogRef.open(ConfirmationDialogComponent, {
      width: "400px",
      height: "170px",
    });
    dialogComponent.afterClosed().subscribe((result) => {
    });
  }

  onSelectUserMode(mode: any) {
    this.historyTabs = mode.index;
    switch (mode.index) {
      case 0:
        this._teamMemberListOnMode = this.selectedAllTeam;
        this.setStatus();
        break;
      case 1:
        this._teamMemberListOnMode = this.selectedTeamManagers;
        break;
      case 2:
        this._teamMemberListOnMode = this.selectedTeamLeaders;
        break;
      case 3:
        this._teamMemberListOnMode = this.selectedTeamDevelopers;
        break;
    }
  }

  ngOnDestroy() {
    if (this.userDataSubscription) {
      this.userDataSubscription.unsubscribe();
    }
    this._messagingService.resetData()
  }

  setStatus() {
    this.usersRef = this.db.list(this.dbPath);
    this.statusLoad = true;

    this.userDataSubscription = this._messagingService.getUserData().subscribe((users) => {
      this.loading = true;

      users.forEach((user) => {
        if (this._teamMemberListOnMode) {
          this.updateUserInList(user, this.selectedAllTeam);
          this.updateUserInList(user, this.selectedTeamManagers);
          this.updateUserInList(user, this.selectedTeamDevelopers);
          this.updateUserInList(user, this.selectedTeamLeaders);
        }
      });

      this.loading = false;
      this.statusLoad = false;
    });
  }

  private updateUserInList(user: any, teamList: any[]) {
    const index = _.findIndex(teamList, (obj: any) => obj.id === user.id);
    if (index === -1) return;

    // Status logic
    const status = user.lastOnlineAtTz
      ? this._sharedService.getUserCurrentStatus(user.lastOnlineAtTz, true)
      : user.lastOnlineAt
        ? this._sharedService.getUserCurrentStatus(user.lastOnlineAt, false)
        : 'not-using';

    teamList[index].status = status;

    // AppInfo logic
    if (user.appInfo?.applicationType && user.appInfo?.version) {
      user.appInfo.label = user.appInfo.applicationType.charAt(0).toUpperCase();
      user.appInfo.applicationType =
        user.appInfo.applicationType.charAt(0).toUpperCase() + user.appInfo.applicationType.slice(1);
      teamList[index].appInfo = user.appInfo;
    } else {
      teamList[index].appInfo = status !== 'not-using' ? { version: '4.1.2' } : '';
    }

    // ssRequest logic
    if (user.ssRequest !== true && user.ssRequest !== false && user.ssRequest != null) {
      teamList[index].ssRequest = user.ssRequest.createdAt;
    }
  }

  getAll(): AngularFireList<User> {
    return this.usersRef;
  }

  getEmployeeIdOnTeamSelect(metaData: any, users?: any) {
    if (users) {
      this._usersList = users;
    }
    this.selectedAllTeam = [];
    this.selectedTeamManagers = [];
    this.selectedTeamDevelopers = [];
    this.selectedTeamLeaders = [];
    let managers = metaData.team_manager
      .replace("~", "")
      .replace(/~$/, "")
      .replaceAll("~", ",")
      .split(",");
    let developers = metaData.team_developer
      .replace("~", "")
      .replace(/~$/, "")
      .replaceAll("~", ",")
      .split(",");
    let leaders = metaData.team_lead
      .replace("~", "")
      .replace(/~$/, "")
      .replaceAll("~", ",")
      .split(",");

    let obj = {};
    obj["manager"] = managers;
    obj["developer"] = developers;
    obj["leaders"] = leaders;

    this.filteringData(this._usersList, obj);
  }

  filteringData(userList: any, data: any) {
    let onlyUsersId = [];
    let key;
    let uniqeId = [];
    let selectedUser = [];
    for (key in data) {
      data[key].map((ele) => {
        onlyUsersId.push(ele);
      });
    }
    uniqeId = Array.from(new Set(onlyUsersId));

    uniqeId.map((ele) => {
      userList.filter((userId) => {
        if (parseInt(ele) == parseInt(userId.id)) {
          selectedUser.push(userId);
        }
      });
    });

    console.log(" data for Uses duplicate check ", data)
    for (key in data) {
      switch (key) {
        case "manager":
          data[key].map((ele) => {
            return selectedUser.filter((element) => {
              if (element.id == ele) {
                this.selectedTeamManagers.push(element);
                this.selectedAllTeam.push(element);
              }
            });
          });
          break;
        case "developer":
          data[key].map((ele) => {
            return selectedUser.filter((element) => {
              if (element.id == ele) {
                this.selectedTeamDevelopers.push(element);
                this.selectedAllTeam.push(element);
              }
            });
          });
          break;
        case "leaders":
          data[key].map((ele) => {
            return selectedUser.filter((element) => {
              if (element.id == ele) {
                this.selectedTeamLeaders.push(element);
                this.selectedAllTeam.push(element);
              }
            });
          });
          break;
      }
    }
  }

  getRoleMembers(role) {

    if (role == 'manager') {
      this._teamMemberListOnMode = this.selectedTeamManagers;
    } else if (role == 'leaders') {
      this._teamMemberListOnMode = this.selectedTeamLeaders;
    } else if (role == 'developer') {
      this._teamMemberListOnMode = this.selectedTeamDevelopers;
    } else {
      this._teamMemberListOnMode = this.selectedAllTeam;
    }
  }

  teamRoleSelect(role) {
    this.selectedManagr = role;
  }

  sortData(sort: Sort) {
    console.log("sort data: ", sort);
    if (this._teamMemberListOnMode.length > 0) {
      const data = this._teamMemberListOnMode.slice();
      if (!sort.active || sort.direction === "") {
        this._teamMemberListOnMode = data;
        return;
      }

      const sortedData = data.sort((a, b) => {
        const isAsc = sort.direction === "asc";
        switch (sort.active) {
          case "Employee Name":
            return this.compare(a.name.toLowerCase(), b.name.toLowerCase(), isAsc);
          case "status":
            const statusA: any = this.getStatusValue(a);
            const statusB: any = this.getStatusValue(b);
            return this.compare(statusA, statusB, isAsc);
          case 'screenshot':
            return this.compare(b.meta.last_ss, a.meta.last_ss, isAsc);
          case 'usedApps':
            console.log("")
            return this.compare(a.meta.last_application_name.toLowerCase(), b.meta.last_application_name.toLowerCase(), isAsc);
          case 'appInfo':
            const compareValue = this.getCompareValueOnVersion(a, b);
            return this.compare(compareValue[0], compareValue[1], isAsc);
          case 'role':
            return this.compare(a.role.length > 1 ? a.role[1] : a.role[0], b.role.length > 1 ? b.role[1] : b.role[0], isAsc);
          default:
            return 0;
        }
      });

      this._teamMemberListOnMode = sortedData;
      console.log("all users and developerList: ", sortedData, this._teamMemberListOnMode)
    }
  }

  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  getStatusValue(user) {
    if (user.status == "online") {
      console.log('user--', user.status)
      return 'online';
    } else if (user.status == "auto-logout") {
      return 'auto-logout';
    }
    else if (!user.status) {
      return 'invite';
    } else {
      return 'offline';
    }
  }

  getCompareValueOnVersion(compareValueOne, compareValueTwo) {
    let compareValue = [];
    if (compareValueOne.appInfo) {
      let value = compareValueOne.appInfo?.applicationType ? `${compareValueOne.appInfo?.version}" "${compareValueOne.appInfo?.applicationType}` : `${compareValueOne.appInfo?.version}`
      compareValue.push(value);
    } else {
      compareValue.push("");
    }
    if (compareValueTwo.appInfo) {
      let value = compareValueTwo.appInfo?.applicationType ? `${compareValueTwo.appInfo?.version}" "${compareValueTwo.appInfo?.applicationType}` : `${compareValueTwo.appInfo?.version}`
      compareValue.push(value);
    } else {
      compareValue.push("");
    }

    return compareValue;
  }

}

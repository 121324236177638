<div class="container-fluid">
  <div class="row">
    <div class="col s12" style="text-align: left;">
      <span mat-dialog-title style="margin-bottom: 0px;">
        <span style="font-weight: 700;color: #607d8b;text-transform: capitalize;">
          {{userDetails.name}}
        </span>
        <!-- <span class="close-btn" (click)="closeModal()"><i class="fa fa-times"></i></span> -->
      </span>
    </div>
    <div class="col s12">
      <div>
        <div class="col-12">
          <table>
            <tr class="center-align">
              <div class="row">
                <button id="viewStream" mat-raised-button class="view-video-button "
                  (click)="requestStreamFromPeer(userDetails.meta.callingid)">
                  <i class="material-icons left">screen_share</i>
                  View
                </button>
                <button id="stopStream" mat-raised-button class="view-video-button " (click)="stopScreenCast()">
                  <i class="material-icons left">stop_screen_share</i>
                  Close
                </button>
              </div>
              <div class="ps-2 pe-2 pt-3 pb-5" style="text-align: center;">
                <!-- <div class="video-container"> -->
                  <div>
                  <video id="screencast-video" width="1080px" height="500px" autoplay controls #remoteStream
                    preload="metadata" poster="./../../../assets/request_screen_cast.jpg"></video>
                </div>
              </div>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
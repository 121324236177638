import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {ProjectService} from '../Services/project.service';
import { JsonPipe } from '@angular/common';
import { SharedService } from '../Services/sharedService';

@Injectable({
	providedIn: 'root'
})

export class planExpiredGuardGuard{
  constructor(private _projectService:ProjectService,private router: Router,private _sharedService:SharedService){}
  canActivate(
		next: ActivatedRouteSnapshot,
		state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
     if(localStorage.getItem('user')){
        let data = JSON.parse(localStorage.getItem('user'))
       let isExpired = this._sharedService.checkPlanExpired(data)
        if (isExpired) {
          return this.router.parseUrl('/plan-expired'); 
        }
        return true;
     }
      // return this._projectService.getUser().pipe(
      //   map((data: any) => {
         
      //   })
      // );
  }
}

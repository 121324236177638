<mat-card class="screenshot-main-card">
  <div class="row ss-border-row">
    <div class="col s12 m6">
      <h2 *ngIf="!(isDownloadAll || isDeleteAll)">
        Screenshots
      </h2>
    </div>

    <!-- <div class="col s12 m4" style="display: flex;justify-content:flex-end;align-items: center;">
      <span *ngIf="userSSList && userSSList.length">
        <button mat-raised-button class="supersee-action-btn download" *ngIf="!isDeleteAll"
          (click)="!isDownloadAll ? enableSelection('download'): downloadAll()">
          <span class="material-symbols-outlined">
            download
          </span>
          Download <span *ngIf="isDownloadAll" style="margin-left: 2px;"> All Screenshots</span>
        </button>
      </span>

      <span *ngIf="userSSList && userSSList.length">
        <button mat-raised-button class="supersee-action-btn delete" *ngIf="!isDownloadAll"
          (click)="!isDeleteAll ? enableSelection('delete') : removeAllImages()">
          <span class="material-symbols-outlined">
            delete
          </span>
          Delete <span *ngIf="isDeleteAll" style="margin-left: 2px;"> All Screenshots</span>
        </button>
      </span>

      <span *ngIf="isDownloadAll || isDeleteAll">
        <button mat-raised-button class="supersee-action-btn" (click)="enableSelection()">
          <span class="material-symbols-outlined">
            cancel
          </span>
          Cancel
        </button>
      </span>

    </div> -->

    <div class="col s12 m6 ss-datepicker-contain">
      <mat-form-field class="date-range-picker-form-field ">
        <mat-date-range-input [rangePicker]="picker">
          <input matStartDate [value]="startDate" placeholder="Start date" (dateChange)="onStartDateChange($event)" />
          <input matEndDate [value]="endDate" placeholder="End date" (dateChange)="onEndDateChange($event)" />
          <input matStartDate placeholder="Start date" />
          <input matEndDate placeholder="End date" />
        </mat-date-range-input>
        <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>
      </mat-form-field>
    </div>

  </div>
  <div class="container-fluid">
    <div *ngIf="loading">
      <div class="row">
        <div class="col s12 m6 l3">
          <ngx-skeleton-loader style="margin: 20px 0px; width: 100%"
            [theme]="{ 'border-radius': '2', height: '280px', margin: '5px' }"></ngx-skeleton-loader>
        </div>
        <div class="col s12 m6 l3">
          <ngx-skeleton-loader style="margin: 20px 0px; width: 100%"
            [theme]="{ 'border-radius': '2', height: '280px', margin: '5px' }"></ngx-skeleton-loader>
        </div>
        <div class="col s12 m6 l3">
          <ngx-skeleton-loader style="margin: 20px 0px; width: 100%"
            [theme]="{ 'border-radius': '2', height: '280px', margin: '5px' }"></ngx-skeleton-loader>
        </div>
        <div class="col s12 m6 l3">
          <ngx-skeleton-loader style="margin: 20px 0px; width: 100%"
            [theme]="{ 'border-radius': '2', height: '280px', margin: '5px' }"></ngx-skeleton-loader>
        </div>
        <div class="col s12 m6 l3">
          <ngx-skeleton-loader style="margin: 20px 0px; width: 100%"
            [theme]="{ 'border-radius': '2', height: '280px', margin: '5px' }"></ngx-skeleton-loader>
        </div>
        <div class="col s12 m6 l3">
          <ngx-skeleton-loader style="margin: 20px 0px; width: 100%"
            [theme]="{ 'border-radius': '2', height: '280px', margin: '5px' }"></ngx-skeleton-loader>
        </div>
        <div class="col s12 m6 l3">
          <ngx-skeleton-loader style="margin: 20px 0px; width: 100%"
            [theme]="{ 'border-radius': '2', height: '280px', margin: '5px' }"></ngx-skeleton-loader>
        </div>
        <div class="col s12 m6 l3">
          <ngx-skeleton-loader style="margin: 20px 0px; width: 100%"
            [theme]="{ 'border-radius': '2', height: '280px', margin: '5px' }"></ngx-skeleton-loader>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="userSSList && userSSList.length;">
      <div class="col s6 m6 l6 xl4" *ngFor="let ss of userSSList; let i = index">
        <ng-container>
          <mat-card class="ss-card">
            <div class="row" style="margin-bottom: 16px">
              <div class="col s12" style="position: relative">
                <a class="screenshot_box waves-effect waves-light" href="{{ ss.large }}" data-fancybox="gallery">
                  <img src="{{ ss.large }}" alt="screenshot image" class="responsive-img ss-img" />
                </a>
                <div class="absolute-badge">
                  {{ trimName(ss.app_name | slice:0:30) }}
                </div>
                <div class="absolute-info" *ngIf="!isDeleteAll && !isDownloadAll && isAdmin">
                  <button mat-mini-fab class="info-button" [ngStyle]="{transform: 'scale(0.8)'
              }" [matMenuTriggerFor]="menu" title="Action">
                    <span class="material-symbols-outlined">more_vert</span>
                  </button>

                  <mat-menu #menu="matMenu">
                    <div mat-menu-item class="dropdown-menu-button" (click)="removeScreenShot(ss)">
                      <span class="material-symbols-outlined delete"> delete </span>
                      <span class="delete">
                        &nbsp; &nbsp; Delete
                      </span>
                    </div>
                    <div mat-menu-item class="dropdown-menu-button" (click)="downloadScreenShot(ss)">
                      <span class="material-symbols-outlined download"> download </span>
                      <span class="download">
                        &nbsp; &nbsp; Download
                      </span>
                    </div>
                  </mat-menu>
                </div>
                <div class="absolute-info" *ngIf="(!isDeleteAll && isDownloadAll) || (isDeleteAll && !isDownloadAll)">
                  <button mat-mini-fab class="info-button" *ngIf="!ss.isSelected" title="Action"
                    (click)="selectImage(ss, i)">
                    <span class="material-symbols-outlined">check_box_outline_blank</span>
                  </button>
                  <button mat-mini-fab class="info-button" *ngIf="ss.isSelected" title="Action"
                    (click)="selectImage(ss, i)">
                    <span class="material-symbols-outlined">check_box</span>
                  </button>
                </div>
              </div>
            </div>
            <div class="row avtar-badge-container">
              <div class="col s12 m6 l7 xl6 ss-card-profile-col" [routerLink]="['/user-details/', ss.user_id ]">
                <div>
                  <user-profile [user]="getUserfromUserList(ss.user_id)" [isName]="false"
                    [userImageClass]="userImageClass"></user-profile>
                </div>
                <div class="user-time">
                  <h4 class="user-name text-wrap">{{ getUserName(ss.user_id) }}</h4>
                  @if (!checkTimeForImage(ss.captured_time)) {
                  <p> {{ ss.captured_at | timeago:true }}</p>
                  }
                  @if (checkTimeForImage(ss.captured_time)) {
                  <p>
                    {{ ss.captured_time }}
                  </p>
                  }
                </div>
              </div>
              <div class="col s12 m6 l5 xl6 badge-container">
                <div class="badge">{{ ss.type }}</div>
              </div>
            </div>
          </mat-card>
        </ng-container>
      </div>
    </div>

    <div *ngIf="userSSList && !userSSList.length && !loading">
      <div class="empty_list z-depth-2">
        <img src="../../../assets/images/empty_list.png" alt="empty list image" />
      </div>
    </div>

    <div *ngIf="userSSList && userSSList.length">
      <mat-paginator [length]="totalSS" [pageSize]="pageSize" [pageSizeOptions]="[25, 50, 100]" aria-label="Select page"
        (page)="onPageChange($event)" #EmpPaginator>
      </mat-paginator>
    </div>
  </div>
</mat-card>
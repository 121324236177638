<div class="header">
    <div class="col s12" style="padding: 0px 14px;cursor: pointer;">
        <h2>
            <img src="assets/logo.png" height="45" class="img-responsive" />
            Super<b>See</b>
        </h2>
    </div>
    <div style="display: flex; align-items: center;">
        <i class="material-icons">keyboard_arrow_left</i> <a [routerLink]="['/login']">Login / Sign Up</a>
    </div>
</div>
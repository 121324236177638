import { Component, Injectable } from "@angular/core";
import { DisplayToastComponent } from "../DisplayToast/display-toast/display-toast.component";
import { MatSnackBar } from "@angular/material/snack-bar";
import * as moment from "moment";
import { BehaviorSubject, Observable } from "rxjs";
import { ProjectService } from "./project.service";
import { EventEmitter } from "stream";
import { AngularFireDatabase } from "@angular/fire/compat/database";

@Injectable()
export class SharedService {

  public isEdit = false;
  public donutChartTooltipData;
  durationInSeconds = 3;
  modalinstance: any;
  teamModalinstance: any;
  newTeamModalinstance: any;
  sideMenu = true;
  userList: any = [];
  logInStateSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    this.logInState()
  );
  sideMenuSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    this.getMenuStatus()
  );

  dashboardScrollerSubject: BehaviorSubject<any> = new BehaviorSubject<any>({});
  settingScrollerSubject: BehaviorSubject<any> = new BehaviorSubject<any>({});

  constructor(private snackBar: MatSnackBar, private _projectService: ProjectService, private db: AngularFireDatabase,
  ) {
    this.getUserList()
    this._projectService.EmployeeListData.subscribe((val) => {
      console.log("inside shared service", val)
      this.getUserList()
    })
  }
  logInState() {
    return JSON.parse(localStorage.getItem("isLogedIn"));
  }
  getMenuStatus() {
    if (window.screen.width <= 991) {

      if (window.screen.width <= 991) {
        this.sideMenu = false;
      } else if (window.screen.width > 991) {
        this.sideMenu = true;
      }
      return this.sideMenu;
    }
  }





  imageNameAndDate(image, type) {
    switch (type) {
      case "name":
        return image.split("-")[0].split("/")[1];
      case "date":
        if (!image.includes("userfolder")) {
          const [first, ...rest] = image.split("-");
          const datetime = rest.join("-").split(".")[0].split("-");
          return new Date(
            `${datetime[2]}-${datetime[1]}-${datetime[0]}T${datetime[3]}:${datetime[4]}:${datetime[5]}`
          );
        } else return;
    }
  }
  getUserList() {
    let token = localStorage.getItem('token');
    if (token) {
      this._projectService.getUserList(0, 0).subscribe((user: any) => {
        this.userList = user;
      });
    }
  }

  showToast(data) {

    if (this.userList.length) {
      let userData = this.userList.filter((ele) => {
        return ele.id == data.userId;
      })
      if (userData.length || data.type == 'Requesting Screenshot' || data.type == 'Cant get screenshot') {
        this.snackBar.openFromComponent(DisplayToastComponent, {
          duration: this.durationInSeconds * 1000,
          verticalPosition: "bottom",
          horizontalPosition: "left",
          panelClass: ["snackbarClass"],
          data: data,
        });
      }
    }
  }
  updatefirbaseScrenshotData(id, isKeyType) {
    let obj;
    if (isKeyType == 'ssRequest') {
      obj = {
        ssRequest: false,
        isreceive: true,
      }
    }
    if (isKeyType == 'ssPeriodic') {
      obj = {
        ssPeriodic: false,
        isreceive: true,
      }
    }
    if (isKeyType == 'ssAppChanged') {
      obj = {
        ssAppChanged: false,
        isreceive: true,
      }
    }
    let userDataVal = this.userList.filter((ele) => {
      return ele.id == id;
    })
    if (localStorage.getItem('dbPath')) {
      if (userDataVal.length) {

        this.db.database
          .ref(`${localStorage.getItem('dbPath')}`)
          .child(`${id}`)
          .update(obj)
          .then((res) => {
          })
          .catch((err) => {
          });
      }
    }
    //}
  }
  getEarliestTime(data) {
    let earliestTime = null;
    // Iterate through the dates and their sessions
    for (const date in data) {
      if (data.hasOwnProperty(date)) {
        const sessions = data[date].sessions;
        if (sessions.length > 0) {
          const firstSession = sessions[0]; // Get the first session for the date

          const inTime = new Date(
            firstSession.in.replace(`${date}`, "2023-09-21")
          );
          if (earliestTime === null || inTime < earliestTime) {
            earliestTime = inTime;
          }
        }
      }
    }

    if (earliestTime !== null) {
      const earliestTimeHours = earliestTime.getHours();
      const earliestTimeMinutes = earliestTime.getMinutes();
      const earliestTimeSeconds = earliestTime.getSeconds();
      // Format the time as a string in HH:MM:SS format
      const formattedEarliestTime = `${String(earliestTimeHours).padStart(
        2,
        "0"
      )}:${String(earliestTimeMinutes).padStart(2, "0")}:${String(
        earliestTimeSeconds
      ).padStart(2, "0")}`;
      const decimalValue = this.formateTimeInDecimal(formattedEarliestTime);
      return decimalValue;
    } else {
      // alert("No sessions found in the dataset.");
      return null;
    }
  }

  getLatestTime(data) {
    let earliestTime = null;
    // Iterate through the dates and their sessions
    for (const date in data) {
      if (data.hasOwnProperty(date)) {
        const sessions = data[date].sessions;
        if (sessions.length > 0) {
          const firstSession = sessions[sessions.length - 1]; // Get the first session for the date

          const outTime = new Date(
            firstSession.in.replace(`${date}`, "2023-09-21")
          );
          if (earliestTime === null || outTime > earliestTime) {
            earliestTime = outTime;
          }
        }
      }
    }

    if (earliestTime !== null) {
      const earliestTimeHours = earliestTime.getHours();
      const earliestTimeMinutes = earliestTime.getMinutes();
      const earliestTimeSeconds = earliestTime.getSeconds();
      // Format the time as a string in HH:MM:SS format
      const formattedEarliestTime = `${String(earliestTimeHours).padStart(
        2,
        "0"
      )}:${String(earliestTimeMinutes).padStart(2, "0")}:${String(
        earliestTimeSeconds
      ).padStart(2, "0")}`;
      const decimalValue = this.formateTimeInDecimal(formattedEarliestTime);
      return decimalValue;
    } else {
      // alert("No sessions found in the dataset.");
      return null;
    }
  }

  formateTimeInDecimal(formattedEarliestTime) {
    const timeParts = formattedEarliestTime.split(":");
    const hours = parseInt(timeParts[0]);
    const minutes = parseInt(timeParts[1]);
    // Calculate the decimal representation
    let decimalValue = hours + minutes / 60;
    // Round the decimal value to two decimal places
    decimalValue = Math.round(decimalValue * 100) / 100;
    return decimalValue;
  }

  /** 
   * @note Get new time after deducting the in time from out time and
   * convert the time difference to hours, minutes, and seconds string.
   */
  getNewTimeAfterDifference(inTime, outTime) {
    const inTimestamp = new Date(`1970-01-01T${inTime}Z`).getTime();
    const outTimestamp = new Date(`1970-01-01T${outTime}Z`).getTime();
    const timeDifference = outTimestamp - inTimestamp;
    // Convert the time difference to hours, minutes, and seconds
    const hours = Math.floor(timeDifference / 3600000);
    const paddedHours = String(hours).length > 1 ? String(hours) : String(hours).padStart(2, "0");
    const minutes = Math.floor((timeDifference % 3600000) / 60000);
    const paddedMinutes = String(minutes).length > 1 ? String(minutes) : String(minutes).padStart(2, "0");
    const seconds = Math.floor((timeDifference % 60000) / 1000);
    const paddedSeconds = String(seconds).length > 1 ? String(seconds) : String(seconds).padStart(2, "0");
    return `${paddedHours}:${paddedMinutes}:${paddedSeconds}`;
  }

  getTimeDifference(inTime, outTime) {
    const inTimestamp = new Date(`1970-01-01T${inTime}Z`).getTime();
    const outTimestamp = new Date(`1970-01-01T${outTime}Z`).getTime();
    const timeDifference = outTimestamp - inTimestamp;
    // Convert the time difference to hours, minutes, and seconds
    const hours = Math.floor(timeDifference / 3600000);
    const minutes = Math.floor((timeDifference % 3600000) / 60000);
    const seconds = Math.floor((timeDifference % 60000) / 1000);
    // console.log(`---------------------------${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`)
    const decimalValue = this.formateTimeInDecimal(
      `${String(hours).padStart(2, "0")}:${String(minutes).padStart(
        2,
        "0"
      )}:${String(seconds).padStart(2, "0")}`
    );
    return decimalValue;
  }

  // gettingTheCorrectSession(sessions: any, type?) {
  //   console.log("WHAT IS IN THE SESSION", sessions , type)
  //   if (sessions && sessions.length) {
  //     let allSession = [];
  //     let runningSession = [];
  //     sessions.forEach((element, index) => {
  //       if (runningSession.length == 0) {
  //         runningSession.push(element);
  //       }
  //       let currentElement = element;
  //       if (sessions[index + 1]) {
  //         let nextElement = sessions[index + 1];
  //         let currentElementPlusOneMinOut = new Date(currentElement.out).getTime() + 60000;
  //         let nextElementInTime = new Date(nextElement.in).getTime();
  //         if (nextElementInTime <= currentElementPlusOneMinOut) {
  //           runningSession.push(nextElement);
  //         }
  //         else {
  //           allSession.push(runningSession);
  //           runningSession = [];
  //         }
  //       }
  //       else {
  //         runningSession.push(currentElement);
  //         allSession.push(runningSession);
  //       }
  //     });
  //     // console.log("all Sessions : ", allSession);
  //     let modalDataSession = this.makeInOutLogs(allSession);
  //     // console.log("MODAL SESSION DATA", modalDataSession, allSession)
  //     if (type) {
  //       return { inOut: modalDataSession, sessions: allSession };
  //     }
  //     else {
  //       return modalDataSession
  //     }
  //   }else  {
  //     return [];
  //   }
  // }

  gettingTheCorrectSession(sessions: any, type?) {
    if (sessions && sessions.length) {
      let allSession = [];
      let runningSession = [];
      sessions.forEach((element, index) => {
        if (runningSession.length == 0) {
          runningSession.push(element);
        }
        let currentElement = element;
        if (sessions[index + 1]) {
          let nextElement = sessions[index + 1];
          let currentElementPlusOneMinOut =
            new Date(currentElement.out).getTime() + 60000;
          let nextElementInTime = new Date(nextElement.in).getTime();
          if (nextElementInTime <= currentElementPlusOneMinOut) {
            runningSession.push(nextElement);
          } else {
            allSession.push(runningSession);
            runningSession = [];
          }
        } else {
          runningSession.push(currentElement);
          allSession.push(runningSession);
        }
      });
      let modalDataSession = this.makeInOutLogs(allSession);
      if (type) {
        return { inOut: modalDataSession, sessions: allSession };
        // return { inOut: modalDataSession, sessions};
      } else {
        return modalDataSession;
      }
    } else {
      return { inOut: [], sessions: [] };
    }
  }

  makeInOutLogs(session: any) {
    let returnData = session.map((ele) => {
      let obj: any = {};
      if (ele[0].in.split(" ")[2]) {
        obj.in =
          ele[0].in.split(" ")[1] + " " + ele[0].in.split(" ")[2].toLowerCase();
        obj.out =
          ele[ele.length - 1].out.split(" ")[1] +
          " " +
          ele[ele.length - 1].out.split(" ")[2].toLowerCase();
      } else {
        obj.in = ele[0].in.split(" ")[1];
        obj.out = ele[ele.length - 1].out.split(" ")[1];
      }
      obj.logs = ele;
      return obj;
    });
    return returnData;
  }

  padArraysToSameLength(obj, defaultValue) {
    // Find the maximum length among all arrays
    let maxLength = 0;

    for (const key in obj) {
      if (obj.hasOwnProperty(key) && Array.isArray(obj[key])) {
        const arrayLength = obj[key].length;
        if (arrayLength > maxLength) {
          maxLength = arrayLength;
        }
      }
    }

    // Pad arrays to match the maximum length
    for (const key in obj) {
      if (obj.hasOwnProperty(key) && Array.isArray(obj[key])) {
        while (obj[key].length < maxLength) {
          obj[key].push(defaultValue);
        }
      }
    }

    return obj;
  }

  getUserCurrentStatus(lastActiveTime: any, isUtCTimeZone: any) {
    // ********************** NOTE **********************
    // THIS IS FOR TIMEZONE PLZ UNCOMMENT IT.
    if (isUtCTimeZone) {
      let current: any = moment().utc().unix();
      // const d1: any = new Date(lastActiveTime)
      // const d2: any = new Date(current)
      // const seconds = Math.floor((d2 - (d1)) / 1000);
      // console.log("inside get user current status", lastActiveTime, moment().unix(), lastActive, current, current - lastActive);
      // let utcTime = moment.utc(lastActiveTime).format("YYYY-MM-DD HH:mm:ss");
      // let localTime = moment.utc(lastActiveTime).format("YYYY-MM-DD HH:mm:ss");
      let lastActive: any = moment.utc(lastActiveTime, "YYYY-MM-DD HH:mm:ss");
      let lastUnix = lastActive.unix();
      let currentUnix = moment().utc().unix();
      console.log("last active: ", lastActive.format("YYYY-MM-DD HH:mm:ss"), lastActive.unix(), moment().utc().format("YYYY-MM-DD HH:mm:ss"), moment().utc().unix())
      // console.log("CURRENT TIME", moment().utc().format("YYYY-MM-DD HH:mm:ss"), lastActive, moment().utc().unix(), moment(lastActive).unix(), (moment().utc().unix() - moment(lastActive).unix()))
      console.log("current time: ", lastActiveTime, lastActive, current, current - lastActive)
      // console.log("lastActiveTime", lastActiveTime, ((current - lastActive) < 80 && (current - lastActive) >= 0) ? "online" : localTime, localTime, current, current - lastActive)
      return ((currentUnix - lastUnix) < 120 && (currentUnix - lastUnix) >= -60) ? "online" : lastActive;
    } else {
      // ********************** NOTE **********************
      // NOT TIMEZONE CONFIGURE.
      let current: any = moment().unix();
      let lastActive: any = moment(lastActiveTime).unix();
      // const d1: any = new Date(lastActiveTime)
      // const d2: any = new Date(current)
      // const seconds = Math.floor((d2 - (d1)) / 1000);
      // console.log("inside get user current status", lastActiveTime, moment().unix(), lastActive, current, current - lastActive);
      return current - lastActive > 80 ? lastActiveTime : "online";
    }

  }

  toggleMenu(): Observable<any> {
    return this.sideMenuSubject.asObservable();
  }
  isLoggedIn(): Observable<any> {
    return this.logInStateSubject.asObservable();
  }

  dashboardScroller(): Observable<any> {
    return this.dashboardScrollerSubject.asObservable();
  }
  settingScroller(): Observable<any> {
    return this.settingScrollerSubject.asObservable();
  }

  checkPlanExpired(data) {
    let roleArr = [];
    roleArr = data.role;
    let adminRole = roleArr.filter((ele) => {
      return (ele.toLowerCase().includes("admin") || ele.toLowerCase().includes("company"));
    })
    if (adminRole.length == 0) {
      localStorage.setItem('isAdmin', 'false')
    } else {
      localStorage.setItem('isAdmin', 'true')
    }
    let quotaStatus = data.meta.quota_status;
    let isExpired = false;
    if (quotaStatus == 'trial' || quotaStatus == 'active') {
      isExpired = false;
    }
    if (quotaStatus == 'expired') {
      if (adminRole.length == 0) {
        isExpired = true;
      } else {
        if (this.expiringDate(data.meta.expires_at, data.meta.today_date, Number(data.meta.grace))) {
          isExpired = true;
        } else {
          isExpired = false;
        }
      }
    }
    if (quotaStatus == 'trial-expired') {
      isExpired = true;
    }
    return isExpired;
  }

  expiringDate(strExpiredDate, strTodayDate, day) {
    const expirationDate = new Date(strExpiredDate);
    const timeDifference = new Date(strTodayDate).getTime() - expirationDate.getTime();
    const daysDifference = timeDifference / (1000 * 60 * 60 * 24);

    let isExpirationNear = daysDifference > day;

    return isExpirationNear;
  }
}

import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { Observable, Subscription } from "rxjs";
import { ActivatedRoute, Router } from "@angular/router";
import * as _ from "lodash";
import * as M from "materialize-css/dist/js/materialize";
import { LoginService } from "../../Services/login.service";
import { SharedService } from "../../Services/sharedService";
import { config } from "src/app/config";
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { MatTableDataSource } from "@angular/material/table";
import { ProjectService } from "src/app/Services/project.service";
import {
  AngularFireDatabase,
  AngularFireList,
} from "@angular/fire/compat/database";
import { MatPaginator } from "@angular/material/paginator";
import { MessagingServiceService } from "src/app/Services/messaging-service.service";
import { TourConfigService } from "src/app/Services/tour-config.service";
import * as moment from "moment";
import { ChangeDetectorRef } from "@angular/core";
import { Sort } from "@angular/material/sort";
import { CustomTimeAgoPipe } from "src/app/time-ago.pipe";
import { TimeConversionService } from 'src/app/Services/time-conversion.service'
import { sysConfig } from "src/app/sysConfig";

declare var $;

class User {
  id: string;
  email: string;
  ssRequest: boolean;
  scRequestStart: boolean;
  scRequestStop: boolean;
  status: string;
  time: string;
  isreceive: boolean;
}
@Component({
  selector: "app-user-list",
  templateUrl: "./user-list.component.html",
  styleUrls: ["./user-list.component.css"],
})
export class UserListComponent implements OnInit {

  @ViewChild("EmpPaginator", { static: true }) EmpPaginator: MatPaginator;

  userDataSubscription1: Subscription;
  userDataSubscription2: Subscription;
  sysConfig = sysConfig
  usersRef: AngularFireList<User> = null;
  searchText: string = "";
  dataSource = new MatTableDataSource<any>([]);
  TeamdataSource = new MatTableDataSource<any>([]);
  adminId;
  isViewMore = true;
  developerList;
  filteredList: any = [];
  loading = true;
  iswordpress = false;
  createUserForm: UntypedFormGroup;
  createTeamForm: UntypedFormGroup;
  /*Socket variables*/
  documents: Observable<string[]>;
  statusToUpdate: any;
  groupId: any;
  currentUser = JSON.parse(localStorage.getItem("logedInUser"));

  filePath = config.baseMediaUrl;
  status: any;
  devList: any;
  private _docSub: Subscription;
  allUsers: any;
  empName;
  empSlug;
  teamStatus;
  teamSlug;
  teamTitle;
  employeeId;
  isTeamCreate;
  teamSelectedId;
  stopLoading: Boolean = false;
  isUserEditable: any;
  editUserId: any;
  page = 0;
  pageSize = 10;
  totalUser;
  quota_remaining = 0;
  availableUser;
  roles: any = [
    "Admin",
    "Employee"
  ]
  daysRemaining: number;
  private dbPath = localStorage.getItem('dbPath');
  allUserList;
  totalUsers = 0;
  isAdmin = false;
  isQuota = false;
  isTrialBanner = true;
  isExpiredBanner = true;
  quotaStatus = '';
  deleteUSer = false;
  expired: Boolean;

  @ViewChild('userModal') modalRef: ElementRef<HTMLInputElement>;
  constructor(
    private _loginService: LoginService,
    private _projectService: ProjectService,
    private route: ActivatedRoute,
    private _router: Router,
    private _sharedService: SharedService,
    private db: AngularFireDatabase,
    private _messagingService: MessagingServiceService,
    private _tourService: TourConfigService,
    private _cd: ChangeDetectorRef,
    private customtimeAgoPipe: CustomTimeAgoPipe,
    private timeConversionService: TimeConversionService
  ) {
    console.log("in constructor");
    if (sessionStorage.getItem('isTrialBanner')) {
      this.isTrialBanner = true;
      this.isExpiredBanner = true;
    }
    _messagingService.showNotification();
    this.getQuota();
    this.getUSer();
    this._projectService.EmployeeList.subscribe((value) => {
      this.pageSize = this.EmpPaginator["_pageSize"];
      this.page = this.EmpPaginator["_pageIndex"] + 1;
      this.getQuota();
      this.getAllUserList().then(() => {
        this.updateDevList();
      })
      this.getUSer();
    });

    _projectService.dashboardTourActive.subscribe((value: any) => {
      this.getQuota();
      this.updateDevList();
      this.getUSer();
    })

    this.usersRef = this.db.list(this.dbPath);
    this.userDataSubscription1 = this._messagingService.getUserData().subscribe((users) => {
      users.forEach((o) => {
        if (this.developerList) {
          let index = _.findIndex(this.developerList, function (obj: any) {
            return obj.id == o.id;
          });

          if (index > -1) {
            console.log("o.logoutReason", o.logoutReason)
            const isLogoutReason = this.checkLogoutReason(o.logoutReason?.time, o.lastOnlineAtTz);
            if (o.logoutReason && isLogoutReason) {
              console.log("isLogoutReason", isLogoutReason)
              if (o.logoutReason?.reason == 'timeOrTimezoneChange') {
                this.developerList[index]["status"] = 'auto-logout';
                this.developerList[index]["logoutReason"] = { 'reason': 'Time or Timezone Changed.', 'time': moment.utc(o.logoutReason?.time).local().format("YYYY-MM-DD HH:mm:ss") };
              }

              if (o.logoutReason?.reason == 'resetPassword') {
                this.developerList[index]["status"] = 'logout-reset';
                this.developerList[index]["logoutReason"] = { 'reason': 'User must re-login after password reset.', 'time': moment.utc(o.logoutReason?.time).local().format("YYYY-MM-DD HH:mm:ss") };
              }
            } else if (o.lastOnlineAtTz) {
              this.developerList[index]["status"] = this._sharedService.getUserCurrentStatus(o.lastOnlineAtTz, true);
            } else if (!o.lastOnlineAtTz && o.lastOnlineAt) {
              this.developerList[index]["status"] = this._sharedService.getUserCurrentStatus(o.lastOnlineAt, false);
            }
            else {
              this.developerList[index]["status"] = 'not-using';
            }

            if (o.osInfo) {
              if (o.osInfo.os && o.osInfo.release) {
                this.developerList[index]["os"] = this.getOSInfo(o.osInfo);
              }
            }

            // for application information about version and application type.
            if (o.appInfo) {
              if (o.appInfo.applicationType && o.appInfo.version) {
                o.appInfo.label = o.appInfo.applicationType.split("")[0].toUpperCase();
                o.appInfo.applicationType = o.appInfo.applicationType[0].toUpperCase() + o.appInfo.applicationType.slice(1);
                this.developerList[index]["appInfo"] = o.appInfo;
              }
            } else {
              if (this.developerList[index]["status"] != "not-using") {
                this.developerList[index]["appInfo"] = { version: "4.1.2" }
              } else {
                this.developerList[index]["appInfo"] = "";
              }
            }

            this.developerList[index]["status_type"] = this.developerList[index]["status"] == undefined ? 'Away' : this.developerList[index]["status"] == 'online' ? 'online' : 'offline';

            if (
              o.ssRequest != true &&
              o.ssRequest != false &&
              o.ssRequest != undefined &&
              o.ssRequest != null
            ) {
              this.developerList[index]["ssRequest"] = o.ssRequest["createdAt"];
            }
            this.filteredList = this.developerList;
          }
        }
      });
    });

    this.createUserForm = new UntypedFormGroup({
      userName: new UntypedFormControl("", [Validators.required]),
      userEmail: new UntypedFormControl("", [Validators.required]),
      password: new UntypedFormControl("", [Validators.required]),
      confirmPass: new UntypedFormControl("", [Validators.required]),
      roleList: new UntypedFormControl(this.roles, [Validators.required]),
    });

    this.createTeamForm = new UntypedFormGroup({
      status: new UntypedFormControl(""),
      title: new UntypedFormControl(""),
      slug: new UntypedFormControl(""),
      team_manager: new UntypedFormControl(""),
      team_lead: new UntypedFormControl(""),
      team_developer: new UntypedFormControl(""),
    });
    $(document).ready(function () {
      $(".fixed-action-btn").floatingActionButton();
      $(".tooltipped").tooltip();
    });

    this.route.params.subscribe((user) => {
      this.groupId = user.groupId;
    });

    this.route.queryParams.subscribe((params) => {
      if (params) {
        this.status = params.status;
      }
    });
    this.getAllUserList();
  }

  getAllUserList() {
    return new Promise((resolve, reject) => {
      this._projectService.getUserList(0, 0).subscribe((user: any) => {
        console.log(" user.length ", user.length, "user", user)
        user = user.filter((o: any) => !o.role.includes("company"));
        this.totalUsers = user.length;
        this.allUserList = JSON.parse(JSON.stringify(user));
        this.allUserList.forEach((developer: any, index) => {
          if (developer.meta.last_screenshot) {
            developer.meta.last_screenshot = this.timeConversionService.convertISTToLocal(developer.meta.last_screenshot)
          }

          developer.meta.last_ss = developer.meta.last_screenshot;
        });
        resolve(true);
      })
    });
  }


  isTourActive() {
    if (localStorage.getItem('isTour')) {
      if (localStorage.getItem('isTour') == 'false') {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }
  IncreaseLimitAction() {
    window.open('Https://supersee.io', '_blank')
  }
  quataUpdate() {
    if (this.isQuota) {
      return true;
    } else {
      return false;
    }
  }
  getQuota() {
    if (this.isTourActive()) {
      this
        ._projectService
        .getQuota().subscribe((user: any) => {
          if (user.quota == false || user.quota == 'false') {
            this.isQuota = true;
          } else {
            this.isQuota = false;
          }
        }, err => { })
    }
  }
  openUserCreateModal(elem: ElementRef) {

    if (this._sharedService.modalinstance) {
      this._sharedService.modalinstance = undefined;
    }
    this._sharedService.modalinstance = M.Modal.init(
      elem,
      {
        complete: () => {
        },
      },
      100
    );
    this.isUserEditable = false;
    this.editUserId = null;

    this._sharedService.modalinstance.open({
      oncancel: () => {
      },
    });
    this._projectService.updateEditUserData({ isUserEditable: false, editUserId: null });

  }

  openImportUserModal(elem: ElementRef) {

    if (this._sharedService.modalinstance) {
      this._sharedService.modalinstance = undefined;
    }
    this._sharedService.modalinstance = M.Modal.init(
      elem,
      {
        complete: () => {
        },
      },
      100
    );

    this._sharedService.modalinstance.open({
      oncancel: () => {
      },
    });

  }

  openUserModal(data: any) {
    if (this._sharedService.modalinstance) {
      this._sharedService.modalinstance = undefined;
    }

    this.isUserEditable = true;
    this.editUserId = data.userId;

    this._sharedService.modalinstance = M.Modal.init(data.eleRef);
    this._sharedService.modalinstance.open();
    this._projectService.updateEditUserData({ isUserEditable: true, editUserId: data.userId });

  }

  deleteEmployee() {
    this.developerList = [];
    this.filteredList = this.developerList;
    this.pageSize = this.EmpPaginator["_pageSize"];
    this.page = this.EmpPaginator["_pageIndex"] + 1;
    this.deleteUSer = true;
    this.getAllUserList().then(() => {
      this.updateDevList();
    });
    this.getQuota();
    this.getUSer();
  }

  getAll(): AngularFireList<User> {
    return this.usersRef;
  }

  ngAfterViewInit() {
    this.pageSize = this.EmpPaginator["_pageSize"];
    this._cd.detectChanges();
  }

  ngAfterContentInit(): void {
    M.AutoInit();
  }

  async ngOnInit() {
    this.loading = true;

    this.pageSize = this.EmpPaginator["_pageSize"];
    this.page = this.EmpPaginator["_pageIndex"] + 1;
    this.getAllUserList().then(() => {
      this.updateDevList();
    });
  }

  filterUsers(event) {
    this.developerList = this.allUsers.filter((item) =>
      item.name.toLowerCase().includes(event.toLowerCase())
    );
    this.filteredList = this.developerList;

  }

  ngOnDestroy() {
    if (this.userDataSubscription1) {
      this.userDataSubscription1.unsubscribe();
    }
    if (this.userDataSubscription2) {
      this.userDataSubscription2.unsubscribe();
    }
    this._messagingService.resetData()
  }

  getDevList() {
    this.stopLoading = true;
    if (this.isTourActive()) {
      this._projectService
        .getUserList(this.page, this.pageSize || 25)
        .subscribe((user: any) => {
          this.developerList = [];
          this.developerList = user;
          this._projectService.updateEmployeeListData({ data: true })
          this.filteredList = this.developerList;
          this.allUsers = user;

          if (localStorage.getItem('dbPath')) {
            this.userDataSubscription2 = this._messagingService.getUserData().subscribe((users) => {
              users.forEach((o) => {
                if (this.developerList) {

                  let index = _.findIndex(this.developerList, function (obj: any) {
                    return obj.id == o.id;
                  });

                  if (index > -1) {
                    const isLogoutReason = this.checkLogoutReason(o.logoutReason?.time, o.lastOnlineAtTz);
                    if (o.logoutReason && isLogoutReason) {
                      console.log("o.logoutReason", o.logoutReason)
                      if (o.logoutReason?.reason == 'timeOrTimezoneChange') {
                        this.developerList[index]["status"] = 'auto-logout';
                        this.developerList[index]["logoutReason"] = { 'reason': 'Time or Timezone Changed.', 'time': moment.utc(o.logoutReason?.time).local().format("YYYY-MM-DD HH:mm:ss") };
                      }

                      if (o.logoutReason?.reason == 'resetPassword') {
                        this.developerList[index]["status"] = 'logout-reset';
                        this.developerList[index]["logoutReason"] = { 'reason': 'User must re-login after password reset.', 'time': moment.utc(o.logoutReason?.time).local().format("YYYY-MM-DD HH:mm:ss") };
                      }
                    } else if (o.lastOnlineAtTz) {
                      this.developerList[index]["status"] = this._sharedService.getUserCurrentStatus(o.lastOnlineAtTz, true);
                    } else if (!o.lastOnlineAtTz && o.lastOnlineAt) {
                      this.developerList[index]["status"] = this._sharedService.getUserCurrentStatus(o.lastOnlineAt, false);
                    } else {
                      this.developerList[index]["status"] = 'not-using';
                    }

                    if (o.osInfo) {
                      if (o.osInfo.os && o.osInfo.release) {
                        this.developerList[index]["os"] = this.getOSInfo(o.osInfo);
                      }
                    }

                    // for application information about version and application type.
                    if (o.appInfo) {
                      if (o.appInfo.applicationType && o.appInfo.version) {
                        o.appInfo.label = o.appInfo.applicationType.split("")[0].toUpperCase();
                        o.appInfo.applicationType = o.appInfo.applicationType[0].toUpperCase() + o.appInfo.applicationType.slice(1);
                        this.developerList[index]["appInfo"] = o.appInfo;
                      }
                    } else {
                      if (this.developerList[index]["status"] != "not-using") {
                        this.developerList[index]["appInfo"] = { version: "4.1.2" }
                      } else {
                        this.developerList[index]["appInfo"] = "";
                      }
                    }

                    if (
                      o.ssRequest != true &&
                      o.ssRequest != false &&
                      o.ssRequest != undefined &&
                      o.ssRequest != null
                    ) {
                      this.developerList[index]["ssRequest"] =
                        o.ssRequest["createdAt"];
                    }
                    this.filteredList = this.developerList;
                  }
                }
              });

              this.stopLoading = false;
              this.loading = false;
            });
          }
        });
    } else {
      this.developerList = this._tourService.getEmployeeListjson();
      // this.stopLoading = false;
      // this.loading = false;
    }
  }

  updateDevList() {
    console.log("all user list: ", this.allUserList);

    if (!this.isTourActive()) {
      this.stopLoading = false;
      this.developerList = this._tourService.getEmployeeListjson();
      return;
    }

    this.paginateDevelopers();

    const dbPath = localStorage.getItem('dbPath');
    if (!dbPath) return;

    this.userDataSubscription2 = this._messagingService.getUserData().subscribe((users) => {
      users.forEach((user) => {
        const index = _.findIndex(this.allUserList, (u: any) => u.id === user.id);
        if (index < 0) return;

        this.updateUserStatus(index, user);
        this.updateUserAppInfo(index, user);
        this.updateUserSsRequest(index, user);

        this.paginateDevelopers();
      });

      this.loading = false;
    });

    this.ensureAppInfoExists();
  }

  private paginateDevelopers(): void {
    this.developerList = this.allUserList?.slice(
      (this.page - 1) * this.pageSize,
      this.page * this.pageSize
    );
    this.filteredList = this.developerList;
  }

  private updateUserStatus(index: number, user: any): void {
    const isLogoutReason = this.checkLogoutReason(user.logoutReason?.time, user.lastOnlineAtTz);

    if (user.logoutReason && isLogoutReason) {
      this.allUserList[index]["status"] = 'auto-logout';

      if (user.logoutReason?.reason === 'timeOrTimezoneChange') {
        this.allUserList[index]["logoutReason"] = {
          reason: 'Time or Timezone Changed.',
          time: moment.utc(user.logoutReason?.time).local().format("YYYY-MM-DD HH:mm:ss")
        };
      }

    } else if (user.lastOnlineAtTz) {
      this.allUserList[index]["status"] = this._sharedService.getUserCurrentStatus(user.lastOnlineAtTz, true);
    } else if (user.lastOnlineAt) {
      this.allUserList[index]["status"] = this._sharedService.getUserCurrentStatus(user.lastOnlineAt, false);
    } else {
      this.allUserList[index]["status"] = 'not-using';
    }
  }

  private updateUserAppInfo(index: number, user: any): void {
    const status = this.allUserList[index]["status"];

    if (user.appInfo?.applicationType && user.appInfo?.version) {
      const appType = user.appInfo.applicationType;
      user.appInfo.label = appType[0].toUpperCase();
      user.appInfo.applicationType = appType[0].toUpperCase() + appType.slice(1);
      this.allUserList[index]["appInfo"] = user.appInfo;
    } else {
      this.allUserList[index]["appInfo"] = status !== "not-using" ? { version: "4.1.2" } : "";
    }
  }

  private updateUserSsRequest(index: number, user: any): void {
    const ssRequest = user.ssRequest;
    if (ssRequest !== true && ssRequest !== false && ssRequest !== undefined && ssRequest !== null) {
      this.allUserList[index]["ssRequest"] = ssRequest["createdAt"];
    }
  }

  private ensureAppInfoExists(): void {
    this.developerList.forEach((developer: any) => {
      if (!developer.appInfo) {
        developer.appInfo = "";
      }
    });
  }

  sortData(sort: Sort) {
    if (this.allUserList.length > 0) {
      const data = this.allUserList.slice();
      if (!sort.active || sort.direction === "") {
        this.allUserList = data;
        return;
      }

      const sortedData = data.sort((a, b) => {
        const isAsc = sort.direction === "asc";
        switch (sort.active) {
          case "Employee Name":
            return this.compare(a.name.toLowerCase(), b.name.toLowerCase(), isAsc);
          case "status":
            const statusA: any = this.getStatusValue(a);
            const statusB: any = this.getStatusValue(b);
            return this.compare(statusA, statusB, isAsc);
          case 'screenshot':
            return this.compare(b.meta.last_ss, a.meta.last_ss, isAsc);
          case 'usedApps':
            console.log("")
            return this.compare(a.meta.last_application_name.toLowerCase(), b.meta.last_application_name.toLowerCase(), isAsc);
          case 'appInfo':
            const compareValue = this.getCompareValueOnVersion(a, b);
            return this.compare(compareValue[0], compareValue[1], isAsc);
          case 'role':
            return this.compare(a.role.length > 1 ? a.role[1] : a.role[0], b.role.length > 1 ? b.role[1] : b.role[0], isAsc);
          default:
            return 0;
        }
      });

      this.allUserList = sortedData;
      this.developerList = sortedData.slice((this.page - 1) * this.pageSize, (this.page) * this.pageSize);
      console.log("all users and developerList: ", sortedData, this.developerList, this.page, this.pageSize)

    }
  }

  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  getStatusValue(user) {
    switch (user?.status) {
      case "online":
        return "online";
      case "auto-logout":
        return "auto-logout";
      case undefined:
      case null:
      case "":
        return "invite";
      default:
        return "offline";
    }
  }

  getCompareValueOnVersion(compareValueOne, compareValueTwo) {
    let compareValue = [];
    if (compareValueOne.appInfo) {
      let value = compareValueOne.appInfo?.applicationType ? `${compareValueOne.appInfo?.version}" "${compareValueOne.appInfo?.applicationType}` : `${compareValueOne.appInfo?.version}`
      compareValue.push(value);
    } else {
      compareValue.push("");
    }

    if (compareValueTwo.appInfo) {
      let value = compareValueTwo.appInfo?.applicationType ? `${compareValueTwo.appInfo?.version}" "${compareValueTwo.appInfo?.applicationType}` : `${compareValueTwo.appInfo?.version}`
      compareValue.push(value);
    } else {
      compareValue.push("");
    }

    return compareValue;
  }

  openCreateTeamModal(iscreate, tid) {
    this.isTeamCreate = iscreate;
    this.teamSelectedId = tid;
    $("#CreateTeam").show();
  }

  closeCreateTeamModal() {
    $("#CreateTeam").hide();
  }

  openEmpModal(eId) {
    this._projectService.getEmployeeData(eId).subscribe((data: any) => {
      this.empName = data.name;
      this.empSlug = data.slug;
      $("#detailModal").show();
    });
  }

  closeEmpModal() {
    $("#detailModal").hide();
  }

  openeditEmployeemodal(eid) {
    this.employeeId = eid;
    $("#EditEmployee").show();
  }

  EditEmployee() {
    this.loading = true;
    if (!this.createUserForm.valid) {
      return;
    }
    this._projectService
      .editEmployee(this.employeeId, this.createUserForm.value)
      .subscribe((data) => {
        $("#EditEmployee").hide();
        this.pageSize = this.EmpPaginator["_pageSize"];
        this.page = this.EmpPaginator["_pageIndex"] + 1;
        this.getAllUserList().then(() => {
          this.updateDevList();
        });

      });
  }

  closeEditEmployeemodal() {
    $("#EditEmployee").hide();
  }

  getUSer() {
    if (this.isTourActive()) {
      let roleArr = [];
      this._projectService.getUser().subscribe((data: any) => {
        localStorage.setItem('user', JSON.stringify(data))
        let isExpired = this._sharedService.checkPlanExpired(data)
        this.expired = isExpired;
        roleArr = data.role;
        let quotaStatus = data.meta.quota_status;
        this.quota_remaining = data.meta.quota_remaining;
        this.quota_remaining = this.quota_remaining <= 0 ? 0 : this.quota_remaining;
        this.quotaStatus = quotaStatus;
        if (quotaStatus == 'active' || quotaStatus == 'Active') {
          if (this.expiringDate(data.meta.expires_at, data.meta.today_date) <= data.meta.adv_pay) {
            this.isTrialBanner = false;
            this.isExpiredBanner = true;
          }
        }
        if (quotaStatus == 'trial' || quotaStatus == 'Trial') {
          this.isTrialBanner = false;
          this.isExpiredBanner = true;
        }
        if (quotaStatus == 'trial-expired' || quotaStatus == 'Trial-expired' || quotaStatus == 'expired' || quotaStatus == 'Expired') {
          this.isTrialBanner = true;
          this.isExpiredBanner = false;
          this.isQuota = true;
        }
        if (sessionStorage.getItem('isTrialBanner')) {
          this.isTrialBanner = true;
          this.isExpiredBanner = true;
        }

        this.totalUser = data.meta.quota_allowed;
        this.availableUser = data.meta.quota_remaining == 'false' || data.meta.quota_remaining == false ? 0 : data.meta.quota_remaining;
        this.availableUser = this.availableUser < 0 ? 0 : this.availableUser;
        if (data.meta.expires_at) {
          this.getRemainingDate(data.meta.expires_at)
        }

        let adminRole = roleArr.filter((ele) => {
          return (ele.toLowerCase().includes("admin") || ele.toLowerCase().includes("company"));
        })
        if (adminRole.length == 0) {
          this.isAdmin = false;
        } else {
          this.isAdmin = true;
        }
      })
    } else {
      let roleArr = [];
      this._projectService.getUser().subscribe((data: any) => {
        localStorage.setItem('user', JSON.stringify(data))
        let isExpired = this._sharedService.checkPlanExpired(data)
        this.expired = isExpired;
        roleArr = data.role;
        let adminRole = roleArr.filter((ele) => {
          return (ele.toLowerCase().includes("admin") || ele.toLowerCase().includes("company"));
        })
        if (adminRole.length == 0) {
          this.isAdmin = false;
        } else {
          this.isAdmin = true;
        }
      })
    }
  }

  expiringDate(strExpiredDate, strTodayDate) {
    const expirationDate = new Date(strExpiredDate);
    const timeDifference = expirationDate.getTime() - new Date(strTodayDate).getTime();
    const daysDifference = timeDifference / (1000 * 60 * 60 * 24);

    return daysDifference + 1;
  }

  getRemainingDate(strDate) {
    const expirationDate = new Date(strDate);
    const timeDifference = expirationDate.getTime() - new Date().getTime();
    const daysDifference = timeDifference / (1000 * 60 * 60 * 24);
    this.daysRemaining = Math.round(daysDifference);
  }

  getDeveloperList(params) {
    this._loginService.getUserList(params).subscribe(
      (users: any) => {

        users.developers.forEach((developer: any) => {
          developer["screenShotTime"] = developer.screenshot
            ? this.imageNameAndDate(developer.screenshot, "date")
            : null;
        });

        users.developers.sort((a, b) => a.screenShotTime - b.screenShotTime);
        this.allUsers = users.developers.reverse();
        this.developerList = JSON.parse(JSON.stringify(this.allUsers));
        this.developerList.forEach((user, index) => {
          if (user.userRole == "admin") {
            this.developerList.splice(index, 1);
          }
        });
        this.filteredList = this.developerList;
        this.loading = false;
        this.updateStatus(this.statusToUpdate);
      },
      (error) => {
        this.loading = false;
      }
    );
  }

  getFirstName(name) {
    return name.split(" ")[0];
  }

  getLastName(name) {
    return name.split(" ")[1];
  }

  updateStatus(userToUpdate) {
    if (this.developerList) {
      _.forEach(userToUpdate, (id) => {

        let index = _.findIndex(this.developerList, function (o: any) {
          return o._id == id.user;
        });
        if (index > -1 && id.status == "online") {
          this.developerList[index]["status"] = id.status;
        } else if (index > -1) {
          delete this.developerList[index]["status"];
        }
        this.filteredList = this.developerList;
      });
    }
  }

  editProject() {
    this._router.navigate(["/projects/add-project"], {
      queryParams: { groupId: this.groupId },
    });
  }

  checkTeamLead(dev) {
    return dev.teamLead.includes(this.groupId) ? true : false;
  }

  getStatus(dev) {
    this._loginService.tickStatusChanged({
      userId: dev._id,
      status: dev.status,
    });
  }

  imageNameAndDate(image, type) {
    return this._sharedService.imageNameAndDate(image, type);
  }

  createTeam() {
    let formValue = this.createTeamForm.value;
    const team_managerArr: string[] = formValue.team_manager.split(",");
    let formatedTeam_manager: string = formValue.team_manager
      ? "~" + team_managerArr.join("~") + "~"
      : "";

    const team_leadArr: string[] = formValue.team_lead.split(",");
    let formatedTeam_lead: string = formValue.team_lead
      ? "~" + team_leadArr.join("~") + "~"
      : "";

    const team_developerArr: string[] = formValue.team_developer.split(",");
    let formatedTeam_developer: string = formValue.team_developer
      ? "~" + team_developerArr.join("~") + "~"
      : "";

    let teamData = {
      status: formValue.status,
      title: formValue.title,
      slug: formValue.slug,
      meta: {
        team_manager: formatedTeam_manager,
        team_lead: formatedTeam_lead,
        team_developer: formatedTeam_developer,
      },
    };

    if (this.isTeamCreate == true) {
      this._projectService.createTeamMember(teamData).subscribe((data) => {
        $("#CreateTeam").hide();
      });
    } else {

    }
  }

  openTeamDetailModal(tId) {
    this._projectService.getTeamDetail(tId).subscribe((data: any) => {
      $("#TeamdetailModal").show();
      this.teamSlug = data.slug;
      this.teamStatus = data.status;
      this.teamTitle = data.title.rendered;
    });
  }

  closeTeamDetailModal() {
    $("#TeamdetailModal").hide();
  }

  removeKeysWithUndefinedValues(obj: any): any {
    const newObj: any = {};

    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const value = obj[key];
        if (value !== undefined && value !== "") {
          newObj[key] = value;
        }
      }
    }

    return newObj;
  }

  searchEmployee(val: any) {
    this.isViewMore = false;
    this.developerList = this.allUserList;
    let svalue = val.toUpperCase();
    this.developerList = this.developerList.filter((ele) => {
      return ele.name.toUpperCase().includes(svalue);
    });
    if (val == "") {
      this.isViewMore = true;
      this.updateDevList();
    }
  }

  btnViewMoreAction() {
    this.page = this.page + 1;
    this.getDevList();
  }

  onPaginateChange(event) {

    this.pageSize = this.EmpPaginator["_pageSize"];
    this.page = this.EmpPaginator["_pageIndex"] + 1;
    this.updateDevList();
  }

  trialCloseBanner() {
    sessionStorage.setItem('isTrialBanner', 'false');
    this.isTrialBanner = true;
    this.isExpiredBanner = true;
  }

  checkLogoutReason(logoutTime: string, lastActiveTime: string) {
    logoutTime = moment(logoutTime).format('YYYY-MM-DD HH:mm:ss');
    lastActiveTime = moment(lastActiveTime).format('YYYY-MM-DD HH:mm:ss');
    // return true if logout time is greater than last active time
    return moment(logoutTime).isAfter(lastActiveTime);
  }

  getOSInfo(osInfo) {
    const { os: platform, release } = osInfo;

    if (platform === 'win32') {
      const build = parseInt(release.split('.')[2], 10);
      if (release.startsWith('6.1')) return 'Windows 7';
      if (release.startsWith('6.2')) return 'Windows 8';
      if (release.startsWith('6.3')) return 'Windows 8.1';
      if (release.startsWith('10.0')) {
        return build >= 22000 ? 'Windows 11' : 'Windows 10';
      }
      return 'Unknown Windows version';
    }

    if (platform === 'darwin') {
      const darwinMajor = parseInt(release.split('.')[0], 10);
      const macOsRelease: any = this.sysConfig.macOsRelease
      const macOSVersionMap = new Map(macOsRelease);
      return macOSVersionMap.get(darwinMajor) || `Unknown macOS (Darwin ${darwinMajor})`;
    }

    return 'Unknown OS';
  }
}

import { DatePipe } from '@angular/common';
import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { MessagingServiceService } from 'src/app/Services/messaging-service.service';
import { ProjectService } from 'src/app/Services/project.service';
import { SharedService } from 'src/app/Services/sharedService';
import { ApplyCouponComponent } from 'src/app/user-plans/apply-coupon/apply-coupon.component';
import swal from 'sweetalert2';
import { passwordValidator } from 'src/app/password-validators';

@Component({
  selector: 'app-user-profile-info',
  templateUrl: './user-profile-info.component.html',
  styleUrl: './user-profile-info.component.css',
  providers: [DatePipe],
})
export class UserProfileInfoComponent {
  hideCurrent: boolean = true;
  hideNew: boolean = true;
  hideConfirm: boolean = true;
  userProfileForm: FormGroup;
  changePasswordForm: FormGroup;
  id;
  role = '';
  userName = '';
  quotaStatus = '';
  quota_expired_date;
  totalUser = 0;
  availableUser = 0;
  useUser = 0;
  isAdmin = true;
  currentQuotaStatus = '';
  loading = false;
  company_name = '';
  plan_type = '';
  constructor(public _dialog: MatDialog, public formBuilder: FormBuilder, private _projectService: ProjectService, private _snackBar: MatSnackBar, private datePipe: DatePipe, private _sharedService: SharedService, private _messagingService: MessagingServiceService, private router: Router) {
    this.loading = true;
    this.getUser()
  }

  ngOnInit(): void {
    this.createProfileForm();
    this.createChangePasswordForm();
  }
  createProfileForm() {
    this.userProfileForm = this.formBuilder.group({
      name: new FormControl(''),
      email: new FormControl(''),
      phone: new FormControl(''),
      role: new FormControl('')
    })
  }
  createChangePasswordForm() {
    this.changePasswordForm = this.formBuilder.group({
      currentPassword: new FormControl('', Validators.required),
      newPassword: new FormControl("", [Validators.required, Validators.minLength(8), passwordValidator()]),
      confirmPassword: new FormControl('', Validators.required),
    }, { validators: this.passwordMatchValidator });
  }

  passwordMatchValidator(form: any) {
    return form.get('newPassword').value === form.get('confirmPassword').value ? null : { 'mismatch': true };
  }

  getUser() {
    let roleArr = [];
    this._projectService.getUser().subscribe((data: any) => {
      this.plan_type = data.meta.plan_name ? data.meta.plan_name : '';
      this.company_name = data.meta.company_name ? data.meta.company_name : '';
      localStorage.setItem('user', JSON.stringify(data))
      let isExpired = this._sharedService.checkPlanExpired(data)
      if (isExpired) {
        return this.router.navigateByUrl('/plan-expired');
      }
      roleArr = data.role;
      this.id = data.id;
      let user_role = roleArr.length ? roleArr[0] == 'employee' ? roleArr[1] : roleArr[0] : '';
      this.role = user_role;
      this.userProfileForm = this.formBuilder.group({
        name: new FormControl({
          value: data.name,
          disabled: true
        }),
        email: new FormControl({
          value: data.email,
          disabled: true
        }),
        phone: new FormControl({
          value: data.meta.phone_number ? data.meta.phone_number : 'none',
          disabled: true
        }),
        role: new FormControl({
          value: user_role == 'company' ? 'admin' : user_role,
          disabled: true,
        }),
      })
      let quota_Status = data.meta.quota_status;
      this.currentQuotaStatus = quota_Status;
      if (quota_Status == 'active' || quota_Status == 'Active' || quota_Status == 'trial' || quota_Status == 'Trial') {
        this.quotaStatus = 'active';
        if (data.meta.expires_at) {
          let day_left = this.getRemainingDate(data.meta.expires_at)
          if (day_left < 0) {
            this.quotaStatus = 'expired';
          }
        }
      }
      if (quota_Status == 'trial-expired' || quota_Status == 'Trial-expired' || quota_Status == 'expired' || quota_Status == 'Expired') {
        this.quotaStatus = 'expired';
      }
      if (data.meta.expires_at) {
        this.quota_expired_date = this.formattedDate(data.meta.expires_at);
      }
      this.totalUser = data.meta.quota_allowed < 0 ? 0 : data.meta.quota_allowed;
      this.availableUser = data.meta.quota_remaining == 'false' || data.meta.quota_remaining == false ? 0 : data.meta.quota_remaining;
      this.useUser = (data.meta.quota_allowed - this.availableUser) < 0 ? 0 : (data.meta.quota_allowed - this.availableUser)
      this.availableUser = this.availableUser < 0 ? 0 : this.availableUser;

      let adminRole = roleArr.filter((ele) => {
        return (ele.toLowerCase().includes("admin") || ele.toLowerCase().includes("company"));
      })
      if (adminRole.length == 0) {
        this.isAdmin = false;
      } else {
        this.isAdmin = true;
      }
      this.loading = false;
    }, err => {
      this.loading = false;
    })
  }

  changePasswordAction() {
    if (!this.changePasswordForm.valid || this.changePasswordForm.get('newPassword').value !=
      this.changePasswordForm.get('confirmPassword').value) {
      return
    } else {
      this
        ._projectService
        .changePasword(this.changePasswordForm.value.currentPassword, this.changePasswordForm.value.newPassword, this.id).subscribe((user: any) => {
          swal.fire({
            icon: 'success',
            title: 'Success',
            text: 'Password change successfully',
            timer: 2000,
            showConfirmButton: false,
          })
          localStorage.clear();
          this._sharedService.logInStateSubject.next(false);
          this.router.navigate(["/login"]);
          this._messagingService.resetData();
        }, err => {
          this._snackBar.open(err.error.message, 'Undo', {
            duration: 3000
          })
        })
    }
  }

  isTourActive() {
    if (localStorage.getItem('isTour')) {
      if (localStorage.getItem('isTour') == 'false') {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  formattedDate(expiresAt): string {
    const parsedDate = new Date(expiresAt);
    const formattedDate = this.datePipe.transform(parsedDate, 'MMM dd, yyyy');

    return formattedDate || '';
  }
  getRemainingDate(strDate) {
    const expirationDate = new Date(strDate);
    const timeDifference = expirationDate.getTime() - new Date().getTime();
    const daysDifference = timeDifference / (1000 * 60 * 60 * 24);
    let daysRemaining = Math.round(daysDifference);
    return daysRemaining;
  }

  applyCouponAction() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.autoFocus = true;

    dialogConfig.minWidth = 400;

    const dialogRef = this._dialog.open(ApplyCouponComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      console.log(result, "result")
      if (result) {
      } else {
        this._snackBar.open('Coupon apply successfully', 'Undo', {
          duration: 3000
        })
        this.getUser();
      }

    });
  }
}

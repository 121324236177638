<policy-header *ngIf="!isLogin"/>
<div class="container-fluid">
    <div class="row m-0">
        <div class="col s12">
            <h1 class="my-1 text-center">Refund Policy</h1>
        </div>
    </div>
    <hr>
    <div class="row m-0">
        <div class="col s12">
            <h3 class="my-1">Returns</h3>
        </div>
        <div class="col s12">
            <p class="text my-0-50">
                Our returns policy lasts for <b class="bold-text">24 hours</b>. If <b class="bold-text">24 hours</b>
                have gone by since your purchase, unfortunately we
                can’t offer you a refund or exchange.
            </p>
            <p class="text my-0-50">
                To be eligible for a return, the product and/or services purchased should either be non-working due to
                technical reasons from our side even after 24 hours of your purchase. This does not include the
                maintenance of the product and for regular updates to features and tools in the products/services, which
                would otherwise be stated. The refund shall be eligible for the subscription amount that was last agreed
                and selected upon, at the time of sign-up process by you with that particular service of ours; for which
                successful payments were made and recorded in our system.
            </p>
            <p class="text my-0-50">
                Additional non-returnable items
            </p>
            <ul>
                <li>Some health and personal care items.</li>
                <li>Downloadable software products. (does not include subscription based products/services).</li>
                <li>To complete your return, we require a receipt or proof of purchase. There are certain situations
                    where only partial refunds are granted:</li>
                <li>Any item not in its original condition, is damaged or missing parts for reasons not due to our
                    error.</li>
                <li>Any item that is returned more than <b class="bold-text">24 hours</b> after delivery.</li>
            </ul>
        </div>
    </div>
    <div class="row mt-1">
        <div class="col s12">
            <h3 class="my-1">Refunds</h3>
        </div>
        <div class="col s12">
            <p class="text my-0-50">
                Once you raise a query for return along with a valid invoice receipt, it is received and inspected, we
                will send you an email to notify you that we have received your query. We will also notify you of the
                approval or rejection of your refund.
            </p>
            <p class="text my-0-50">
                If you are approved, then your refund will be processed, and a credit will automatically be applied to
                your credit card, original method of payment, or the bank account information stored with us for that
                particular product/service for your account (if such a provision is available), within a certain amount
                of days.
            </p>
            <p class="text my-0-50">
                We will process your refunds once everything checks out fine within a period of 7-10 working days. The
                mode of refund credits to your account will be through <b class="bold-text">[NetBanking via Razorpay or
                    directly]</b>. Bank
                Account details will be asked to you if not already saved by you in the product/service that you opted
                for itself; once the refund is granted.
            </p>
        </div>
    </div>
    <div class="row mt-1">
        <div class="col s12">
            <h3 class="my-1">Late or missing refunds</h3>
        </div>
        <div class="col s12">
            <p class="text m-0">
                If you haven’t received a refund yet, first check your bank account again. Then contact your credit card
                company, it may take some time before your refund is officially posted. Next contact your bank. There is
                often some processing time before a refund is posted. If you’ve done all of this and you still have not
                received your refund yet, please contact us at <a href="mailto:hello@raoinformationtechnology.com"
                    class="link">{{'hello@raoinformationtechnology.com'}}</a>
            </p>
        </div>
    </div>
    <div class="row mt-1">
        <div class="col s12">
            <h3 class="my-1">Sale items</h3>
        </div>
        <div class="col s12">
            <p class="text m-0">
                Only regular priced items may be refunded and unfortunately sale items cannot be refunded.
            </p>
        </div>
    </div>
    <div class="row mt-1">
        <div class="col s12">
            <h3 class="my-1">Exchanges</h3>
        </div>
        <div class="col s12">
            <p class="text m-0">
                We don’t do exchanges.
            </p>
        </div>
    </div>
    <div class="row mt-1">
        <div class="col s12">
            <h3 class="my-1">Gifts</h3>
        </div>
        <div class="col s12">
            <p class="text my-0-50">
                If the item was marked as a gift when purchased and shipped directly to you, you’ll receive a gift
                credit for the value of your return. Once the returned item is received, a gift certificate will be
                mailed to you.If the item wasn’t marked as a gift when purchased, or the gift giver had the order
                shipped to themselves to give to you later, we will send a refund to the gift giver and he will find out
                about your return.
            </p>
            <p class="text my-0-50">
                Since it is a software product sold as a service, we will contact you for any discrepancy regarding the
                order placed by you. And the refund process would be continued with the contact information of the
                person, provided to us via the product/service for which you registered and require the refund for.
            </p>
            <p class="text my-0-50">
                If there is any other information you require regarding the refunds, send us an email with the Subject
                as:“Refunds – ” at <b><a href="mailto:hello@raoinformationtechnology.com"
                        class="link">{{'hello@raoinformationtechnology.com'}}</a></b>
            </p>
        </div>
    </div>
</div>
import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { DatePipe, Location } from '@angular/common';

@Component({
  selector: 'app-user-options',
  templateUrl: './user-options.component.html',
  styleUrls: ['./user-options.component.css'],
  providers: [DatePipe],
})
export class UserOptionsComponent {
  @Input() isUser: boolean = false;
  @Input() employeeList: any[] = [];
  @Input() selectedUser: any;
  @Input() startDate: any;
  @Input() endDate: any;
  @Input() selectDate: any;
  @Input() todayDate: any;
  @Output() toggleChange = new EventEmitter<boolean>();
  @Output() userSelection = new EventEmitter<void>();
  @Output() startDateChange = new EventEmitter<any>();
  @Output() endDateChange = new EventEmitter<any>();
  @Output() selectDateChange = new EventEmitter<any>();
  filteredEmpList: any[];

  ngOnChanges(changes: SimpleChanges) {
    if (changes['employeeList']) {
      this.filteredEmpList = this.employeeList || [];
    }
  }

  toggleUserWise(event: any) {
    if (event.checked) {
      this.isUser = true
    }
    this.toggleChange.emit(event.checked);
  }

  userSelectionChange() {
    let employee;
    if (this.employeeList) {
      this.employeeList.forEach((element) => {
        if (element.id === this.selectedUser) {
          employee = element
        }
      });
    }
    this.userSelection.emit(employee);
  }

  // 🔹 FIX: Add `searchEmployee`
  searchEmployee(val: string) {
    let svalue = val.toLowerCase();
    this.filteredEmpList = this.employeeList.filter((ele) => {
      return ele.name.toLowerCase().includes(svalue);
    });
    if (val == "") {
      this.filteredEmpList = this.employeeList;
    }
    // this.employeeList = this.employeeList.filter(emp => emp.name.toLowerCase().includes(query.toLowerCase()));
  }

  employeeSelected(employee: any) {
    this.userSelection.emit(employee);
  }

  changeDate(isPrevious: boolean) {
    const dateChange = isPrevious ? -1 : 1;
    const newDate = new Date(this.selectDate);
    newDate.setDate(newDate.getDate() + dateChange);
    const formattedDate = newDate.toISOString().split('T')[0];
    this.selectDateChange.emit(formattedDate);
  }

  onStartDateChange(event: any) {
    if (event.value) {
      this.startDateChange.emit(event.value);
    }
  }

  onEndDateChange(event: any) {
    if (event.value) {
      this.endDateChange.emit(event.value);
    }
  }

  onSelectDateChange(event: any) {
    this.selectDateChange.emit(event.value);
  }
}

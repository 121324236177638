import { Component } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ProjectService } from '../Services/project.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SharedService } from '../Services/sharedService';
import { LoginService } from '../Services/login.service';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { VerificationCodeComponent } from '../verification-code/verification-code.component';
import { IConfig } from 'ngx-countries-dropdown';
import { passwordValidator } from '../password-validators';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.css']
})
export class SignupComponent {
  signupForm: UntypedFormGroup;
  emailPattern = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/;
  hide = true;
  visible = true;
  isDisable = false;
  isGoogleSignIn = false;
  countries = [];
  selectedCode = '+91';
  isSelected = false;
  constructor(private router: Router, private _projectService: ProjectService, private _snackBar: MatSnackBar, private _sharedServices: SharedService, private loginService: LoginService, public dialog: MatDialog
  ) {
    this._sharedServices.sideMenuSubject.next(false);

    this.signupForm = new UntypedFormGroup({
      company: new UntypedFormControl('', [Validators.required]),
      firstName: new UntypedFormControl('', [Validators.required]),
      lastName: new UntypedFormControl("", [Validators.required]),
      email: new UntypedFormControl("", [Validators.required, Validators.pattern(this.emailPattern)]),
      contact: new UntypedFormControl("", [Validators.required, Validators.pattern(/^\d{0,3}\d{10}$/)]),
      password: new UntypedFormControl("", [Validators.required, Validators.minLength(8), Validators.maxLength(20), passwordValidator()]),
      confirmPassword: new UntypedFormControl("", [Validators.required]),
    }, { validators: this.passwordMatchValidator });
  }

  passwordMatchValidator(form: UntypedFormGroup) {
    return form.get('password').value === form.get('confirmPassword').value ? null : { 'mismatch': true };
  }

  signUpAction() {
    this.isDisable = true;
    if (this.selectedCode == '') {
      this.isSelected = true;
      return;
    }
    if (!this.signupForm.valid || this.signupForm.get('password').value !=
      this.signupForm.get('confirmPassword').value) {
      this.isDisable = false;
      return
    } else {
      this
        ._projectService
        .doSignUp(this.signupForm.value, this.selectedCode ? this.selectedCode : '+91').subscribe((user: any) => {
          localStorage.setItem('current_user', JSON.stringify(user))
          localStorage.setItem('current_email', user.email)
          this.router.navigate(["/verification-code"], { queryParams: { type: 'signup' } });
          this.isDisable = false;
        }, err => {
          this.isDisable = false;
          this._snackBar.open(err.error.message, 'Undo', {
            duration: 3000
          })
        })
    }
  }

  gotoSignIn() {
    this.router.navigate(["/login"]);
  }

  googleSigninAction() {

    this.loginService.GoogleAuth().then((result) => {
      let user: any = result.additionalUserInfo.profile;
      let strEmail = this.signupForm.get('email');
      let strFirstName: any = this.signupForm.get('firstName')
      let strLastName: any = this.signupForm.get('lastName')
      let name = user.name.split(' ');
      strEmail.patchValue(user.email);
      strFirstName = name && name[0] ? strFirstName.patchValue(name[0]) : '';
      strLastName = name && name[1] ? strLastName.patchValue(name[1]) : '';
      this.isGoogleSignIn = true;
    })
  }



  selectedCountryConfig: IConfig = {
    hideCode: true,
    hideName: true,
    hideDialCode: true
  };
  countryListConfig: IConfig = {
    hideCode: true,
    hideName: true
  };

  onCountryChange(country: any) {
    this.isSelected = false;
    this.selectedCode = country.dialling_code ? country.dialling_code : '';
  }

  openSupersee() {
    window.open('Https://supersee.io', '_blank')
  }
}


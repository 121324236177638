import { Component, Inject, Input } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ProjectService } from '../Services/project.service';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.css']
})
export class ConfirmationDialogComponent {
  // @Input() msgType = "";
 

  constructor(private _dialogRef: MatDialogRef<ConfirmationDialogComponent>,private _projectService:ProjectService, @Inject(MAT_DIALOG_DATA) public data: any) {
   } 

  closeDialog() {
    this._dialogRef.close();  
  }

  deleteUser() {
    this._dialogRef.close({ delete : this.data.id }); 
  }
}

<div class="main_section">
    <div class="plan_header_section">
        <div class="box_section">
            <div>
                <div class="content_section">
                    <h3>Your plan has ended</h3>
                    <p>Thank you for using Supersee.</p>
                    <!-- <p>Your free trial period has ended</p><br> -->
                    <p *ngIf="isAdmin">To continue seeing all reports & screenshots, please upgrade your plan.</p>
                    <p *ngIf="!isAdmin">Please reach out to your administrator for assistance.</p>
                </div>
            </div>
            <div class="btn_section" *ngIf="isAdmin">
                <button class="buy_now" (click)="sendPaymentRequest()">Upgrade plan</button>
            </div>
        </div>
    </div>
</div>
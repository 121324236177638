import { Injectable } from '@angular/core';
import { HttpRequest, HttpResponse } from '@angular/common/http';

interface CachedData {
  response: HttpResponse<any>;
  addedTime: number;
}

@Injectable({
  providedIn: 'root'
})
export class CacheService {
  private cache = new Map<string, CachedData>();
  private cacheTTL = 5 * 60 * 1000; // 5 minutes in milliseconds

  get(req: HttpRequest<any>): HttpResponse<any> | null {
    const url = req.urlWithParams;
    const cached = this.cache.get(url);

    if (!cached) return null;

    const isExpired = (Date.now() - cached.addedTime) > this.cacheTTL;
    if (isExpired) {
      this.cache.delete(url);
      return null;
    }

    return cached.response;
  }

  put(req: HttpRequest<any>, response: HttpResponse<any>): void {
    const url = req.urlWithParams;
    const entry: CachedData = {
      response,
      addedTime: Date.now()
    };
    this.cache.set(url, entry);
  }

  clear(): void {
    this.cache.clear();
  }
}

<form [formGroup]="uploadCSVForm">
    <div class="container-fluid">
        <div class="modal-content">
            <div class="row d-flex align-items-center">
                <div class="col m10 s10 import-contain" style="padding: 0px;">
                    <h1>
                        Import Employees
                    </h1>
                </div>
                <div class="col m2 s2 import-btn-contain">
                    <button class="btn  modal-close btn-flat" (click)="modalClose()">
                        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="41" viewBox="0 0 40 41" fill="none">
                            <path
                                d="M20.0002 3.8335C10.7835 3.8335 3.3335 11.2835 3.3335 20.5002C3.3335 29.7168 10.7835 37.1668 20.0002 37.1668C29.2168 37.1668 36.6668 29.7168 36.6668 20.5002C36.6668 11.2835 29.2168 3.8335 20.0002 3.8335ZM28.3335 26.4835L25.9835 28.8335L20.0002 22.8502L14.0168 28.8335L11.6668 26.4835L17.6502 20.5002L11.6668 14.5168L14.0168 12.1668L20.0002 18.1502L25.9835 12.1668L28.3335 14.5168L22.3502 20.5002L28.3335 26.4835Z"
                                fill="#D9D9D9" />
                        </svg>
                    </button>
                </div>
            </div>
            <div class="modal-body">
                <div class="row mt-2 choose-csv-container">
                    <div class="col s12 choose-csv">
                        <span class="material-symbols-outlined file-icon">
                            upload
                        </span>
                        <input #csvInput mat-input hidden="true" onclick="this.value=null" type="file"
                            (change)="uploadCSV($event)" accept=".csv">
                        <button mat-raised-button class="btn other-btn" (click)="csvInput.click()">
                            Choose file
                        </button>
                    </div>
                </div>
                <div class="row">
                    <div class="col s12" *ngIf="noDataErrorMsg.hasError">
                        <p class="error">*{{ noDataErrorMsg.message }}</p>
                    </div>
                    <div class="col s12" *ngIf="headerErrorMsg.hasError">
                        <p class="error">*{{ headerErrorMsg.message }}</p>
                    </div>
                </div>
                <div class="row" style="padding: 0px; margin-bottom: 0px;">
                    <div class="col s12" *ngIf="fileObjectToShow.hasShowObject" class="file-upload">
                        <p class="file-name">{{ fileObjectToShow.fileName }} ( {{ fileObjectToShow.data }} Employees )
                            File Selected
                        </p>
                    </div>
                </div>
                <div class="row" style="margin-bottom: 10px;">
                    <div class="col s12" style="padding: 0px;">
                        <p class="send-email" style="padding: 0px;">Send E-mail to Employees?</p>
                        <mat-slide-toggle color="primary" formControlName="sendEmail"></mat-slide-toggle>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <div class="row footer-container">
                    <div class="col m6 s12 download-container" style="padding: 0px;">
                        <a #downloadLink
                            href="https://api.supersee.staging.raoinfo.tech/wp-content/uploads/ss-employees-import.csv"
                            download="ss-employees-import.csv" class="btn-link">
                            <button mat-raised-button class="btn download-btn" (click)="downloadLink.click()">
                                Download Sample CSV File
                            </button>
                        </a>
                    </div>
                    <div class="col m6 s12 footer-contain" style="padding: 0px;">
                        <button mat-raised-button class="btn cancel-btn" (click)="modalClose()">Cancel</button>
                        <button mat-raised-button [disabled]="!uploadCSVForm.valid || disabled"
                            (click)="importEmployees()" class="btn import-btn">Import</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>
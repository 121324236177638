<!-- <div id="donutChartNewContainer"></div> -->
<div class="relative" *ngIf="!isChartWebBrowserUser">
  <div id="donutChartNewContainer">
    <canvas #applicationUsageChart></canvas>
  </div>
  <div class="absolute-center text-center" style="margin-top: 12px;">
    <div class="hoursValue">{{maxTime}}</div>
    <div class="hoursText">Hours</div>
  </div>
</div>

<div class="relative" *ngIf="isChartWebBrowserUser">
  <div id="webBrowserShowingChart">
    <canvas #webBrowserShowingChartCanvas></canvas>
  </div>
  <div class="absolute-center text-center" style="margin-top: 12px;">
    <div class="hoursValue">{{maxTimeWebsiteUsage}}</div>
    <div class="hoursText">Hours</div>
  </div>
</div>


import {
  HttpClient,
  HttpHandler,
  HttpHeaders,
  HttpParams,
} from "@angular/common/http";
import { EventEmitter, Injectable, Injector, numberAttribute } from "@angular/core";
import { config } from "../config";
import { skip } from "rxjs/operators";
import { BehaviorSubject, Observable } from "rxjs";
import { Overlay, OverlayRef } from "@angular/cdk/overlay";
import { ScreencastActionModelComponent } from "../screencast/screencast-action-model/screencast-action-model.component";
import { ComponentPortal } from "@angular/cdk/portal";

@Injectable({
  providedIn: "root",
})
export class ProjectService {
  chardData: EventEmitter<any> = new EventEmitter();
  EmployeeListData: EventEmitter<any> = new EventEmitter();
  donutChart: EventEmitter<any> = new EventEmitter();
  pieChart: EventEmitter<any> = new EventEmitter();
  StackeChart: EventEmitter<any> = new EventEmitter();
  ChartLabel: EventEmitter<any> = new EventEmitter();
  BarChartData: EventEmitter<any> = new EventEmitter();
  ToastData: EventEmitter<any> = new EventEmitter();
  EmployeeList: EventEmitter<any> = new EventEmitter();
  ApplicationUSage: EventEmitter<any> = new EventEmitter();
  webSiteUsage: EventEmitter<any> = new EventEmitter();
  selsectedTeam: EventEmitter<any> = new EventEmitter();
  isAdminLogin: EventEmitter<any> = new EventEmitter();
  screencastRequest: EventEmitter<any> = new EventEmitter();
  isLoginUser: EventEmitter<any> = new EventEmitter();
  isFirebaseRefresh: EventEmitter<any> = new EventEmitter();
  dashboardTourActive: EventEmitter<any> = new EventEmitter();
  deleteTeamList: EventEmitter<any> = new EventEmitter();
  editUserData: EventEmitter<any> = new EventEmitter();

  private overlayRef: OverlayRef;
  empCountSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    JSON.parse(localStorage.getItem("empCount") || null)
  );
  constructor(private http: HttpClient, private overlay: Overlay, private injector: Injector) { }
  getTotalHours(startDate, endDate, userId) {
    let url = config.baseUrl + "/user/admin/getTotalHours";

    var formData: FormData = new FormData();
    formData.append("startDate", startDate);
    formData.append("endDate", endDate);
    if (userId) {
      formData.append("userId", userId);
    }

    return this.http.post(url, formData);
  }

  updateApplicationUsageData(data) {
    if (data) {
      this.ApplicationUSage.emit(data);
    }
  }

  updateWebsiteUsageChart(data) {
    if (data) {
      this.webSiteUsage.emit(data);
    }
  }

  updateEditUserData(data) {
    this.editUserData.emit(data)
  }
  updateTeamDeletedData(data) {
    this.deleteTeamList.emit(data)
  }
  updateFirebaseData(data) {
    this.isFirebaseRefresh.emit(data)
  }
  updateDashboardTourData(data) {
    this.dashboardTourActive.emit(data)
  }
  updateLoginData(data) {
    this.isLoginUser.emit(data)
  }
  updateChartData(data) {
    this.chardData.emit(data)
  }
  updateEmployeeListData(data) {
    this.EmployeeListData.emit(data)
  }
  updateAdminLoginData(data) {
    this.isAdminLogin.emit(data)
  }
  updateSelectedTeamData(data) {
    this.selsectedTeam.emit(data)
  }
  updateStackChartData(data) {
    this.StackeChart.emit(data)
  }
  updateDonutChartData(data) {
    this.donutChart.emit(data)
  }
  updatePieChartData(data) {
    this.pieChart.emit(data)
  }
  updateEmployeeList(data) {
    this.EmployeeList.emit(data)
  }
  updateToastData(data) {
    this.ToastData.emit(data)
  }
  updateBarChartData(data) {
    this.BarChartData.emit(data)
  }
  updateChartLabel(data) {
    this.ChartLabel.emit(data)
  }
  screencaseRequestAction(data) {
    this.screencastRequest.emit(data)
  }
  getUserData() {
    return this.http.get(config.baseUrl + "/user/admin/getDashboardUsers");
  }
  getAnalysisCategories() {
    return this.http.get(config.baseUrl + "wp/v2/analysis-categories");
  }
  SendAccessToken(data, id) {
    return this.http.post(config.baseUrl + "wp/v2/users/" + id, data);
  }
  getlastFifteenPhoto(author, perPage, offset, page, after, before) {
    // return this.http.get(config.baseUrl + "/user/admin/getLast50Photos");
    if (author) {
      if (page != 0) {
        return this.http.get(config.baseUrl + "wp/v2/analysis?per_page=" + perPage + "&page=" + page + "&status=private&author=" + author);
      } else {
        return this.http.get(config.baseUrl + "wp/v2/analysis?per_page=" + perPage + "&offset=" + offset + "&status=private&author=" + author);
      }
    } else {
      if (page != 0) {
        if (after && before) {
          return this.http.get(config.baseUrl + "wp/v2/analysis?per_page=" + perPage + "&page=" + page + "&status=private" + "&after=" + after + "&before=" + before);
        } else {
          return this.http.get(config.baseUrl + "wp/v2/analysis?per_page=" + perPage + "&page=" + page + "&status=private");
        }
      } else {
        if (after && before) {
          return this.http.get(config.baseUrl + "wp/v2/analysis?per_page=" + perPage + "&offset=" + offset + "&status=private" + "&after=" + after + "&before=" + before, { observe: 'response' });
        } else {
          return this.http.get(config.baseUrl + "wp/v2/analysis?per_page=" + perPage + "&offset=" + offset + "&status=private", { observe: 'response' });
        }
      }
    }
  }
  getSingleSS(uid, perPage, offset, after, before) {
    // get single screen shot
    // return this.http.get(config.baseUrl + "/user/admin/getLast50Photos");
    return this.http.get(config.baseUrl + "wp/v2/analysis?per_page=" + perPage + "&page=" + offset + "&status=private&author=" + uid + "&after=" + after + "&before=" + before, { observe: 'response' });
  }
  getWorkinHour(strarDate, EndDate, emp) {
    if (emp) {
      // return this.http.get(config.baseUrl + "wp/v2/timesheet/getWorkingHours?start_date="+strarDate+"&end_date="+EndDate+"&author="+emp);
      return this.http.get(config.baseUrl + "wp/v2/total-working-idle-productive?start_date=" + strarDate + "&end_date=" + EndDate + "&author=" + emp);

    } else {
      return this.http.get(config.baseUrl + "wp/v2/total-working-idle-productive?start_date=" + strarDate + "&end_date=" + EndDate);
    }
  }



  /**
   * @param strarDate 
   * @param EndDate 
   * @param emp 
   * Employee ideal hours and productive hours data 
   */
  getWorkinHourV2(strarDate, EndDate, emp) {

    if (emp) {
      return this.http.get(config.baseUrl + "wp/v2/total-working-idle-productive-dashboard?start_date=" + strarDate + "&end_date=" + EndDate + "&author=" + emp);
    } else {
      return this.http.get(config.baseUrl + "wp/v2/total-working-idle-productive-dashboard?start_date=" + strarDate + "&end_date=" + EndDate + "&author=");
    }
  }
  // https://api.demo.supersee.io/wp-json/wp/v2/total-working-idle-productive-dashboard?start_date=2023-09-01&end_date=2023-10-01

  getPiechartData(strarDate, EndDate, emp) {
    if (emp) {
      // return this.http.get(config.baseUrl + "wp/v2/timesheet/getWorkingHours?start_date="+strarDate+"&end_date="+EndDate+"&author="+emp);
      return this.http.get(config.baseUrl + "wp/v2/applications-usage?start_date=" + strarDate + "&end_date=" + EndDate + "&author=" + emp);
    } else {
      return this.http.get(config.baseUrl + "wp/v2/applications-usage?start_date=" + strarDate + "&end_date=" + EndDate);
    }
  }


  /**
   * @param startDate 
   * @param EndDate 
   * @returns  Get details of application usage with thier time
   * Used that data to display top 10 application usage in chart
   */
  getAppUsageCount(startDate, EndDate, emp) {
    if (emp) {
      return this.http.get(config.baseUrl + "wp/v2/applications-usage-count?start_date=" + startDate + "&end_date=" + EndDate + "&author=" + emp);
    } else {
      return this.http.get(config.baseUrl + "wp/v2/applications-usage-count?start_date=" + startDate + "&end_date=" + EndDate);
    }
  }

  getWebsiteUsageData(startDate: any, endDate: any, employeeId: any) {
    if (employeeId) {
      return this.http.get(config.baseUrl + "wp/v2/website-usage-count?start_date=" + startDate + "&end_date=" + endDate + "&author=" + employeeId);
    } else {
      return this.http.get(config.baseUrl + "wp/v2/website-usage-count?start_date=" + startDate + "&end_date=" + endDate);
    }
  }

  getAppUsageTooltipData(appName, startDate, EndDate, type) {
    return this.http.get(config.baseUrl + "wp/v2/application-data?start_date=" + startDate + "&end_date=" + EndDate + "&app_name=" + appName + "&type=" + type);
  }

  // https://api.demo.supersee.io/wp-json/wp/v2/applications-usage-count?start_date=2023-09-01&end_date=2023-10-01

  getStackchartData(strarDate, page, search) {
    return this.http.get(config.baseUrl + "wp/v2/application-logs?start_date=" + strarDate + "&per_page=10&page=" + page + "&search=" + search);
  }
  getFilterStackchartDataByTeam(strarDate, page, search, author) {
    return this.http.get(config.baseUrl + "wp/v2/application-logs?start_date=" + strarDate + "&per_page=10&page=" + page + "&search=" + search + "&author=" + author);
  }

  getStackChartDataUser(userId, startDate, endDate) {
    return this.http.get(config.baseUrl + "wp/v2/application-logs?author=" + userId + "&end_date=" + endDate + "&start_date=" + startDate);
  }

  getUserApplicationLogs(userId, startDate, endDate) {
    return this.http.get(config.baseUrl + `wp/v2/user-application-logs/${userId}?start_date=${startDate}&end_date=${endDate}`);
  }

  getEmployeeData(id) {
    return this.http.get(config.baseUrl + "wp/v2/users/" + id);
  }
  DeleteEmployee(id) {
    return this.http.delete(config.baseUrl + "wp/v2/users/" + id + "?force=true&reassign=0");
  }
  getUser() {
    return this.http.get(config.baseUrl + "wp/v2/users/me");
  }
  editEmployee(id, user) {
    var formData: FormData = new FormData();
    if (user.first_name) {
      formData.append("first_name", user.first_name);
    }
    if (user.last_name) {
      formData.append("last_name", user.last_name);
    }
    // if(user.name){
    formData.append("name", user.first_name + " " + user.last_name);
    //}
    if (user.slug) {
      formData.append("slug", user.slug);
    }
    const roles = 'employee'
    user['roles'] = roles;
    if (user.roles2 != null || user.roles2 != '' || user.roles2 != undefined) {
      // if (user.roles == 'employee') {
      if (user.roles2) {
        user['roles'] = roles + ',' + user.roles2;
        // }
      }
    }
    delete user['roles2'];
    formData.append("roles", user.roles);

    return this.http.post(config.baseUrl + "wp/v2/users/" + id, formData);
  }
  getUserStatusCount() {
    return this.http.get(config.baseUrl + "/user/admin/getUserStatusCount");
  }
  createTeamMember(data) {
    return this.http.post(config.baseUrl + "wp/v2/teams", data);
  }
  editTeamMeamber(id, data) {
    return this.http.post(config.baseUrl + "wp/v2/teams/" + id, data);
  }
  getUserList(page, pageSize) {
    console.log(page, pageSize, "get users ")
    if (page == 0 && pageSize == 0) {
      return this.http.get(
        config.baseUrl +
        "wp/v2/users?roles=employee&per_page=200&offset=0"
      );
    } else {
      return this.http.get(
        config.baseUrl +
        "wp/v2/users?roles=employee&per_page=" + pageSize + "&offset=0&page=" + page
      );
    }

  }
  getFilteredTeam(str) {
    return this.http.get(
      config.baseUrl + "wp/v2/users?roles=" + str + "&per_page=100&offset=0"

    );
  }
  getTeamDetail(id) {
    return this.http.get(config.baseUrl + "wp/v2/teams/" + id);
  }
  getTeamList() {
    return this.http.get(
      config.baseUrl +
      "wp/v2/teams?per_page=100&offset=0"
    );
  }
  getUserAppUsage(userId, options) {
    let query = `?user=${userId}`;
    query = options.perPage
      ? `${query}&perPage=${options.perPage}`
      : `${query}&perPage=10000`;
    if (options.offset) query = `${query}&offset=0`;
    if (options.sortby) query = `${query}&sortby=openAt`;
    if (options.sortType) query = `${query}&sortType=1`;

    return this.http.get(`${config.baseUrl}/apps${query}`);
  }

  getUserAppUsageDashboard(options) {
    let query;

    if (options && options.userIds) {
      query = `?users=${options.userIds.toString()}`;
      if (options && options.startDate && options.endDate) {
        query = query + `&startDate=${options.startDate}&endDate=${options.endDate}`;
      }
    } else if (options && options.startDate && options.endDate) {
      query = `?startDate=${options.startDate}&endDate=${options.endDate}`;
    }

    // query = options.perPage ? `${query}&perPage=1000` : `${query}&perPage=1000`;
    // if (options.offset) query = `${query}&offset=0`;
    // if (options.sortby) query = `${query}&sortby=openAt`;
    // if (options.sortType) query = `${query}&sortType=1`;
    if (query)
      return this.http.get(`${config.baseUrl}/apps/dashboard/${query}`);
    else return this.http.get(`${config.baseUrl}/apps/dashboard`);
  }

  bookAppointmentStatuses(): Observable<any> {
    return this.empCountSubject.asObservable();
  }

  openScreencastModa() {
    this.overlayRef = this.overlay.create({
      positionStrategy: this.overlay.position().global().centerHorizontally().centerVertically(),
      hasBackdrop: true
    });

    const portal = new ComponentPortal(ScreencastActionModelComponent);
    this.overlayRef.attach(portal);
  }



  // GLOBAL SETTINGS API

  // Set global settings for all the applicants 
  setGlobalSettings(data, isEmployee) {
    let newData = new FormData()
    if (data.ss_screen_capture_time != '' || data.ss_screen_capture_time) { newData.append("ss_screen_capture_time", data.ss_screen_capture_time) }
    if (data.ss_appchange_interval != '' || data.ss_appchange_interval) { newData.append("ss_appchange_interval", data.ss_appchange_interval) }

    if (data.ss_image_messages_discord_url != '' || data.ss_image_messages_discord_url) { newData.append("ss_image_messages_discord_url", data.ss_image_messages_discord_url) }
    if (data.ss_status_messages_discord_url != '' || data.ss_status_messages_discord_url) { newData.append("ss_status_messages_discord_url", data.ss_status_messages_discord_url) }
    if (data.ss_summary_messages_discord_url != '' || data.ss_summary_messages_discord_url) { newData.append("ss_summary_messages_discord_url", data.ss_summary_messages_discord_url) }

    newData.append("ss_idle_time", data.ss_idle_time)
    // newData.append("ss_auto_send_summry_time", data.ss_auto_send_summry_time)
    newData.append("ss_appchange", data.ss_appchange)
    newData.append("ss_periodic", data.ss_periodic)
    newData.append("ss_webcam", data.ss_webcam)
    newData.append("ss_silent", data.ss_silent)
    newData.append("ss_strict_time_tracking", data.ss_strict_time_tracking)
    if (data.ss_personalizeSettings) {
      newData.append("ss_personalizeSettings", data.ss_personalizeSettings)
    }
    if (isEmployee) {
      let SettingnewData = new FormData();
      if (data.ss_screen_capture_time != '' && data.ss_screen_capture_time) { SettingnewData.append("settings[ss_screen_capture_time]", data.ss_screen_capture_time) }
      if (data.ss_idle_time != '' && data.ss_idle_time) { SettingnewData.append("settings[ss_idle_time]", data.ss_idle_time) }
      if (data.ss_auto_send_summry_time != '' && data.ss_auto_send_summry_time) { SettingnewData.append("settings[ss_auto_send_summry_time]", data.ss_auto_send_summry_time) }
      if (data.ss_appchange_interval != '' && data.ss_appchange_interval) { SettingnewData.append("settings[ss_appchange_interval]", data.ss_appchange_interval) }
      if (data.ss_image_messages_discord_url && data.ss_image_messages_discord_url != '') { SettingnewData.append("settings[ss_image_messages_discord_url]", data.ss_image_messages_discord_url) }
      if (data.ss_status_messages_discord_url && data.ss_status_messages_discord_url != '') { SettingnewData.append("settings[ss_status_messages_discord_url]", data.ss_status_messages_discord_url) }
      if (data.ss_summary_messages_discord_url && data.ss_summary_messages_discord_url != '') { SettingnewData.append("settings[ss_summary_messages_discord_url]", data.ss_summary_messages_discord_url) }
      SettingnewData.append("settings[ss_appchange]", data.ss_appchange)
      SettingnewData.append("settings[ss_periodic]", data.ss_periodic)
      SettingnewData.append("settings[ss_webcam]", data.ss_webcam)
      SettingnewData.append("settings[ss_silent]", data.ss_silent)
      SettingnewData.append("settings[ss_personalizeSettings]", data.ss_personalizeSettings)
      SettingnewData.append("settings[ss_strict_time_tracking]", data.ss_strict_time_tracking)
      return this.http.post(config.baseUrl + "wp/v2/users/" + isEmployee, SettingnewData);
    } else {
      return this.http.post(config.baseUrl + "wp/v2/optionskeys", newData)
    }
  }

  updateTour() {

    let newData = new FormData()
    newData.append("meta[ss_tooltip_visited] ", 'true')
    return this.http.post(config.baseUrl + "wp/v2/users/me", newData);
  }

  // Get global settings for all the applicants
  getGlobalSettings() {

    return this.http.get(config.baseUrl + "wp/v2/optionskeys")
  }

  deleteTeam(teamId) {
    return this.http.delete(config.baseUrl + "wp/v2/teams/" + teamId);
  }

  getOnlineOfflinePercentage() {

    return this.http.get(config.baseUrl + "wp/v2/dashboard-widgets")
  }

  doSignUp(data, selectedCode) {
    let newData = new FormData()
    newData.append("username", data.email)
    newData.append("first_name", data.firstName)
    newData.append("last_name", data.lastName)
    newData.append("email", data.email)
    newData.append("roles", 'company')
    newData.append("password", data.password)
    newData.append("meta[phone_number]", selectedCode + '-' + data.contact)
    newData.append("meta[company_name]", data.company)

    const username = 'rao-developer'
    const password = 'u33SwxnOo8CwONZNjmyjdwcY'
    const headers: HttpHeaders = new HttpHeaders({
      'Authorization': 'Basic ' + btoa(config.signUp_username + ':' + config.signUp_password)
    })
    return this.http.post(config.baseUrl + "wp/v2/users", newData, { headers })
  }
  VarifyCode(email, code) {
    return this.http.get(config.baseUrl + "wp/v2/verify-company" + "?email=" + email + "&code=" + code)
  }

  sendVarificationCode(email) {
    let newData = new FormData()
    newData.append("email", email)
    return this.http.post(config.baseUrl + "wp/v2/verify-company", newData)
  }

  sendResetPasswordEmail(email) {
    let newData = new FormData()
    newData.append("email", email)
    newData.append("type", 'reset')

    return this.http.post(config.baseUrl + "bdpwr/v1/reset-password", newData)
  }

  resetPasword(password, email, code) {
    let newData = new FormData()
    newData.append("email", email)
    newData.append("code", code)
    newData.append("password", password)

    return this.http.post(config.baseUrl + "bdpwr/v1/set-password", newData)
  }

  getQuota() {
    return this.http.get(config.baseUrl + "wp/v2/quota")
  }

  changePasword(currentPassword, newPassword, id) {
    let newData = new FormData()
    newData.append("current_password", currentPassword)
    newData.append("new_password", newPassword)

    return this.http.post(config.baseUrl + "wp/v2/users/" + id, newData)
  }

  checkUserPlan() {
    const headers: HttpHeaders = new HttpHeaders({
      'Authorization': 'Basic ' + btoa(config.payment_username + ':' + config.payment_password)
    })
    return this.http.get(config.baseUrl + "wc/v3/products", { headers });
  }

  purchasePlan(plan_id, qty, type, gst_no) {

    const data = {
      plan_id: plan_id,
      qty: qty,
      type: type,
      gst_no: gst_no
    };

    return this.http.post(config.baseUrl + "rao/v2/upgrade", data)
  }

  getInvoice() {
    return this.http.get(config.baseUrl + "rao/v2/invoices")
  }

  applyCoupon(coupon: any) {
    return this.http.get(config.baseUrl + "rao/v2/validateCoupon?coupon=" + coupon)
  }

  getUserReportLog(isToggle: boolean, userId: string, selectedDate: string, startDate?: string, endDate?: string, isApplicationUsage?: boolean) {
    const baseUrl = config.baseUrl + "wp/v2/";
    // Define URL based on the toggle status
    let url = isToggle
      ? `${baseUrl}single-attendance?start_date=${startDate}&end_date=${endDate}&user_id=${userId}`
      : `${baseUrl}user-attendance?date=${selectedDate}`;
    // Append app_logs=true if isApplicationUsage is true
    if (isApplicationUsage) {
      url += '&app_logs=true';
    }
    // Execute HTTP request
    return this.http.get(url);
  }

  downloadCSVSample() {
    const httpOptions = {
      responseType: 'blob' as 'json'
    }
    return this.http.get('https://api.supersee.staging.raoinfo.tech/wp-content/uploads/ss-employees-import.csv', httpOptions)
  }

  uploadCSVFile(form: any) {
    var formData = new FormData();
    formData.append("file", form.csv);
    formData.append("send_email", form.sendEmail)
    console.log("import formData: ", formData.getAll('formdata'), formData.getAll('send_email'))
    return this.http.post(config.baseUrl + "ss-front/v1/import-employees", formData)
  }
}

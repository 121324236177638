<div class="container-fluid" *ngIf="!loading">
  <form [formGroup]="userProfileForm">
    <mat-card class="system-config-card">
      <div class="row setting-row">
        <div class="col s8 m6">
          <h2 *ngIf="role != 'company'">{{role | titlecase}} details</h2>
          <h2 *ngIf="role == 'company'">{{role | titlecase}} profile : {{company_name}} </h2>
        </div>
      </div>
      <div class="setting-section system-config">
        <div class="row details">
          <div class="col s12 m6 input-box">
            <!-- <mat-form-field class="input_fields" appearance="outline"> -->
            <mat-label>Name:</mat-label>
            <input type="text" matInput formControlName="name" />
            <!-- </mat-form-field> -->
          </div>
          <div class="col s12 m6 input-box">
            <!-- <mat-form-field class="input_fields" appearance="outline"> -->
            <mat-label>Email:</mat-label>
            <input type="text" matInput formControlName="email" />
            <!-- </mat-form-field> -->
          </div>
          <div class="col s12 m6 input-box">
            <!-- <mat-form-field class="input_fields" appearance="outline"> -->
            <mat-label>Phone number:</mat-label>
            <input type="text" matInput formControlName="phone" />
            <!-- </mat-form-field> -->
          </div>
          <div class="col s12 m6 input-box" style="margin-bottom: 18px;">
            <!-- <mat-form-field class="input_fields" appearance="outline"> -->
            <mat-label>Role:</mat-label>
            <input type="text" matInput formControlName="role" />
            <!-- </mat-form-field> -->
          </div>
        </div>
        <!-- <div class="row">
                    <div class="col s12 " style="text-align: right;padding-right: 24px;">
                      <button mat-raised-button class="save-btn">
                        Edit changes
                      </button>
                
                    </div>
                  </div> -->
      </div>
    </mat-card>
  </form>
  <!-- <mat-card class="system-config-card" *ngIf="isAdmin && isTourActive()">
      <div class="row setting-row">
        <div class="col s8 m6">
          <h2>Billing and plan</h2>
        </div>
      </div>
      <div class="setting-section system-config">
        <div class="row" style="margin-bottom: 0px;">
          <div class="col m12 s12 l12">
            <quota-banner [closeButton]="true" [isProfile]="true"></quota-banner>
          </div>
        </div>
        <div class="row" style="margin-bottom: 0px;">
          <div class="col s12 m4 input-box">
            <div class="billing_card1">
              <div class="billing_card_title">
                {{totalUser}}
              </div>
              <div class="billing_card_sub_title">
                Total users with current plan
              </div>
            </div>
          </div>
          <div class="col s12 m4 input-box">
            <div class="billing_card2">
              <div class="billing_card_title">
                {{useUser}}
              </div>
              <div class="billing_card_sub_title">
                Active users
              </div>
            </div>
          </div>
          <div class="col s12 m4 input-box">
            <div class="billing_card3">
              <div class="billing_card_title">
                {{availableUser}}
              </div>
              <div class="billing_card_sub_title">
                Available users
             </div>
            </div>
          </div>
        </div>
        <div class="row" style="margin-bottom: 14px;">
          
          
          <div class="col s12 input-box">
            <div class="plan_card row">
              <div class="plan_card_title col s12 m12" *ngIf="!isAdmin">
                <p>
                  {{quotaStatus == 'active' ? 'Active until' : 'Plan expired'}}
                </p>
               <div class="chips_section">
                 <span *ngIf="quota_expired_date">
                   <div class="custom-chip">
                     {{quota_expired_date}}
                   </div>
                  
                   
                 </span>
                 <span *ngIf="plan_type != ''">
                   <div class="plan-type-chip">
                     {{plan_type}}
                   </div>
                 </span>
               </div>
              </div>
              <div class="plan_card_title col s12 m7" *ngIf="isAdmin">
                <p>
                  {{quotaStatus == 'active' ? 'Active until' : 'Plan expired'}}
                </p>
               <div class="chips_section">
                 <span *ngIf="quota_expired_date">
                   <div class="custom-chip">
                     {{quota_expired_date}}
                   </div>
                  
                   
                 </span>
                 <span *ngIf="plan_type != ''">
                   <div class="plan-type-chip">
                     {{plan_type}}
                   </div>
                 </span>
               </div>
              </div>
              
                <div class="col s12 m5" *ngIf="isAdmin">
                  <div class="btn_group_trial" style="float: right;">
                    <button class="trialBtnIncreaseLimit" [routerLink]="['/billings']">Manage my plan</button>
                  </div>
                </div>
                <div class="plan_card_sub_title col s12" *ngIf="!isAdmin">
                  {{quotaStatus == 'active' ? 'We will send you a notification upon Subscription expiration' : 'Please renew your plan as the current one has expired.'}}
               </div>
              <div class="plan_card_sub_title col s9" *ngIf="isAdmin">
                {{quotaStatus == 'active' ? 'We will send you a notification upon Subscription expiration' : 'Please renew your plan as the current one has expired.'}}
             </div>
              <div class="col s3 btn_group_trial" *ngIf="isAdmin">
               <a (click)="applyCouponAction()">Apply coupon?</a>
             </div>
             
             <div>
             </div>
            </div>
          </div>
          </div>
      </div>
    </mat-card> -->
  <form [formGroup]="changePasswordForm">
    <mat-card class="system-config-card">
      <div class="row setting-row">
        <div class="col s8 m6">
          <h2>Change password</h2>
        </div>
      </div>
      <div class="setting-section system-config changePassword">
        <div class="row" style="margin-bottom: 0px;">
          <div class="col s12 input-box">
            <mat-form-field class="input_fields" appearance="outline">
              <mat-label>Old password</mat-label>
              <input type="text" matInput formControlName="currentPassword" />
            </mat-form-field>
            <mat-error
              *ngIf="changePasswordForm.get('currentPassword')?.hasError('required') && (changePasswordForm.get('currentPassword')?.dirty || changePasswordForm.get('currentPassword')?.touched)">
              Please enter current password </mat-error>
          </div>
          <div class="col s12 input-box">
            <mat-form-field class="input_fields" appearance="outline">
              <mat-label>New password</mat-label>
              <input type="text" matInput formControlName="newPassword" />
            </mat-form-field>
            <mat-error
              *ngIf="changePasswordForm.get('newPassword')?.hasError('required') && (changePasswordForm.get('newPassword')?.dirty || changePasswordForm.get('newPassword')?.touched)">
              Please enter new password </mat-error>
            <mat-error *ngIf="changePasswordForm.get('newPassword')?.hasError('minlength') && (changePasswordForm.get('newPassword')?.dirty || changePasswordForm.get('newPassword')?.touched) || changePasswordForm.get('newPassword')?.hasError('pattern') &&
					    (changePasswordForm.get('newPassword')?.dirty || changePasswordForm.get('newPassword')?.touched)">
              Please enter valid new password. Hint: To make password stronger, use minimum length
              of 8, upper and lower case letters, numbers, and symbols like ! ? &#64; # $ % ^
              &
            </mat-error>
          </div>
          <div class="col s12 input-box" style="margin-bottom: 18px;">
            <mat-form-field class="input_fields" appearance="outline">
              <mat-label>Confirm new password</mat-label>
              <input type="text" matInput formControlName="confirmPassword" />
            </mat-form-field>
            <mat-error
              *ngIf="changePasswordForm.get('confirmPassword')?.hasError('required') && (changePasswordForm.get('confirmPassword')?.dirty || changePasswordForm.get('confirmPassword')?.touched)">
              Please enter confirm password </mat-error>
            <mat-error
              *ngIf="changePasswordForm.get('confirmPassword').value != changePasswordForm.get('newPassword').value  && (changePasswordForm.get('confirmPassword')?.dirty || changePasswordForm.get('confirmPassword')?.touched) && changePasswordForm.get('confirmPassword').value  != ''">
              Password does not match </mat-error>
          </div>
        </div>
        <div class="row">
          <div class="col s12 " style="text-align: right;">
            <button mat-raised-button class="save-btn"
              [disabled]="!changePasswordForm.valid || changePasswordForm.get('confirmPassword').value != changePasswordForm.get('newPassword').value"
              (click)="changePasswordAction()" style="width: auto;">
              Update password
            </button>

          </div>
        </div>
      </div>
    </mat-card>
  </form>
</div>
<div class="box_section" *ngIf="loading">
  <div>
    <ngx-skeleton-loader style="margin: 20px 0px; width: 100%;" count="4"
      [theme]="{ 'border-radius': '2', height: '200px', width: '100%', padding: '16px 29px'}"></ngx-skeleton-loader>
  </div>
</div>
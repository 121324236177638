<policy-header *ngIf="!isLogin" />
<div class="container-fluid">
    <div class="row m-0">
        <div class="col s12">
            <h1 class="my-1 text-center">Privacy Policy</h1>
        </div>
    </div>
    <hr>
    <div class="row m-0">
        <div class="col s12">
            <h3 class="my-1">Privacy Policy for SuperSee</h3>
        </div>
        <div class="col s12">
            <p class="text my-0-50">
                At Rao Information Technology, accessible from www.raoinformationtechnology.com, one of our main
                priorities is the privacy of our visitors. This Privacy Policy document contains types of information
                that is collected and recorded by Rao Information Technology and how we use it.
            </p>
            <p class="text my-0-50">
                We at Rao Information Technology (hereinafter collectively referred to as “Supersee”, “we”, “us”, “our”)
                respect the confidentiality and integrity of your Personal Data (Def: “personal data” means any data
                about an individual who is identifiable by or in relation to such data). At Supersee we are committed to
                ensure that your Personal Data is processed in a clear and transparent manner, as set out herein.
            </p>
            <p class="text my-0-50">
                Supersee strongly recommends that you read this Privacy Statement thoroughly so that you are familiar
                with
                its privacy practices involving your Personal Data while using its products or services.
            </p>
            <p class="text my-0-50">
                If you have additional questions or require more information about our Privacy Policy, do not hesitate
                to contact us. Our Privacy Policy was created with the help of the <a
                    href="https://www.generateprivacypolicy.com/" class="link">Privacy Policy Generator</a>
            </p>
        </div>
    </div>
    <div class="row mt-1">
        <div class="col s12">
            <h3 class="my-1">Log Files</h3>
        </div>
        <div class="col s12">
            <p class="text m-0">
                Rao Information Technology follows a standard procedure of using log files. These files log visitors
                when they visit websites. All hosting companies do this and a part of hosting services’ analytics. The
                information collected by log files include internet protocol (IP) addresses, browser type, Internet
                Service Provider (ISP), date and time stamp, referring/exit pages, and possibly the number of clicks.
                These are not linked to any information that is personally identifiable. The purpose of the information
                is for analyzing trends, administering the site, tracking users’ movement on the website, and gathering
                demographic information.
            </p>
        </div>
    </div>
    <div class="row mt-1">
        <div class="col s12">
            <h3 class="my-1">Cookies and Web Beacons</h3>
        </div>
        <div class="col s12">
            <p class="text m-0">
                Like any other website, Rao Information Technology uses ‘cookies’. These cookies are used to store
                information including visitors’ preferences, and the pages on the website that the visitor accessed or
                visited. The information is used to optimize the users’ experience by customizing our web page content
                based on visitors’ browser type and/or other information.
            </p>
        </div>
    </div>
    <div class="row mt-1">
        <div class="col s12">
            <h3 class="my-1">Google DoubleClick DART Cookie</h3>
        </div>
        <div class="col s12">
            <p class="text m-0">
                Google is one of a third-party vendor on our site. It also uses cookies, known as DART cookies, to serve
                ads to our site visitors based upon their visit to www.website.com and other sites on the internet.
                However, visitors may choose to decline the use of DART cookies by visiting the Google ad and content
                network Privacy Policy at the following URL – <a href="https://policies.google.com/technologies/ads"
                    class="link">https://policies.google.com/technologies/ads</a>
            </p>
        </div>
    </div>
    <div class="row mt-1">
        <div class="col s12">
            <h3 class="my-1">Our Advertising Partners</h3>
        </div>
        <div class="col s12">
            <p class="text m-0">
                Some of advertisers on our site may use cookies and web beacons. Our advertising partners are listed
                below. Each of our advertising partners has their own Privacy Policy for their policies on user data.
                For easier access, we hyperlinked to their Privacy Policies below. Google – <a
                    href="https://policies.google.com/technologies/ads"
                    class="link">https://policies.google.com/technologies/ads</a>
            </p>
        </div>
    </div>
    <div class="row mt-1">
        <div class="col s12">
            <h3 class="my-1">Privacy Policies</h3>
        </div>
        <div class="col s12">
            <p class="text my-0-50">
                You may consult this list to find the Privacy Policy for each of the advertising partners of Rao
                Information Technology.
            </p>
            <p class="text my-0-50">
                Third-party ad servers or ad networks uses technologies like cookies, JavaScript, or Web Beacons that
                are used in their respective advertisements and links that appear on Rao Information Technology, which
                are sent directly to users’ browser. They automatically receive your IP address when this occurs. These
                technologies are used to measure the effectiveness of their advertising campaigns and/or to personalize
                the advertising content that you see on websites that you visit.
            </p>
            <p class="text my-0-50">
                Note that Rao Information Technology has no access to or control over these cookies that are used by
                third-party advertisers.
            </p>
        </div>
    </div>
    <div class="row mt-1">
        <div class="col s12">
            <h3 class="my-1">Third Party Privacy Policies</h3>
        </div>
        <div class="col s12">
            <p class="text my-0-50">
                Rao Information Technology’s Privacy Policy does not apply to other advertisers or websites. Thus, we
                are advising you to consult the respective Privacy Policies of these third-party ad servers for more
                detailed information. It may include their practices and instructions about how to opt-out of certain
                options.
            </p>
            <p class="text my-0-50">
                You can choose to disable cookies through your individual browser options. To know more detailed
                information about cookie management with specific web browsers, it can be found at the browsers’
                respective websites. What Are Cookies?
            </p>
        </div>
    </div>
    <div class="row mt-1">
        <div class="col s12">
            <h3 class="my-1">Children’s Information</h3>
        </div>
        <div class="col s12">
            <p class="text my-0-50">
                Another part of our priority is adding protection for children while using the internet. We encourage
                parents and guardians to observe, participate in, and/or monitor and guide their online activity.
            </p>
            <p class="text my-0-50">
                Rao Information Technology does not knowingly collect any Personal Identifiable Information from
                children under the age of 13. If you think that your child provided this kind of information on our
                website, we strongly encourage you to contact us immediately and we will do our best efforts to promptly
                remove such information from our records.
            </p>
        </div>
    </div>
    <div class="row mt-1">
        <div class="col s12">
            <h3 class="my-1">Online Privacy Policy Only</h3>
        </div>
        <div class="col s12">
            <p class="text m-0">
                This Privacy Policy applies only to our online activities and is valid for visitors to our website with
                regards to the information that they shared and/or collect in Rao Information Technology. This policy is
                not applicable to any information collected offline or via channels other than this website.
            </p>
        </div>
    </div>
    <div class="row mt-1">
        <div class="col s12">
            <h3 class="my-1">Consent</h3>
        </div>
        <div class="col s12">
            <p class="text m-0">
                By using our website, you hereby consent to our Privacy Policy and agree to its Terms and Conditions.
            </p>
        </div>
    </div>
    <div class="row mt-1">
        <div class="col s12">
            <h3 class="my-1">Privacy policy for our product websites, apps and demos</h3>
        </div>
        <div class="col s12">
            <p class="text my-0-50">
                Information collected by us (Who provides it and how we use it)
            </p>
            <p class="text my-0-50">
                We do require that you provide us with information or create an account in order to view our prices,
                discounts and services.
            </p>
            <p class="text my-0-50">
                You may choose to provide us with information to receive certain services.
            </p>
            <hr>
            <p class="text my-0-50">
                The information you provide us could include:
            </p>
            <p class="text my-0-50"><b class="bold-text">Name, Address, Email Address, Images, videos, and other media
                    files</b></p>
            <p class="text my-0-50"><b class="bold-text">Contacts (if the demo app requires)</b> To find and connect
                your known contacts with you and help them
                connect with you in the application. We delete all the contacts once known contacts are linked.</p>
            <p class="text my-0-50"><b class="bold-text"></b></p>
            <p class="text my-0-50">
                If you provide us with your information, you agree to let us use such personal information in order to
                provide services to you, to run our business (including for the purposes of internal business operations
                and analytics, to provide, change, market or optimize our services and products) and to communicate and
                market to you.
            </p>
            <p class="text my-0-50">
                <a href="https://docs.google.com/document/d/1NtSD_dX79ExGzA7c56KLb2TX80DrT731JiwPbzq5Dwo/edit"
                    class="link">Special declarations and clauses for our product websites, apps and demos. Click to
                    read
                    more.</a>
            </p>
        </div>
    </div>
</div>
<ng-template #searchBox>
  <ngx-skeleton-loader *ngIf="!noDataFound" count="1"
    [theme]="{ 'border-radius': '2', height: '50px' }"></ngx-skeleton-loader>
</ng-template>
<mat-card>
  <div class="row teams-row">
    <div class="col s12 teams-contain">
      <h4>Teams</h4>
    </div>
  </div>
  <div *ngIf="!loading">
    <div class="row teams-row single-team dropdown-hover" *ngFor="let team of _userData" (click)="goToTeamEmiter(team)">
      <div class="col s7 d-flex avtar-container">
        <div class="avtar">
          <ngx-avatars name="{{ team.title.rendered }}" size="38"></ngx-avatars>
        </div>

        <div class="">
          <h6 class="text-wrap" style="text-transform: capitalize" [innerHTML]="team.title.rendered"></h6>
          <p>
            <span style="font-weight: 500">Created At:<span> {{ team.date | date }}</span></span>
          </p>
        </div>
      </div>
      <div class="col s5 badge-container">
        <div class="badge">
          <span>
            {{ getTotalMemberCount(team.meta) }}
            {{ getTotalMemberCount(team.meta) > 1 ? "Members" : "Member" }}
          </span>
        </div>
        <span (click)="openMenu(team)" style="cursor: pointer" *ngIf="isAdmin"><span class="material-symbols-outlined">
            more_horiz </span></span>
        <div class="more-button-dropdowns" *ngIf="team.isMenuOpened && isAdmin">

          <div class="row dropdown-hover" (click)="openTeamEditModal(team.id)">
            <div class="col s12">
              <p>
                <span [ngClass]="'online'" class="material-symbols-outlined">Edit</span>
                Edit

              </p>
            </div>
          </div>

          <div class="row dropdown-hover" (click)="deleteTeam(team)">
            <div class="col s12">
              <p class="delete">
                <span [ngClass]="'online'" class="material-symbols-outlined">delete</span>
                delete

              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="loading">
    <ngx-skeleton-loader count="5" [theme]="{ 'border-radius': '2', height: '75px' }"></ngx-skeleton-loader>
  </div>
  <div *ngIf="errorPhotoShow && !_userData.length && !loading && !skeletonLoader">
    <div class="empty_list z-depth-2">
      <img src="assets/images/empty_list.png" alt="empty list image" />
    </div>
  </div>
</mat-card>
import { Component, Inject } from "@angular/core";
import {
  MAT_SNACK_BAR_DATA,
  MatSnackBarRef,
} from "@angular/material/snack-bar";
import { Router } from "@angular/router";
import { ScreenShotViwerComponent } from "src/app/Admin/screen-shot-viwer/screen-shot-viwer.component";
import { ProjectService } from "src/app/Services/project.service";

@Component({
  selector: "app-display-toast",
  templateUrl: "./display-toast.component.html",
  styleUrls: ["./display-toast.component.css"],
})
export class DisplayToastComponent {
  // imageUrl;
  // imageDate;
  // appName: any;
  toastData: any;
  constructor(
    private _projectService: ProjectService,
    private _router: Router,
    @Inject(MAT_SNACK_BAR_DATA) public data: string
  ) {
    // this._projectService.ToastData.subscribe((value) => {
    //  console.log(JSON.parse(value.data) , "emit value")
    //  let data = JSON.parse(value.data);
    //  this.imageUrl = data.imgURL;
    //  this.appName = value.name;
    //  this.imageDate = data.createdAt;
    // });
  }
  ngOnInit() {
    this.toastData = this.data;
    // console.log(this.data, "dataa");
    // this.imageUrl = this.data["imgURL"];
    // this.appName = this.data["appName"];
    // this.imageDate = this.data["createdAt"];
  }
  openImage(): any {
    // console.log("img", this.toastData.imgURL);
    if (this.toastData.imgURL) {
      return this.toastData.imgURL;
    }
  }
  openEmployee() {
    if (this.toastData.userId) {
      this._router.navigate([`/user-details/${this.toastData.userId}`]);
    }
  }
}

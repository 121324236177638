import { Component, NgZone } from '@angular/core';
import { SharedService } from '../Services/sharedService';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ProjectService } from '../Services/project.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent {

  forgotForm: UntypedFormGroup;
  isError='';
  isDisable = false;
  emailPattern = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/;
  constructor(private _sharedServices:SharedService,private _projectService:ProjectService,private _snackBar: MatSnackBar,private router: Router,private ngZone: NgZone){
    this._sharedServices.sideMenuSubject.next(false);

    this.forgotForm = new UntypedFormGroup({
      email: new UntypedFormControl("", [Validators.required,Validators.pattern(this.emailPattern)]),
    });
  }

  SendEmailAction(){
    this.isDisable = true;
    if(!this.forgotForm.valid){
      this.isDisable = false;
      return
    }else{
    this
      ._projectService
      .sendResetPasswordEmail(this.forgotForm.value.email).subscribe((user: any) => {
        this._snackBar.open(user.message,'Undo',{
          duration: 3000
        })
        localStorage.setItem('current_email',this.forgotForm.value.email)
        this.router.navigate(["/verification-code"], { queryParams: { type: 'forgot' } });
        this.isDisable = false;
      },err=>{
        this.isDisable = false;
        this.isError = err.error.message;
        setInterval(() => {
          this.ngZone.run(() => {
            this.isError = '';
          });
        }, 3000); 
          this._snackBar.open(err.error.message,'Undo',{
            duration: 3000
          })
      })
    }
  }

}

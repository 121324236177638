<div class="container-fluid" *ngIf="!loading">
  <form [formGroup]="userProfileForm">
    <mat-card class="system-config-card">
      <div class="row setting-row">
        <div class="col s8 m6">
          <h2 *ngIf="role != 'company'">{{role | titlecase}} details</h2>
          <h2 *ngIf="role == 'company'">{{role | titlecase}} profile : {{company_name}} </h2>
        </div>
      </div>
      <div class="setting-section system-config">
        <div class="row details">
          <div class="col s12 m6 input-box">
            <mat-label>Name:</mat-label>
            <input type="text" matInput formControlName="name" />
          </div>
          <div class="col s12 m6 input-box">
            <mat-label>Email:</mat-label>
            <input type="text" matInput formControlName="email" />
          </div>
          <div class="col s12 m6 input-box">
            <mat-label>Phone number:</mat-label>
            <input type="text" matInput formControlName="phone" />
          </div>
          <div class="col s12 m6 input-box" style="margin-bottom: 18px;">
            <mat-label>Role:</mat-label>
            <input type="text" matInput formControlName="role" />
          </div>
        </div>
      </div>
    </mat-card>
  </form>

  <form [formGroup]="changePasswordForm">
    <mat-card class="system-config-card">
      <div class="row setting-row">
        <div class="col s8 m6">
          <h2>Change password</h2>
        </div>
      </div>
      <div class="setting-section system-config changePassword">
        <div class="row" style="margin-bottom: 0px;">
          <div class="col s12 input-box">
            <mat-form-field class="input_fields" appearance="outline">
              <mat-label>Current password</mat-label>
              <input [type]="hideCurrent ? 'password' : 'text'" matInput formControlName="currentPassword" />
              <button mat-icon-button matSuffix class="passBtn" (click)="hideCurrent = !hideCurrent">
                <mat-icon [matTooltip]="hideCurrent ? 'Unlock' : 'Lock'">
                  <span class="material-symbols-outlined">
                    {{ hideCurrent ? "visibility_off" : "visibility" }}
                  </span>
                </mat-icon>
              </button>
            </mat-form-field>
            <mat-error
              *ngIf="changePasswordForm.get('currentPassword')?.hasError('required') && (changePasswordForm.get('currentPassword')?.dirty || changePasswordForm.get('currentPassword')?.touched)">
              Please enter current password </mat-error>
          </div>
          <div class="col s12 input-box">
            <mat-form-field class="input_fields" appearance="outline">
              <mat-label>New password</mat-label>
              <input [type]="hideNew ? 'password' : 'text'" matInput formControlName="newPassword" />
              <button mat-icon-button matSuffix class="passBtn" (click)="hideNew = !hideNew">
                <mat-icon [matTooltip]="hideNew ? 'Unlock' : 'Lock'">
                  <span class="material-symbols-outlined">
                    {{ hideNew ? "visibility_off" : "visibility" }}
                  </span>
                </mat-icon>
              </button>
            </mat-form-field>
            <mat-error
              *ngIf="changePasswordForm.get('newPassword')?.hasError('required') && (changePasswordForm.get('newPassword')?.dirty || changePasswordForm.get('newPassword')?.touched)">
              Please enter new password </mat-error>

            <mat-error *ngIf="changePasswordForm.get('newPassword')?.hasError('password')">{{
              changePasswordForm.get('newPassword')?.errors?.password }}</mat-error>
          </div>
          <div class="col s12 input-box" style="margin-bottom: 18px;">
            <mat-form-field class="input_fields" appearance="outline">
              <mat-label>Confirm new password</mat-label>
              <input [type]="hideConfirm ? 'password' : 'text'" matInput formControlName="confirmPassword" />
              <button mat-icon-button matSuffix class="passBtn" (click)="hideConfirm = !hideConfirm">
                <mat-icon [matTooltip]="hideConfirm ? 'Unlock' : 'Lock'">
                  <span class="material-symbols-outlined">
                    {{ hideConfirm ? "visibility_off" : "visibility" }}
                  </span>
                </mat-icon>
              </button>
            </mat-form-field>
            <mat-error
              *ngIf="changePasswordForm.get('confirmPassword')?.hasError('required') && (changePasswordForm.get('confirmPassword')?.dirty || changePasswordForm.get('confirmPassword')?.touched)">
              Please enter confirm password </mat-error>
            <mat-error
              *ngIf="changePasswordForm.get('confirmPassword').value != changePasswordForm.get('newPassword').value  && (changePasswordForm.get('confirmPassword')?.dirty || changePasswordForm.get('confirmPassword')?.touched) && changePasswordForm.get('confirmPassword').value  != ''">
              Password does not match </mat-error>
          </div>
        </div>
        <div class="row">
          <div class="col s12 " style="text-align: right;">
            <button mat-raised-button class="save-btn"
              [disabled]="!changePasswordForm.valid || changePasswordForm.get('confirmPassword').value != changePasswordForm.get('newPassword').value"
              (click)="changePasswordAction()" style="width: auto;">
              Update password
            </button>

          </div>
        </div>
      </div>
    </mat-card>
  </form>
</div>
<div class="box_section" *ngIf="loading">
  <div>
    <ngx-skeleton-loader style="margin: 20px 0px; width: 100%;" count="4"
      [theme]="{ 'border-radius': '2', height: '200px', width: '100%', padding: '16px 29px'}"></ngx-skeleton-loader>
  </div>
</div>
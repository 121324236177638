import { Component } from '@angular/core';
import { CdkAccordionModule } from '@angular/cdk/accordion';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-question-and-answer',
  standalone: true,
  imports: [ CdkAccordionModule, CommonModule ],
  templateUrl: './question-and-answer.component.html',
  styleUrl: './question-and-answer.component.css'
})
export class QuestionAndAnswerComponent {
  items = [
    {
      title: "I’ve created employees, but why is there no data?",
      description: "Ensure that employees or your management team have got the SuperSee Time Tracker App installed on their system. Without the desktop app, no data will be recorded. In case the set up process is pending, visit <a href='https://supersee.io/how-to-install-supersee-productivity-tracking-software-for-employees/'>here</a> for more information."
    },
    {
      title: "How would my employees receive their credentials?",
      description: "After creating an account for an employee,<ol><li>Download and install the SuperSee Time Tracker App from <a href='https://supersee.io/how-to-install-supersee-productivity-tracking-software-for-employees/'>here</a>.</li><li>Login with the credentials received in their email.</li><li>Restart the system after Installation.</li></ol>"
    },
    {
      title: "Supersee Time Tracker Installaton Instructions for Windows, Linux and Mac",
      description: "Visit <a href='https://supersee.io/how-to-install-supersee-productivity-tracking-software-for-employees/'>here</a> for more details to configure SuperSee Time Tracking App on your desired OS"
    },
    {
      title: "Overview of timeline of a particular day",
      description: "<b>Productive Hours</b> - Highlighted with purple background</p><p><b>Idle Hours</b> - Highlighted with gray background</p><p><b>Break Hours</b> - Highlighted with white background<br/><p>By, clicking on the specific employee timeline following insights can be obtained</p><ol type='i'><li>First & Last Activity Time</li><li>Total Productive & Idle Hours</li><li>Application Usage</li></ol><center><img width='80%' src='../../assets/employees-timeline.png'></center"
    },
    {
      title: "What is the Silent version & How can I enable silent mode for my employees?",
      description: "<p>Desktop app & widget won’t be visible whenever a Silent version mode is enabled. It can be enabled/disabled for all employees or any specific employee with the help of personalized settings.</p> <p>All Employees : Navigate to Menu => Click on Settings => Enable/Disable Silent mode => Save Settings</p><p>Specific Employees : Navigate to Menu => Employees => Select Employee => Click on three dots => Click Settings => Enable/Disable Silent mode => Save Settings</p>"
    },
    {
      title: "How can I change a specific employee’s settings?",
      description: "Go to the employee's tab and click on the three dots, click on ‘Settings’, turn on personalized settings and change settings just for that one employee."
    },
    {
      title: "How can I buy/extend my plan?",
      description: "Click <a href='https://app.supersee.io/billings'>here</a>, to buy/extend your plan as per your requirements!"
    },
    {
      title: "Different type of employee roles and its specification",
      description: "<p>Employee:</p><ol><li>Can view their own logs from Supersee Monitoring Desktop Application</li></ol><p>Team Lead:</p><ol><li>Can view logs of their team members</li><li>Can request screenshot/screencast</li></ol><p>Manager:</p><ol><li>Can view logs of their team members</li><li>Can request screenshot/screencast</li><li>Can change employee specific settings</li></ol>"
    },
    {
      title: "How can I create teams?",
      description: "<ol><li>Click on Teams menu item</li><li>Click on Add team and add team name</li><li>Assign manager and team lead</li><li>Add employees to the team</li></ol>"
    },
    {
      title: "How can I integrate Supersee with Discord?",
      description: "<ol><li>Follow the steps mentioned <a href='https://support.discord.com/hc/en-us/articles/228383668-Intro-to-Webhooks'>here</a> to create two Discord Webhooks for Screenshot & Summary updates.</li><li>In SuperSee admin panel, navigate to Settings->Discord Hooks.</li><li>Copy and paste webhook url(s) appropriately and the integration is done.</li><li>You would start receiving updates on respective Discord Channels.</li></ol>"
    },
    {
      title: "How to Request screenshot and Screencast of particular employee",
      description: "<p>There are 2 ways where you can request a screenshot/screencast of an employee</p><ol><li>Navigate to the employee list, click on the screencast/screenshot icon for a specific employee.</li><li>Under employee profile, click on Screenshot Request / Screencast Button.</li></ol>"
    },
    {
      title: "How to see hours worked of all employees of a particular day",
      description: "<ol><li>Click on Timesheets menu item</li><li>By default Day wise toggle would be applicable</li><li>Select date</li><li>Click on export button</li></ol>"
    },
    {
      title: "How to see hours worked of particular Employee with custom date range",
      description: "<ol><li>Click on Timesheets menu item</li><li>Enable toggle switch to filter user wise</li><li>Select user and date range</li><li>Click on export button</li></ol>"
    }
  ];
  expandedIndex = 0;
}

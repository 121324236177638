<!-- <app-header></app-header> -->

<div class="container-fluid" style="margin: 10px 20px;">
    <!-- <div class="row">
        <div class="col s10">
            <h5 style="margin: 5px 0px;">Edit Employee</h5>
        </div>
        <div class="col s2" style="text-align: right;">
            <button style="margin: 0px 10px;" class="waves-effect waves-light btn deep-purple">
                <span class="material-symbols-outlined" style="position: relative; top: 6px;">save</span> Save</button>
        </div>
    </div> -->

    <div class="row">
        <div class="col s12">
            <h6 style="opacity: 0.7;">Edit Information</h6>
            <div class="row configBox">
                <div class="col s12">
                    <h6>Personal Information</h6>
                </div>
                <div class="col s12 input-box">
                    <div class="row">
                        <div class="col s12 m1" style="text-align: left;">
                            <p>First Name :</p>
                        </div>
                        <div class="col s12 m11">
                            <mat-form-field class="input_fields">
                                <mat-label>First Name</mat-label>
                                <input type="text" matInput>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
                <div class="col s12 input-box">
                    <div class="row">
                        <div class="col s12 m1" style="text-align: left;">
                            <p>Last Name :</p>
                        </div>
                        <div class="col s12 m11">
                            <mat-form-field class="input_fields">
                                <mat-label>Last Name</mat-label>
                                <input type="text" matInput>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
                <div class="col s12 input-box">
                    <div class="row">
                        <div class="col s12 m1" style="text-align: left;">
                            <p>Email :</p>
                        </div>
                        <div class="col s12 m11">
                            <mat-form-field class="input_fields">
                                <mat-label>Email</mat-label>
                                <input type="email" matInput>
                                <mat-icon matSuffix>
                                    <span class="material-symbols-outlined">
                                        mail
                                    </span>
                                </mat-icon>
                                <mat-hint>email : example&#64;domain.com</mat-hint>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
                <div class="col s12 input-box">
                    <div class="row">
                        <div class="col s12 m1" style="text-align: left;">
                            <p>Password :</p>
                        </div>
                        <div class="col s12 m11">
                            <mat-form-field class="input_fields">
                                <mat-label>Password</mat-label>
                                <input [type]="hide ? 'password' : 'text'" matInput>
                                <mat-hint>Enter password</mat-hint>
                                <button mat-icon-button matSuffix class="passBtn" (click)="hide = !hide"
                                    style="cursor: pointer;">
                                    <mat-icon [matTooltip]="hide ? 'Unlock' : 'Lock'">
                                        <span class="material-symbols-outlined">
                                            {{hide ? 'lock' : 'lock_open'}}
                                        </span>
                                    </mat-icon>
                                </button>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
                <div class="col s12 input-box">
                    <div class="row">
                        <div class="col s12 m1" style="text-align: left;">
                            <p>Role :</p>
                        </div>
                        <div class="col s12 m11">
                            <mat-form-field class="input_fields">
                                <mat-select>
                                    <mat-option *ngFor="let role of roles" [value]="role">{{role}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col s12">
            <h6 style="opacity: 0.7;">Edit Work Profile</h6>
            <div class="row configBox">
                <div class="col s6 m6">
                    <h5>Team's</h5>
                    <app-team-list></app-team-list>
                </div>
                <div class="col s6 m6">
                    <app-team-member-list></app-team-member-list>
                </div>
            </div>
        </div>
    </div>
</div>
import { ElementRef, SimpleChanges } from "@angular/core";
import { Component, Input, Output, EventEmitter } from "@angular/core";
import { AngularFireDatabase } from "@angular/fire/compat/database";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Observable } from "rxjs";
import { Sort } from "@angular/material/sort";
import { ProjectService } from "src/app/Services/project.service";
import { ConfirmationDialogComponent } from "src/app/confirmation-dialog/confirmation-dialog.component";
import { SharedService } from "src/app/Services/sharedService";
import { ScreencastActionModelComponent } from "src/app/screencast/screencast-action-model/screencast-action-model.component";
import { Router } from "@angular/router";
import { CustomTimeAgoPipe } from '../../time-ago.pipe';
import { NewPasswordComponent } from "src/app/new-password/new-password.component";
import { LoginFiles } from "./../../loginFiles"
import { MessagingServiceService } from "src/app/Services/messaging-service.service";

@Component({
  selector: "app-users-table",
  templateUrl: "./users-table.component.html",
  styleUrls: ["./users-table.component.css"],
})
export class UsersTableComponent {
  @Input() developerList: any;
  @Input() devList: any;
  @Input() modalRef: ElementRef;
  @Input() widthStyle: any;
  @Input() isMaxContent: string;
  @Input() loadWaiter: Boolean;
  @Output() modalOpen = new EventEmitter<any>();
  @Output() deleteEmployeee = new EventEmitter<any>();
  @Output() sortingData = new EventEmitter<any>();
  @Input() showAction: false;
  @Input() isTeam: boolean;
  @Input() statusLoad: Boolean;
  @Input() expired: Boolean;
  isAdmin = true;
  isManager = true;
  generalDownloads = [
    // { label: 'Windows', link: 'https://github.com/RaoInfotech/supersee-releases/releases/download/v5.1.5-alpha.2/Supersee_Setup_4.1.5-alpha.2.exe' },
    { label: 'Windows', link: './../../../assets/Windows/General/Supersee_Installer.bat' },
    { label: 'Linux', link: 'https://github.com/RaoInfotech/supersee-release-linux/releases/download/v4.1.5-alpha.1/Supersee_4.1.5-alpha.1_amd64.deb' },
    { label: 'Mac', link: 'https://github.com/RaoInfotech/supersee-release-mac/releases/download/v4.1.5-alpha.1/Supersee-4.1.5-alpha.1.dmg' }
  ];

  silentDownloads = [
    { label: 'Windows' },
    { label: 'Linux' },
    { label: 'Mac' }
  ];

  errorPhotoShow: Boolean = false;
  skeletonLoader: Boolean = true;
  private dbPath = localStorage.getItem('dbPath');
  userImageClass = 'avatar-img';

  constructor(
    private _projectService: ProjectService,
    private db: AngularFireDatabase,
    public dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private _sharedService: SharedService,
    private _messagingServiceService: MessagingServiceService,
    private router: Router,
    private customtimeAgoPipe: CustomTimeAgoPipe
  ) {
    this.getUSer();
  }

  ngOnInit() {
    setInterval(() => {
      this.errorPhotoShow = true;
      this.skeletonLoader = false;
    }, 10000);
  }

  ngOnChanges(changes: SimpleChanges) {
  }

  openModalFire(id: any) {
    this.developerList.find((dev) => {
      if (dev.id == id) {
        dev.isMenuOpened = false;
      }
    });
    this.modalOpen.emit({ eleRef: this.modalRef, isEdit: true, userId: id });
  }
  deleteUser(id, dev) {
    let data = {
      id: id,
      name: dev.name,
      type: "employee"
    };
    this.developerList.find((dev) => {
      if (dev.id == id) {
        dev.isMenuOpened = false;
      }
    });
    this.openDialog(ConfirmationDialogComponent, data).subscribe((response) => {
      if (response != undefined) {
        this._projectService
          .DeleteEmployee(response.delete)
          .subscribe((data: any) => {
            this.deleteEmployeee.emit({ data: data });
          });
      }
    });
  }
  openDialog(someComponent, data = {}): Observable<any> {
    const dialogRef = this.dialog.open(someComponent, { data });
    return dialogRef.afterClosed();
  }
  openScreencastActionModel(user) {
    let obj = {
      userDetails: user,
      userId: user.id
    };
    let data = obj;
    const dialogRef = this.openDialog(
      ScreencastActionModelComponent,
      data
    ).subscribe((response) => { });
  }

  screenshotRequest(id, Status, name) {
    if (!navigator.onLine) {
      this._snackBar.open("Please check your internet connection?", "Undo", {
        duration: 3000,
      });
    } else {
      console.log("CLIICK NOT ONLINE")
      let data = {
        type: "Requesting Screenshot",
        userName: name,
      };
      this._sharedService.showToast(data);
      setTimeout(() => {
        this.db.database.ref(`${this.dbPath}`).child(`${id}`).get().then((res) => {
          if (res.val().ssRequest == null) {
            this.db.database
              .ref(`${this.dbPath}`)
              .child(`${id}`)
              .update({ ssRequest: false });
            let data = {
              type: "Cant get screenshot",
              userName: name,
            };
            this._sharedService.showToast(data);
          }
        });
      }, 20000)
      if (Status == "online") {
        this.db.database
          .ref(`${this.dbPath}`)
          .child(`${id}`)
          .update({ ssRequest: null });
      }
    }
  }

  sortData(sort: Sort) {
    this.sortingData.emit(sort);
  }

  openMenu(dev) {
    this.developerList.find((singleDev) => {
      if (singleDev.id != dev.id) {
        singleDev.isMenuOpened = false;
      }
    });
    if (dev.isMenuOpened) {
      dev.isMenuOpened = false;
    } else {
      dev.isMenuOpened = true;
    }
    return dev;
  }

  isPersonalizeSetting(setting) {
    if (Array.isArray(setting)) {
      if (!setting.length) {
        return true;
      }
    } else {
      if (setting.ss_personalizeSettings) {
        if (setting.ss_personalizeSettings == 'true') {
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    }
  }

  getUSer() {
    if (this.isTourActive()) {
      let roleArr = [];
      this._projectService.getUser().subscribe((data: any) => {
        roleArr = data.role;

        let adminRole = roleArr.filter((ele) => {
          return (ele.toLowerCase().includes("admin") || ele.toLowerCase().includes("company"));
        })
        if (adminRole.length == 0) {
          this.isAdmin = false;
        } else {
          this.isAdmin = true;
        }

        let managerRole = roleArr.filter((ele) => {
          return (ele.toLowerCase().includes("manager"));
        })
        if (managerRole.length == 0) {
          this.isManager = false;
        } else {
          this.isManager = true;
        }
      })
    } else {
      let roleArr = [];
      this._projectService.getUser().subscribe((data: any) => {
        roleArr = data.role;
        let adminRole = roleArr.filter((ele) => {
          return (ele.toLowerCase().includes("admin") || ele.toLowerCase().includes("company"));
        })
        if (adminRole.length == 0) {
          this.isAdmin = false;
        } else {
          this.isAdmin = true;
        }

        let managerRole = roleArr.filter((ele) => {
          return (ele.toLowerCase().includes("manager"));
        })
        if (managerRole.length == 0) {
          this.isManager = false;
        } else {
          this.isManager = true;
        }
      })
    }
  }

  isTourActive() {
    if (localStorage.getItem('isTour')) {
      if (localStorage.getItem('isTour') == 'false') {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  checkIsActive() {
    if (this.router.url.includes('/employees') == true) {
      return true;
    } else {
      return false;
    }
  }

  resetPassword(user: any) {
    const dialogData = {
      id: user.id,
      email: user.email
    }

    // this._messagingServieService.updateEmployeeCurrentStatus(dialogData)
    this.dialog.open(NewPasswordComponent, {
      width: '660px',
      data: dialogData
    })
  }


  autoResetPassword(label, user) {
    let password = this.generatePassword()
    user['password'] = password
    this
      ._projectService
      .resetUserPassword(user).subscribe((user: any) => {
        this.downloadFile(label, user, password)
      }, err => {

      })
  }

  downloadFile(label, dev, password) {
    console.log(dev)
    if (label == 'Windows') {
      try {
        let batContent = LoginFiles.windows.replace("%EMAIL%", dev.email).replace("%PASSWORD%", password)
        const blob = new Blob([batContent], { type: "text/plain" });
        const a = document.createElement("a");
        a.href = URL.createObjectURL(blob);
        console.log(dev)
        a.download = `${dev.name}.bat`; // Set the filename
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      } catch (error) {
        console.log(error)
      }
    }
    else if (label === 'Mac') {
      let commandContent = LoginFiles.mac.replace("$EMAIL", dev.email).replace("$PASSWORD", password)
      const blob = new Blob([commandContent], { type: "text/plain" });
      const a = document.createElement("a");
      a.href = URL.createObjectURL(blob);
      console.log(dev)
      a.download = `${dev.name}.command`; // Set the filename
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
    else if (label === 'Linux') {
      let shContent = LoginFiles.linux.replace("$EMAIL", dev.email).replace("$PASSWORD", password)
      const blob = new Blob([shContent], { type: "text/plain" });
      const a = document.createElement("a");
      a.href = URL.createObjectURL(blob);
      console.log(dev)
      a.download = `${dev.name}.sh`; // Set the filename
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
    else {
      console.log("elese")
    }

  }

  generatePassword() {
    const minLength = 8;
    const maxLength = 12;
    const specialChars = ['@'];
    const uppercaseChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const lowercaseChars = 'abcdefghijklmnopqrstuvwxyz';
    const numbers = '0123456789';
    const allChars = uppercaseChars + lowercaseChars + numbers;

    const length = Math.floor(Math.random() * (maxLength - minLength + 1)) + minLength;

    let password = '';
    password += uppercaseChars[Math.floor(Math.random() * uppercaseChars.length)]; // One uppercase
    password += lowercaseChars[Math.floor(Math.random() * lowercaseChars.length)]; // One lowercase
    password += numbers[Math.floor(Math.random() * numbers.length)]; // One number

    let specialChar = specialChars[Math.floor(Math.random() * specialChars.length)]; // One special char

    let remainingLength = length - 4; // Already used 3 characters, plus the special character

    for (let i = 0; i < remainingLength; i++) {
      password += allChars[Math.floor(Math.random() * allChars.length)];
    }

    let middleIndex = Math.floor(password.length / 2);
    password = password.slice(0, middleIndex) + specialChar + password.slice(middleIndex);

    return password;
  }

}

import { Component } from '@angular/core';
import { PolicyHeaderComponent } from '../policy-header/policy-header.component';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-refund-policy',
  standalone: true,
  imports: [PolicyHeaderComponent, CommonModule],
  templateUrl: './refund-policy.component.html',
  styleUrl: '../policy-page.css'
})
export class RefundPolicyComponent {
  isLogin = false;
  ngOnInit() {
    this.isLogin = localStorage.getItem('token') ? true : false;
  }
}

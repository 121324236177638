<div class="container-fluid">
	<div class="row login-page-container">
		<div class="col s12 m12 l6 img-container">
		</div>
		<div class="col s12 m12 l6 login-container">
			<div class="welcome-contain">
				<h1>Forgot Password</h1>
				<p>No worries, We are here for you, enter your email account and we will share your link</p>
			</div>
			<div class="">
				<form [formGroup]='forgotForm'>
					<div class="row" style="margin-bottom: 0;">
						<!-- <div class="col s12" style="margin: 0px 10px;">
							<h5>Sign In</h5>
						</div> -->
						<div class="col s12">
							<div class="row" style="margin-bottom: 0;">
								<div class="col s12 form-field">
									<!-- <span class="label_input"><i class="material-icons prefix">email</i> Email /
										Username</span> -->
									<mat-form-field class="mat-form" appearance="outline">
										<mat-label>Enter your work email</mat-label>
										<input matInput formControlName="email" placeholder="Enter your work email">
										<!-- <mat-hint>Please Enter Email</mat-hint> -->
									</mat-form-field>

									<mat-error *ngIf="
									forgotForm.get('email')?.hasError('required')&&
                                  (forgotForm.get('email')?.dirty ||
								  forgotForm.get('email')?.touched)">
										Please enter your work email </mat-error>
									<mat-error *ngIf="
								  forgotForm.get('email')?.hasError('pattern') &&
								 (forgotForm.get('email')?.dirty ||
								 forgotForm.get('email')?.touched)">
										Please enter a valid email address</mat-error>
									<mat-error *ngIf="isError">{{isError}}</mat-error>

								</div>

								<div class="col s12">
									<button mat-raised-button class="btn  signInBtn" extended
										[disabled]="!forgotForm.valid || isDisable" (click)="SendEmailAction()">Send me
										the
										link</button>
								</div>

								<div class="col s12 divider-contain">
									<div class="divider"><mat-divider></mat-divider></div>
									<div class="or">
										<p>Or</p>
									</div>
									<div class="divider"><mat-divider></mat-divider></div>
								</div>
								<div class="col s12" [routerLink]="'/signup'">
									<p class="sign-up">Don't have an account?<span> Sign Up</span></p>
								</div>
							</div>
						</div>
					</div>
				</form>
			</div>
		</div>
	</div>
</div>
import { Component, OnInit } from "@angular/core";
import {
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
} from "@angular/forms";
import { Router } from "@angular/router";
import { LoginService } from "../../Services/login.service";
// import { version } from '../../../../package.json';
import packageJson from "../../../../package.json";
import { Socket } from "ngx-socket-io";
import { map } from "rxjs/operators";
import {
  AngularFireDatabase,
  AngularFireList,
} from "@angular/fire/compat/database";
import { ProjectService } from "src/app/Services/project.service";
import { MessagingServiceService } from "src/app/Services/messaging-service.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import swal from "sweetalert2";
import { SharedService } from "src/app/Services/sharedService";
import { Observable, Subscription } from "rxjs";
import { data } from "jquery";
import { WelcomeComponent } from "src/app/welcome/welcome.component";
import { MatDialog } from "@angular/material/dialog";
import { IConfig } from "ngx-countries-dropdown";
import { passwordValidator } from './../../password-validators';
import { superseeConfig } from './../../superseeConfig'
class User {
  id: string;
  email: string;
  ssRequest: boolean;
  scRequestStart: boolean;
  scRequestStop: boolean;
}
@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"],
})
export class LoginComponent implements OnInit {
  type = 'login'
  public version: string = packageJson.version;
  loginForm: UntypedFormGroup;
  signupForm: UntypedFormGroup;
  errorMessage: any;
  loading = false;
  private dbPath;
  usersRef: AngularFireList<User> = null;
  email: string;
  message: any;
  hide = true;
  emailPattern = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/;
  userDataSubscription: Subscription;
  isloading = false;
  visible = true;
  teamSizeOptions = superseeConfig.teamSizeOptions;
  isDisable = false;
  countries = [];
  selectedCode = '+91';
  isSelected = false;
  featureImages = [
    { src: '../../../assets/images/MostUsedApplication.webp', className: 'most-used-application', classNameTop: 'flex_item_third_1', },
    { src: '../../../assets/images/workingHours.webp', className: 'working-hours', classNameTop: 'flex_item_third_2', },
    { src: '../../../assets/images/captureScreenShots.webp', className: 'capture-screenShots', classNameTop: 'flex_item_third_3', }
  ];

  constructor(
    private loginService: LoginService,
    private router: Router,
    private db: AngularFireDatabase,
    private _projectService: ProjectService,
    private messagingService: MessagingServiceService,
    private _snackBar: MatSnackBar,
    private _sharedServices: SharedService,
    public dialog: MatDialog,
  ) //private socket: Socket
  {

    console.log(this.router.url); // Logs the current route URL

    if (this.router.url == '/signup') {
      this.type = 'signup'
    }
    else if (this.router.url == '/login') {
      this.type = 'login'
    }

    messagingService.resetData();
    this._sharedServices.logInStateSubject.subscribe((result) => {
      if (result) {
        this.router.navigate(["/"]);
      }
    })
    this._sharedServices.sideMenuSubject.next(false);

    this.signupForm = new UntypedFormGroup({
      company: new UntypedFormControl('', [Validators.required]),
      firstName: new UntypedFormControl('', [Validators.required]),
      lastName: new UntypedFormControl("", [Validators.required]),
      email: new UntypedFormControl("", [Validators.required, Validators.pattern(this.emailPattern)]),
      contact: new UntypedFormControl("", [Validators.required, Validators.pattern(/^\+?[1-9]\d{7,14}$/)]),
      password: new UntypedFormControl("", [Validators.required, Validators.minLength(8), Validators.maxLength(20), passwordValidator()]),
      confirmPassword: new UntypedFormControl("", [Validators.required]),
      teamSize: new UntypedFormControl("", [Validators.required])
    }, { validators: this.passwordMatchValidator });

    this.loginForm = new UntypedFormGroup({
      username: new UntypedFormControl("", [Validators.required]),
      password: new UntypedFormControl("", [Validators.required]),
    });


  }

  ngOnDestroy() {
    if (this.userDataSubscription) {
      this.userDataSubscription.unsubscribe();
    }
  }

  passwordMatchValidator(form: UntypedFormGroup) {
    return form.get('password').value === form.get('confirmPassword').value ? null : { 'mismatch': true };
  }

  create(user: User): any {
    return this.db.database.ref(this.dbPath).child(user.id).set(user);
    // return this.usersRef.push(user);
  }

  getAll(): AngularFireList<User> {
    return this.usersRef;
  }

  userLogin() {
    if (!this.loginForm.valid) {
      return;
    }
    this.isloading = true;
    this.loginService.doLogin(this.loginForm.value).subscribe(
      (check: any) => {
        this.loading = true;
        this.email = check.user_email;
        this.isloading = false;
        // console.log("====>", this.socket.);
        if (check.verified == '1' || check.verified == 1 || check.verified == true || check.verified == 'true' || check.verified != '') {
          swal.fire({
            position: "center",
            icon: "success",
            title: "Successfull login",
            timer: 1500,
            showConfirmButton: false,
          });
          localStorage.setItem("token", check["token"]);
          this._sharedServices.logInStateSubject.next(true);
          this.router.navigate([""]);
          localStorage.setItem("isLogedIn", "true");
          localStorage.setItem("logedInUser", JSON.stringify(check));
          this.errorMessage = null;
          this.loading = false;
          this._projectService.updateEmployeeListData({ data: true })
          this.messagingService.resetData();
          const db_path = `/users/${check.company_id}`;
          this.dbPath = db_path;

          localStorage.setItem('dbPath', db_path)
          this.messagingService.dbPath = db_path;
          this._projectService.updateFirebaseData({ data: db_path })
          this._projectService.getUser().subscribe((check: any) => {
            localStorage.setItem('user', JSON.stringify(check))
            if (check.meta.ss_tooltip_visited == '' || !check.meta.ss_tooltip_visited || check.meta.ss_tooltip_visited == 'false' || check.meta.ss_tooltip_visited == false) {
              const dialogRef = this.openDialog(
                WelcomeComponent
              ).subscribe((response) => { });
              localStorage.setItem('tourStart', 'true')
            } else {
              localStorage.setItem('tourStart', 'false')
            }
            this.messagingService.requestPermission(check.id);
            this.messagingService.receiveMessage();
            this.message = this.messagingService.currentMessage;
            this.messagingService.showNotification();
          });
        } else {
          this.resendCode(check);
        }
      },
      (error) => {
        this.isloading = false;
        this.loading = false;
        swal.fire({
          position: "center",
          icon: "error",
          title: "Oops...",
          html: `${error.error.message}`,
        });
        this.errorMessage = error.error.message;
      }
    );
  }

  ngOnInit() {
    this.loading = true;
    if (localStorage.getItem("logedInUser"))
      this.router.navigate(["/projects"]);
    this.loading = false;
  }
  goto(type) {

    if (type == 'signup') {
      this.router.navigate(["/signup"]);
    }
    else if (type == 'login') {
      this.router.navigate(["/login"]);
    }

    this.type = type
  }

  resendCode(check) {
    this
      ._projectService
      .sendVarificationCode(this.email).subscribe((user: any) => {
        if (user == true || user == 'true') {
          localStorage.setItem('current_email', this.email)

          this.errorMessage = null;
          this.loading = false;
          this.router.navigate(["/verification-code"], { queryParams: { type: 'login', data: JSON.stringify(check) } });

          this._snackBar.open('The recovery code sent to your email', 'Undo', {
            duration: 3000
          })
        }
      }, err => {
        this._snackBar.open(err.error.message, 'Undo', {
          duration: 3000
        })
      })

  }
  openDialog(someComponent, data = {}): Observable<any> {
    const dialogRef = this.dialog.open(someComponent, {
      data,
      width: "100%",
      height: "100%",
    });
    return dialogRef.afterClosed();
  }

  loginOnEnter(event: KeyboardEvent) {
    if (!this.isloading) {
      event.preventDefault();
      this.userLogin();
    }
  }

  togglePasswordVisibility(event: MouseEvent) {
    if (!(event instanceof KeyboardEvent && event.key === 'Enter')) {
      this.hide = !this.hide;
    }
  }

  openSupersee() {
    window.open('Https://supersee.io', '_blank')
  }

  selectedCountryConfig: IConfig = {
    hideCode: true,
    hideName: true,
    hideDialCode: true
  };
  countryListConfig: IConfig = {
    hideCode: true,
    hideName: true
  };

  onCountryChange(country: any) {
    this.isSelected = false;
    this.selectedCode = country.dialling_code ? country.dialling_code : '';
  }

  signUpAction() {
    this.isDisable = true;
    if (this.selectedCode == '') {
      this.isSelected = true;
      return;
    }
    if (!this.signupForm.valid || this.signupForm.get('password').value !=
      this.signupForm.get('confirmPassword').value) {
      this.isDisable = false;
      return
    } else {
      this
        ._projectService
        .doSignUp(this.signupForm.value, this.selectedCode ? this.selectedCode : '+91').subscribe((user: any) => {
          localStorage.setItem('current_user', JSON.stringify(user))
          localStorage.setItem('current_email', user.email)
          this.router.navigate(["/verification-code"], { queryParams: { type: 'signup' } });
          this.isDisable = false;
        }, err => {
          this.isDisable = false;
          this._snackBar.open(err.error.message, 'Undo', {
            duration: 3000
          })
        })
    }
  }
}

<policy-header *ngIf="!isLogin"/>
<div class="container-fluid">
    <div class="row m-0">
        <div class="col s12">
            <h1 class="my-1 text-center">Shipping & Exchange Policy</h1>
        </div>
    </div>
    <hr>
    <div class="row m-0">
        <div class="col s12">
            <h1 class="my-1">Shipping Policy:</h1>
        </div>
    </div>
    <div class="row m-0">
        <div class="col s12">
            <h3 class="my-1">Delivery Method:</h3>
        </div>
        <div class="col s12">
            <p class="text m-0">
                Rao Information Technology delivers software products and licenses electronically. Customers will
                receive license keys and download links via email after the successful completion of the purchase.
            </p>
        </div>
    </div>
    <div class="row mt-1">
        <div class="col s12">
            <h3 class="my-1">Delivery Time:</h3>
        </div>
        <div class="col s12">
            <p class="text m-0">
                Upon successful payment, customers can expect to receive their digital products and license information
                within 1 hours. Delivery times may vary based on factors such as payment verification and system
                processing.
            </p>
        </div>
    </div>
    <div class="row mt-1">
        <div class="col s12">
            <h3 class="my-1">Delivery Confirmation:</h3>
        </div>
        <div class="col s12">
            <p class="text m-0">
                An email confirmation will be sent to customers containing relevant download links, license keys, and
                instructions. Customers are responsible for providing a valid email address and ensuring the accuracy of
                the information provided.
            </p>
        </div>
    </div>
    <div class="row mt-1">
        <div class="col s12">
            <h3 class="my-1">Lost or Delayed Deliveries:</h3>
        </div>
        <div class="col s12">
            <p class="text m-0">
                If there is a rare occurrence of a lost or delayed delivery, customers are urged to contact our support
                team at <a href="mailto:hello@raoinformationtechnology.com"
                    class="link">{{'hello@raoinformationtechnology.com'}}</a> for prompt assistance. We will investigate
                and resolve the
                issue as quickly as possible.
            </p>
        </div>
    </div>
    <div class="row m-0">
        <div class="col s12">
            <h1 class="my-1">Exchange/Refund Policy:</h1>
        </div>
    </div>
    <div class="row mt-1">
        <div class="col s12">
            <h3 class="my-1">Refund Eligibility:</h3>
        </div>
        <div class="col s12">
            <p class="text m-0">
                Refunds are applicable within 24 hours of the purchase date. To be eligible, the customer must provide a
                valid reason for the refund request.
            </p>
        </div>
    </div>
    <div class="row mt-1">
        <div class="col s12">
            <h3 class="my-1">Non-refundable Products:</h3>
        </div>
        <div class="col s12">
            <p class="text m-0">
                Certain products may be labeled as non-refundable. This includes products with explicit terms of use or
                those clearly mentioned as non-refundable during the purchase process.
            </p>
        </div>
    </div>
    <div class="row mt-1">
        <div class="col s12">
            <h3 class="my-1">Refund Process:</h3>
        </div>
        <div class="col s12">
            <p class="text m-0">
                To initiate a refund, customers must contact our support team at <a
                    href="mailto:hello@raoinformationtechnology.com"
                    class="link">{{'hello@raoinformationtechnology.com'}}</a> with
                their order details and reason for the refund. The refund will be processed within 7-10 business days.
            </p>
        </div>
    </div>
    <div class="row mt-1">
        <div class="col s12">
            <h3 class="my-1">Exchange Policy:</h3>
        </div>
        <div class="col s12">
            <p class="text m-0">
                Rao Information Technology does not offer direct product exchanges. If a customer wishes to exchange a
                product, they must first request a refund and then proceed to purchase the desired product separately.
            </p>
        </div>
    </div>
    <div class="row mt-1">
        <div class="col s12">
            <h3 class="my-1">Defective or Incorrect Products:</h3>
        </div>
        <div class="col s12">
            <p class="text m-0">
                In the case of a defective or incorrect digital product, customers should contact our support team
                immediately. We will investigate the issue and provide a replacement or refund, depending on the
                circumstances. </p>
        </div>
    </div>
    <div class="row mt-1">
        <div class="col s12">
            <h3 class="my-1">Cancellation Policy:</h3>
        </div>
        <div class="col s12">
            <p class="text m-0">
                Customers can cancel their order within 24 hours of purchase. Cancellation requests should be sent to
                <a href="mailto:hello@raoinformationtechnology.com"
                    class="link">{{'hello@raoinformationtechnology.com'}}</a>
            </p>
        </div>
    </div>
    <div class="row mt-1">
        <div class="col s12">
            <h3 class="my-1">Additional Notes:</h3>
        </div>
        <div class="col s12">
            <p class="text my-0-50">
                Rao Information Technology reserves the right to update the shipping and exchange policy without prior
                notice. Customers are encouraged to review the policy periodically.
                All customer inquiries and support requests should be directed to
                <a href="mailto:hello@raoinformationtechnology.com"
                    class="link">{{'hello@raoinformationtechnology.com'}}</a>
            </p>
        </div>
    </div>
</div>
<div [ngClass]="!isEmployee ? 'container-fluid': ''">
	<div class="row" [ngClass]="isEmployee ? 'user-reset-container' : 'login-page-container'">

		<div class="col s12 m12 l12 login-container" [style.padding]="isEmployee ? '24px' : '70px'">
			<div class="welcome-contain">
				<h1 [ngClass]="isEmployee ? 'h1-reset': ''">{{ isEmployee ? 'Reset password' : 'Create a new password'}}
				</h1>
				<p *ngIf="!isEmployee">Please create a new password for your account to enhance security and protect
					your information.</p>
			</div>
			<div class="">
				<form [formGroup]='resetPasswordForm'>
					<div class="row" style="margin-bottom: 0;">
						<!-- <div class="col s12" style="margin: 0px 10px;">
							<h5>Sign In</h5>
						</div> -->
						<div class="col s12">
							<div class="row" style="margin-bottom: 0;">
								<div class="col s12" *ngIf="isEmployee">
									<mat-form-field class="mat-form" appearance="outline">
										<mat-label>Email</mat-label>
										<input matInput formControlName="email">
									</mat-form-field>
								</div>
								<div class="col s12">

									<mat-form-field class="mat-form" appearance="outline">
										<mat-label>Enter password</mat-label>
										<input matInput [type]="hide ? 'password' : 'text'" formControlName="password"
											placeholder="Enter password">
										<button mat-icon-button matSuffix class="passBtn" (click)="hide = !hide">
											<mat-icon [matTooltip]="hide ? 'Unlock' : 'Lock'">
												<span class="material-symbols-outlined">
													{{ hide ? "visibility_off" : "visibility" }}
												</span>
											</mat-icon>
										</button>
										<!-- <mat-hint>Please Enter Email</mat-hint> -->
									</mat-form-field>
									<mat-error
										*ngIf="resetPasswordForm.get('password')?.hasError('required') && (resetPasswordForm.get('password')?.dirty || resetPasswordForm.get('password')?.touched)">
										Please enter password.
									</mat-error>
									<mat-error *ngIf="resetPasswordForm.get('password')?.hasError('password')">{{
										resetPasswordForm.get('password')?.errors?.password }}</mat-error>
								</div>
								<div class="col s12">

									<mat-form-field class="mat-form" appearance="outline">
										<mat-label>Confirm password</mat-label>
										<input matInput [type]="hideConfirm ? 'password' : 'text'"
											formControlName="confirmPassword" placeholder="Confirm password">
										<button mat-icon-button matSuffix class="passBtn"
											(click)="hideConfirm = !hideConfirm">
											<mat-icon [matTooltip]="hideConfirm ? 'Unlock' : 'Lock'">
												<span class="material-symbols-outlined">
													{{ hideConfirm ? "visibility_off" : "visibility" }}
												</span>
											</mat-icon>
										</button>
									</mat-form-field>
									<mat-error
										*ngIf="
					resetPasswordForm.get('confirmPassword')?.hasError('required') && (resetPasswordForm.get('confirmPassword')?.dirty || resetPasswordForm.get('confirmPassword')?.touched)">
										Please enter confirm password </mat-error>
									<mat-error
										*ngIf="resetPasswordForm.get('confirmPassword')?.value?.length > 0 && resetPasswordForm.get('password')?.value != resetPasswordForm.get('confirmPassword')?.value  && (resetPasswordForm.get('confirmPassword')?.dirty || resetPasswordForm.get('confirmPassword')?.touched) && resetPasswordForm.errors?.mismatch">
										Password does not match </mat-error>
								</div>

								<div *ngIf="!isEmployee" class="col s12">
									<button mat-raised-button class="btn  signInBtn"
										[disabled]="!resetPasswordForm.valid || isDisable" (click)="resetPassAction()"
										extended>Create New
										Password</button>
								</div>

							</div>

							<div *ngIf="isEmployee" class="modal-footer">
								<div class="row footer-container">
									<div class="col s12 footer-contain">
										<button *ngIf="isEmployee" mat-raised-button type="button"
											(click)="closeModal()" class="btn modal-close  cancel-btn">
											Cancel
										</button>

										<button mat-raised-button class="btn save-password"
											[disabled]="!resetPasswordForm.valid || isDisable"
											(click)="resetUserPassword()" extended>Save</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</form>
			</div>
		</div>
	</div>
</div>
<div class="user_filter">
    <div class="dateTimeSection" id="day-wise-section">
        Day wise
        <span class="personalize-toggle">
            <mat-slide-toggle color="primary" [checked]="isUser" (change)="toggleUserWise($event)">
            </mat-slide-toggle>
        </span>
        User wise
    </div>

    <div class="dateTimeSection" id="employee-selection-section" *ngIf="isUser">
        <mat-form-field class="timeline-datepicker">
            <mat-label>Select Employee</mat-label>
            <mat-select (selectionChange)="userSelectionChange()" [(ngModel)]="selectedUser">
                <span style="margin-left: 2px;">Search employee</span>
                <input matInput type="text" class="search_input" #searchInp (keyup)="searchEmployee(searchInp.value)"
                    placeholder="Search employee" />
                <mat-option *ngFor="let emp of filteredEmpList" [value]="emp.id" (click)="employeeSelected(emp)">
                    {{ emp.name | titlecase }}
                </mat-option>
                <mat-option *ngIf="!filteredEmpList.length">No employee available</mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <div class="dateTimeSection" id="date-range-picker-section" *ngIf="isUser">
        <mat-form-field class="date-range-picker-form-field">
            <mat-date-range-input [rangePicker]="picker" [max]="todayDate">
                <input matStartDate [value]="startDate" placeholder="Start date"
                    (dateChange)="onStartDateChange($event)" />
                <input matEndDate [value]="endDate" placeholder="End date" (dateChange)="onEndDateChange($event)" />
            </mat-date-range-input>
            <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>
        </mat-form-field>
    </div>

    <div class="dateTimeSection" id="single-date-picker-section" *ngIf="!isUser">
        <i class="material-icons arrow_icons picker_icon" (click)="changeDate(true)">keyboard_arrow_left</i>
        <mat-form-field class="timeline-datepicker">
            <input matInput [matDatepicker]="pickerdate" [value]="selectDate" [max]="todayDate"
                (dateChange)="onSelectDateChange($event)" />
            <mat-datepicker-toggle matIconSuffix [for]="pickerdate"></mat-datepicker-toggle>
            <mat-datepicker #pickerdate></mat-datepicker>
        </mat-form-field>
        <i class="material-icons arrow_icons picker_icon" (click)="changeDate(false)">keyboard_arrow_right</i>
    </div>
</div>
import { Component, OnInit, Input, SimpleChanges, Inject } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { LoginService } from '../../../Services/login.service';
import { GroupService } from '../../../Services/group.service';
import { Router, ActivatedRoute } from '@angular/router';
import * as _ from 'lodash';
import swal from 'sweetalert2';
import { ProjectService } from 'src/app/Services/project.service';
import { SharedService } from 'src/app/Services/sharedService';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import {Subscription} from 'rxjs';

@Component({
	selector: 'app-add-project',
	templateUrl: './add-project.component.html',
	styleUrls: ['./add-project.component.css']
})
export class AddProjectComponent implements OnInit {
	toppingList: string[] = ['Extra cheese', 'Mushroom', 'Onion', 'Pepperoni', 'Sausage', 'Tomato'];
	createUserForm: UntypedFormGroup;
	allUsers: any;
	groupId: any;
	groupDetails: any;
	previousTeamLead: any;
	isEdit: any = false;
	team: any = [];
	removedTeam: any = [];
	loading = false;
	Employee: any;
	managerArr:any;
	leadArr:any;
	developerArr:any;
	isResponseGet: any = true;
	isMultipleLeads: any = true;
	isMultipleManagers: any = true;
	isMuleripleMember: any = true;
	@Input() teamData: any = {};
	@Input("teamId") teamId;
	userDataSubscription: Subscription;
	getfilteredTeamManagerSubscription: Subscription;
	getfilteredTeamTlSubscription: Subscription;
	getfilteredTeamEmployeeSubscription: Subscription;

	constructor(public _loginService: LoginService,
		@Inject(MAT_DIALOG_DATA) public dialogData: any,
		private _projectService: ProjectService,
		public _groupService: GroupService,
		private _router: Router,
		private _route: ActivatedRoute,
		private _sharedService: SharedService) {
		this.createUserForm = new UntypedFormGroup({
			title: new UntypedFormControl('', [Validators.required]),
			team_manager: new UntypedFormControl([]),
			team_lead: new UntypedFormControl([]),
			team_developer: new UntypedFormControl([])
		})
		this.getDevList();
		this._route.queryParams.subscribe((queryParams) => {
			if (queryParams.groupId) {
				this.isEdit = true;
				this.groupId = queryParams.groupId;
				this._projectService.getTeamDetail(queryParams.groupId).subscribe((data: any) => {
					let teamManagerArr = [];
					let teamDevloperArr = [];
					let teamleadArr = [];

					if (data.meta.team_manager) {
						const stringArray = data.meta.team_manager.split("~").filter(item => item !== ""); // Split and remove empty items
						teamManagerArr = stringArray.map(item => Number(item));
					}
					if (data.meta.team_developer) {
						const stringArray = data.meta.team_developer.split("~").filter(item => item !== ""); // Split and remove empty items
						teamDevloperArr = stringArray.map(item => Number(item));
					}
					if (data.meta.team_lead) {
						const stringArray = data.meta.team_lead.split("~").filter(item => item !== ""); // Split and remove empty items
						teamleadArr = stringArray.map(item => Number(item));
					}
					this.createUserForm = new UntypedFormGroup({
						title: new UntypedFormControl(data.title.rendered, [Validators.required]),
						slug: new UntypedFormControl(data.slug, [Validators.required]),
						team_manager: new UntypedFormControl(teamManagerArr),
						team_lead: new UntypedFormControl(teamleadArr),
						team_developer: new UntypedFormControl(teamDevloperArr)
					})
				});
			}

		})

	}
	ngOnInit() {
		if(this.dialogData.teamId){
			this.isEdit = true;
			this.groupId = this.dialogData.teamId;
			this._projectService.getTeamDetail(this.groupId).subscribe((data: any) => {
				let teamManagerArr = [];
				let teamDevloperArr = [];
				let teamleadArr = [];
				if (data.meta.team_manager) {
					const stringArray = data.meta.team_manager.split("~").filter(item => item !== ""); // Split and remove empty items
					teamManagerArr = stringArray.map(item => Number(item));
				}
				if (data.meta.team_developer) {
					const stringArray = data.meta.team_developer.split("~").filter(item => item !== ""); // Split and remove empty items
					teamDevloperArr = stringArray.map(item => Number(item));
				}
				if (data.meta.team_lead) {
					const stringArray = data.meta.team_lead.split("~").filter(item => item !== ""); // Split and remove empty items
					teamleadArr = stringArray.map(item => Number(item));
				}
				this.createUserForm = new UntypedFormGroup({
					title: new UntypedFormControl(data.title.rendered, [Validators.required]),
					slug: new UntypedFormControl(data.slug, [Validators.required]),
					team_manager: new UntypedFormControl(teamManagerArr),
					team_lead: new UntypedFormControl(teamleadArr),
					team_developer: new UntypedFormControl(teamDevloperArr)
				})
			});
		}
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.teamData.currentValue) {
			this.isEdit = true;
			this.groupId = changes.teamData.currentValue.id;
			this._projectService.getTeamDetail(this.groupId).subscribe((data: any) => {
				let teamManagerArr = [];
				let teamDevloperArr = [];
				let teamleadArr = [];
				if (data.meta.team_manager) {
					const stringArray = data.meta.team_manager.split("~").filter(item => item !== ""); // Split and remove empty items
					teamManagerArr = stringArray.map(item => Number(item));
				}
				if (data.meta.team_developer) {
					const stringArray = data.meta.team_developer.split("~").filter(item => item !== ""); // Split and remove empty items
					teamDevloperArr = stringArray.map(item => Number(item));
				}
				if (data.meta.team_lead) {
					const stringArray = data.meta.team_lead.split("~").filter(item => item !== ""); // Split and remove empty items
					teamleadArr = stringArray.map(item => Number(item));
				}
				this.createUserForm = new UntypedFormGroup({
					title: new UntypedFormControl(data.title.rendered, [Validators.required]),
					slug: new UntypedFormControl(data.slug, [Validators.required]),
					team_manager: new UntypedFormControl(teamManagerArr),
					team_lead: new UntypedFormControl(teamleadArr),
					team_developer: new UntypedFormControl(teamDevloperArr)
				})
			});
		}
	}




	onGroupSelect(event, userId) {
		if (event.source._selected == true) {
			this.team.push(userId)
			if (_.findIndex(this.removedTeam, function (o) { return o == userId._id }) > -1) {
				this.removedTeam.splice(_.findIndex(this.removedTeam, function (o) { return o == userId._id }), 1)
			}
		} else {

			this.team.splice(_.findIndex(this.team, function (o) { return o._id == userId._id }), 1)
            if(this.groupDetails)
			if (_.findIndex(this.groupDetails.members, function (o) { return o == userId._id }) > -1)
				this.removedTeam.push(userId._id);
		}
	}

	private markFormGroupTouched(formGroup: UntypedFormGroup) {
		(<any>Object).values(formGroup.controls).forEach(control => {
			control.markAsTouched();

			if (control.controls) {
				this.markFormGroupTouched(control);
			}
		});
	}
	ngAfterViewInit(){
		
	}
	getDevList() {
	this.userDataSubscription =	this._projectService.getUserList(0,0).subscribe((user: any) => {
			this.Employee = user;
		})
	this.getfilteredTeamManagerSubscription = this._projectService.getFilteredTeam('manager').subscribe((user: any) => {
			// console.log("MANAGER ARE AVAILABLE > ", user.length);
			if (user.length > 0) {
				this.managerArr = user;
				this.isMultipleManagers = true;
			} else {
				this.isMultipleManagers = false;
			}
		})
	this.getfilteredTeamTlSubscription =	this._projectService.getFilteredTeam('lead').subscribe((user: any) => {
			if (user.length > 0) {
				this.leadArr = user;
				this.isMultipleLeads = true;
			} else {
				this.isMultipleLeads = false;
			}
		})
	this.getfilteredTeamEmployeeSubscription = this._projectService.getFilteredTeam('employee').subscribe((user: any) => {
			if (user.length > 0) {
				this.developerArr = user;
				this.isMuleripleMember = true;
			} else {
				this.isMuleripleMember = false;
			}
		})
	}

	ngOnDestroy(){
     this.userDataSubscription.unsubscribe()
	//  this.getfilteredTeamManagerSubscription.unsubscribe();
	//  this.getfilteredTeamEmployeeSubscription.unsubscribe();
	//  this.getfilteredTeamTlSubscription.unsubscribe();
	}

	createTeam() {
		this.isResponseGet = false;
		let formValue = this.createUserForm.value;
		//const team_managerArr:string[] = formValue.team_manager.split(',');
		let formatedTeam_manager: string = (formValue.team_manager != null && formValue.team_manager.length) ? '~' + formValue.team_manager.join('~') + '~' : '';

		//const team_leadArr:string[] = formValue.team_lead.split(',');
		let formatedTeam_lead: string = (formValue.team_lead != null && formValue.team_lead.length) ? '~' + formValue.team_lead.join('~') + '~' : '';

		//const team_developerArr:string[] = formValue.team_developer.split(',');
		let formatedTeam_developer: string = (formValue.team_developer != null && formValue.team_developer.length) ? '~' + formValue.team_developer.join('~') + '~' : '';

		let teamData = {
			"status": "publish",
			"title": formValue.title,
			"slug": formValue.slug,
			"meta": {
				"team_manager": formatedTeam_manager,
				"team_lead": formatedTeam_lead,
				"team_developer": formatedTeam_developer
			}

		}

		this._projectService.createTeamMember(teamData).subscribe(data => {
			this.isResponseGet = true;
			swal.fire({
				position: 'center',
				icon: 'success',
				title: 'Team Created',
				showConfirmButton: false,
				timer: 3000
			})
			if (this._sharedService.newTeamModalinstance) {
				this._sharedService.newTeamModalinstance.close();
			}
			this._projectService.updateEmployeeList({});
			this.createUserForm.reset();
			this._router.navigate(['/projects']);


		}, (err) => {
			swal.fire({
				position: 'center',
				icon: 'error',
				title: 'Oops...',
				text: `${err.error.message}`,
				showConfirmButton: false,
				timer: 3000
			});
			this.isResponseGet = true;
		});


	}


	editTeam() {
		this.isResponseGet = false;
		let formValue = this.createUserForm.value;
		//const team_managerArr:string[] = formValue.team_manager.split(',');
		let formatedTeam_manager: string = formValue.team_manager.length ? '~' + formValue.team_manager.join('~') + '~' : '';

		//const team_leadArr:string[] = formValue.team_lead.split(',');
		let formatedTeam_lead: string = formValue.team_lead.length ? '~' + formValue.team_lead.join('~') + '~' : '';

		//const team_developerArr:string[] = formValue.team_developer.split(',');
		let formatedTeam_developer: string = formValue.team_developer.length ? '~' + formValue.team_developer.join('~') + '~' : '';

		let teamData = {
			"title": formValue.title,
			"slug": formValue.slug,
			"meta": {
				"team_manager": formatedTeam_manager,
				"team_lead": formatedTeam_lead,
				"team_developer": formatedTeam_developer
			}

		}
		this._projectService.editTeamMeamber(this.groupId, teamData).subscribe(data => {
			this.isResponseGet = true;
			swal.fire({
				position: 'center',
				icon: 'success',
				title: 'Team Updated',
				showConfirmButton: false,
				timer: 3000
			})
			this._projectService.updateEmployeeList({})
			if (this._sharedService.teamModalinstance) {
				this._sharedService.teamModalinstance.close();
			}
			// this._router.navigate(['/projects']);
		}, (err) => {
			swal.fire({
				position: 'center',
				icon: 'error',
				title: 'Oops...',
				text: `${err.error.message}`,
				showConfirmButton: false,
				timer: 3000
			});
			this.isResponseGet = true;
		});
	}

	removeKeysWithUndefinedValues(obj: any): any {
		const newObj: any = {};

		for (const key in obj) {
			if (obj.hasOwnProperty(key)) {
				const value = obj[key];
				if (value !== undefined && value !== "") {
					newObj[key] = value;
				}
			}
		}

		return newObj;
	}

	cancelAction(){
		this.createUserForm.reset();
	}
}

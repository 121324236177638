import { Component } from '@angular/core';
import { SharedService } from '../Services/sharedService';

@Component({
  selector: 'app-reset-sucessfully',
  templateUrl: './reset-sucessfully.component.html',
  styleUrls: ['./reset-sucessfully.component.css']
})
export class ResetSucessfullyComponent {
 
  constructor(private _sharedServices:SharedService){
    this._sharedServices.sideMenuSubject.next(false);
  }
}

// import { CanActivateFn } from '@angular/router';

// export const settingGuardGuard: CanActivateFn = (route, state) => {
//   return true;
// };
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root'
})

export class settingGuardGuard  {
	constructor(private router: Router){
	}
	
	canActivate(
		next: ActivatedRouteSnapshot,
		state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		if(localStorage.getItem('isAdmin') !='true'){
			// alert('unauthorize access');
			this.router.navigate(['']);
			return false;
		}
		return true;
	}
}
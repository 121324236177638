import { ElementRef, SimpleChanges } from "@angular/core";
import { Component, Input, Output, EventEmitter } from "@angular/core";
import { AngularFireDatabase } from "@angular/fire/compat/database";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Observable } from "rxjs";
import { Sort } from "@angular/material/sort";
import { ProjectService } from "src/app/Services/project.service";
import { ConfirmationDialogComponent } from "src/app/confirmation-dialog/confirmation-dialog.component";
import { SharedService } from "src/app/Services/sharedService";
import { ScreencastActionModelComponent } from "src/app/screencast/screencast-action-model/screencast-action-model.component";
import { Router } from "@angular/router";
import { CustomTimeAgoPipe } from '../../time-ago.pipe';
import { NewPasswordComponent } from "src/app/new-password/new-password.component";

@Component({
  selector: "app-users-table",
  templateUrl: "./users-table.component.html",
  styleUrls: ["./users-table.component.css"],
})
export class UsersTableComponent {
  @Input() developerList: any;
  @Input() devList: any;
  @Input() modalRef: ElementRef;
  @Input() widthStyle: any;
  @Input() isMaxContent: string;
  @Input() loadWaiter: Boolean;
  @Output() modalOpen = new EventEmitter<any>();
  @Output() deleteEmployeee = new EventEmitter<any>();
  @Output() sortingData = new EventEmitter<any>();
  @Input() showAction: false;
  @Input() isTeam: boolean;
  @Input() statusLoad: Boolean;
  @Input() expired: Boolean;
  isAdmin = true;
  isManager = true;

  errorPhotoShow: Boolean = false;
  skeletonLoader: Boolean = true;
  // sortedData: any;
  private dbPath = localStorage.getItem('dbPath');
  userImageClass = 'avatar-img';

  constructor(
    private _projectService: ProjectService,
    private db: AngularFireDatabase,
    public dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private _sharedService: SharedService,
    private router: Router,
    private customtimeAgoPipe: CustomTimeAgoPipe
  ) {
    // this.searchData.filter(section => 
    //   section.section_type === 'Employee').map((item:any) => item["data"]=user) 
    // console.log("changes happen : ", this.developerList);
    this.getUSer();
  }

  ngOnInit() {
    setInterval(() => {
      this.errorPhotoShow = true;
      this.skeletonLoader = false;
    }, 10000);
  }

  ngOnChanges(changes: SimpleChanges) {    
    // if(!this.developerList){
    // console.log("changes happen : ", this.developerList.length);
    // this.developerList = [];
    // this.developerList.forEach((item:any) =>{  
    // //  return item.status == undefined ? item["status_type"] = 'Away' : item.status == 'online' ? item["status_type"] = 'Online' : item["status_type"] = 'Offline';
    // })
    // }

    // this.developerList = this.developerList.sort((a, b) => {
    //   if (a.status === 'online' && b.status !== 'online') {
    //     return -1;
    //   }
    //   if (a.status !== 'online' && b.status === 'online') {
    //     return 1;
    //   }
    //   return 0;
    // });

    // if (this.developerList) {
    //   this.developerList.forEach((developer: any, index) => {
    //     developer.meta.last_ss = developer.meta.last_screenshot;
    //     developer.meta.last_screenshot = this.customtimeAgoPipe.transform(developer.meta.last_screenshot);
    //   });
    // }

    // if (this.allUsers) {
    //   this.allUsers.forEach((developer: any, index) => {
    //     developer.meta.last_ss = developer.meta.last_screenshot;
    //     developer.meta.last_screenshot = this.customtimeAgoPipe.transform(developer.meta.last_screenshot);
    //   });
    // }

    // let ids = new Set();

    // if (this.allUsers && this.allUsers.length > 0) {
    //   this.allUsers = this.allUsers.filter((ele) => {
    //     if (!ids.has(ele.id)) {
    //       ids.add(ele.id);
    //       return ele;
    //     }
    //   });
    // }

    // if (this.developerList && this.developerList.length > 0) {
    //   this.developerList = this.developerList.filter((ele) => {
    //     if (!ids.has(ele.id)) {
    //       ids.add(ele.id);
    //       return ele;
    //     }
    //   });
    // }
  }

  openModalFire(id: any) {
    this.developerList.find((dev) => {
      if (dev.id == id) {
        dev.isMenuOpened = false;
      }
    });
    this.modalOpen.emit({ eleRef: this.modalRef, isEdit: true, userId: id });
  }
  deleteUser(id, dev) {
    // this.dialog.open(ConfirmationDialogComponent, {
    // });
    let data = {
      id: id,
      name: dev.name,
      type: "employee"
    };
    this.developerList.find((dev) => {
      if (dev.id == id) {
        dev.isMenuOpened = false;
      }
    });
    this.openDialog(ConfirmationDialogComponent, data).subscribe((response) => {
      if (response != undefined) {
        this._projectService
          .DeleteEmployee(response.delete)
          .subscribe((data: any) => {
            this.deleteEmployeee.emit({ data: data });
          });
      }
    });
  }
  openDialog(someComponent, data = {}): Observable<any> {
    const dialogRef = this.dialog.open(someComponent, { data });
    return dialogRef.afterClosed();
  }
  openScreencastActionModel(user) {
    //  this._projectService.openScreencastModa();
    let obj = {


      userDetails: user,
      userId: user.id
    };
    let data = obj;
    const dialogRef = this.openDialog(
      ScreencastActionModelComponent,
      data

      //maxHeight: "calc(100vh - 90px)",
    ).subscribe((response) => { });
  }

  screenshotRequest(id, Status, name) {
    if (!navigator.onLine) {
      this._snackBar.open("Please check your internet connection?", "Undo", {
        duration: 3000,
      });
    } else {
      console.log("CLIICK NOT ONLINE")
      let data = {
        type: "Requesting Screenshot",
        userName: name,
      };
      this._sharedService.showToast(data);
      setTimeout(() => {
        this.db.database.ref(`${this.dbPath}`).child(`${id}`).get().then((res) => {
          if (res.val().ssRequest == null) {
            this.db.database
              .ref(`${this.dbPath}`)
              .child(`${id}`)
              .update({ ssRequest: false });
            let data = {
              type: "Cant get screenshot",
              userName: name,
            };
            this._sharedService.showToast(data);
          }
          // else{
          //   this._sharedService.showToast(res.val().ssRequest);
          // }
        });
      }, 20000)
      if (Status == "online") {
        this.db.database
          .ref(`${this.dbPath}`)
          .child(`${id}`)
          .update({ ssRequest: null });
      }
    }
  }

  // sortData(sort: Sort) {
  //   if (this.developerList.length > 0) {
  //     const data = this.developerList.slice();
  //     if (!sort.active || sort.direction === "") {
  //       this.developerList = data;
  //       return;
  //     }

  //     console.log(this.developerList, "devloperlist")
  //     this.developerList = data.sort((a, b) => {
  //       const isAsc = sort.direction === "asc";
  //       switch (sort.active) {
  //         case "Employee Name":
  //           return this.compare(a.name.toLowerCase(), b.name.toLowerCase(), isAsc);
  //         case "status":
  //           const statusA: any = this.getStatusValue(a);
  //           const statusB: any = this.getStatusValue(b);
  //           return this.compare(statusA, statusB, isAsc);
  //         case 'screenshot':
  //           return this.compare(a.meta.last_ss, b.meta.last_ss, isAsc);
  //         case 'usedApps':
  //           return this.compare(a.meta.last_application_name, b.meta.last_application_name, isAsc);
  //         case 'role':
  //           return this.compare(a.role.length > 1 ? a.role[1] : a.role[0], b.role.length > 1 ? b.role[1] : b.role[0], isAsc);
  //         default:
  //           return 0;
  //       }
  //     });

  //   }
  // }

  sortData(sort: Sort) {
    this.sortingData.emit(sort);
  }

  openMenu(dev) {
    this.developerList.find((singleDev) => {
      if (singleDev.id != dev.id) {
        singleDev.isMenuOpened = false;
      }
    });
    if (dev.isMenuOpened) {
      dev.isMenuOpened = false;
    } else {
      dev.isMenuOpened = true;
    }
    return dev;
  }

  isPersonalizeSetting(setting) {
    if (Array.isArray(setting)) {
      // this.globalSettingsData = {}
      if (!setting.length) {
        return true;
      }
    } else {
      if (setting.ss_personalizeSettings) {
        if (setting.ss_personalizeSettings == 'true') {
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    }
  }

  getUSer() {
    if (this.isTourActive()) {
      let roleArr = [];
      this._projectService.getUser().subscribe((data: any) => {
        roleArr = data.role;

        let adminRole = roleArr.filter((ele) => {
          return (ele.toLowerCase().includes("admin") || ele.toLowerCase().includes("company"));
        })
        if (adminRole.length == 0) {
          this.isAdmin = false;
        } else {
          this.isAdmin = true;
        }

        let managerRole = roleArr.filter((ele) => {
          return (ele.toLowerCase().includes("manager"));
        })
        if (managerRole.length == 0) {
          this.isManager = false;
        } else {
          this.isManager = true;
        }
      })
    } else {
      let roleArr = [];
      this._projectService.getUser().subscribe((data: any) => {
        roleArr = data.role;
        let adminRole = roleArr.filter((ele) => {
          return (ele.toLowerCase().includes("admin") || ele.toLowerCase().includes("company"));
        })
        if (adminRole.length == 0) {
          this.isAdmin = false;
        } else {
          this.isAdmin = true;
        }

        let managerRole = roleArr.filter((ele) => {
          return (ele.toLowerCase().includes("manager"));
        })
        if (managerRole.length == 0) {
          this.isManager = false;
        } else {
          this.isManager = true;
        }
      })
    }
  }

  isTourActive() {
    if (localStorage.getItem('isTour')) {
      if (localStorage.getItem('isTour') == 'false') {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  checkIsActive() {
    if (this.router.url.includes('/employees') == true) {
      return true;
    } else {
      return false;
    }
  }

  resetPassword(user: any) {
    console.log("user: ", user)
    const dialogData = {
      id: user.id,
      email: user.email
    }
    // this.openDialog(NewPasswordComponent, dialogData).subscribe((res) => {
    //   console.log("dialog: ", res)
    // })

    this.dialog.open(NewPasswordComponent, {
      width: '660px',
      data: dialogData
    })
  }
}

import { Component } from '@angular/core';
import { TourConfigService } from '../Services/tour-config.service';
import {TourService} from 'ngx-ui-tour-md-menu'
import { MatDialog } from '@angular/material/dialog';
import { ProjectService } from '../Services/project.service';
import { SharedService } from '../Services/sharedService';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.css']
})
export class WelcomeComponent {

  isloading = false;

constructor(public tourService:TourService,private _tourConfig:TourConfigService,public dialog: MatDialog,private _projectService:ProjectService,private _sharedServices:SharedService){
  localStorage.setItem('isTour','false')
  this._projectService.updateDashboardTourData({data:'false'})
}

startTour(){
  this.isloading = true;
  this._projectService.getUser().subscribe((data:any)=>{
    let roleArr =  data.role;
    let isnotAdmin;
    let adminRole = roleArr.filter((ele) => {
        return (ele.toLowerCase().includes("admin") || ele.toLowerCase().includes("company"));
      })
      if(adminRole.length == 0){
       isnotAdmin = true;
      }else{
        isnotAdmin = false;
      }
      localStorage.setItem('isTour','true')
      this._projectService.updateDashboardTourData({data:'true'})
      this._sharedServices.sideMenuSubject.next(true);

      this.dialog.closeAll();
      this._tourConfig.getTourData(isnotAdmin);
      this.tourService.start()
      this.isloading = false;
    },err=>{
      this.dialog.closeAll();
      this.isloading = false;
    })
}

skipTour(){
  localStorage.setItem('isTour','false')
  this._sharedServices.sideMenuSubject.next(true);
  this._projectService.updateDashboardTourData({data:'false'})
  this.dialog.closeAll();
  this._projectService.updateTour().subscribe((res: any) => {
    localStorage.setItem('isTour','false')
  },err=>{})
}
}


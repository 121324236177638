<div class="container-fluid ">
    <div class="row">
        <div class="col s12 employee-list-container">
          <h1>Payment History</h1>
        </div>
    </div>
<div class="list-parts">
    <table
    matSort
    (matSortChange)="sortData($event)"
    class="highlight centered"
    style="padding: 0px 32px"
  >
    <thead class="">
      <tr class="table-head">
        <th mat-sort-header="Id">Invoice id</th>
        <th mat-sort-header="Date">Date</th>
        <th mat-sort-header="Users">User</th>
        <th mat-sort-header="Plan">Plan</th>
        <th mat-sort-header="Amount">Amount</th>
        <th>Action</th>

      </tr>
    </thead>
    <tbody *ngIf="!loadWaiter">
        <tr *ngFor="let val of orderList">
          <td>
             {{val.invoice_no}}
          </td>
          <td>
            {{val.date ? formattedDate(val.date) : '-'}}
          </td>
          <td>
            {{val.plan_data && val.plan_data[0] ? val.plan_data[0].users : '-'}}
          </td>
          <td>
            {{val.plan_data && val.plan_data[0]  ? val.plan_data[0].title : '-'}}
          </td>
          <td>
            {{formatValue(val.total)}}
          </td>
          <td>
            <a href="{{val.pdf}}">Download</a>
            
          </td>
        </tr>
    </tbody>
  </table>
  <!-- <div>
    <mat-paginator [length]="orderList.length" [pageSize]="25" [pageSizeOptions]="[25, 50, 100]" aria-label="Select page"
      (page)="onPaginateChange($event)" #EmpPaginator>
    </mat-paginator>
  </div> -->
  <div>
    <div *ngIf="loadWaiter">
      <ngx-skeleton-loader
        style="margin: 20px 0px; width: 100%"
        count="6"
        [theme]="{ 'border-radius': '2', height: '50px', width: '100%' }"
      ></ngx-skeleton-loader>
    </div>
    <div
      *ngIf="!loadWaiter && orderList && !orderList.length"
      style="width: 100%"
    >
      <div class="empty_list z-depth-2">
        <img src="assets/images/empty_list.png" alt="empty list image" />
      </div>
    </div>
  </div>
</div>
</div>
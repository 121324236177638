import { DatePipe, Location } from '@angular/common';
import { ChangeDetectorRef, Component, ElementRef, ViewChild } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { ProjectService } from 'src/app/Services/project.service';
import 'jspdf-autotable';
import * as XLSX from 'xlsx';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-user-report',
  templateUrl: './user-report.component.html',
  styleUrl: './user-report.component.css',
  providers: [DatePipe],
})
export class UserReportComponent {
  loadWaiter = false;
  timelineList: any = [];
  userName = '';
  userId;
  userImageClass = 'avatar-img';
  startDate;
  endDate = new Date();
  selectDate = new Date();
  strTimesheet = 'Employee' + "'s" + ' Timesheet'
  selectedUser: any;
  testingSelect: any;
  selectedEmpName = '';
  currentUser = '';
  employeeList: any = [];
  filterTimelineList: any = [];
  isUser = false;
  isApplicationUsage = false
  selectedValue;
  selectedEmployee = new FormControl('418')
  filteredEmpList: any = [];
  todayDate = new Date();
  headers = [
    { key: 'index', label: 'Sr no', visible: true },
    { key: 'name', label: 'Employee name', visible: true, sortable: true },
    { key: 'date', label: 'Date', visible: this.isUser, sortable: true },
    { key: 'first_activity', label: 'First activity', visible: true, sortable: true },
    { key: 'last_activity', label: 'Last activity', visible: true, sortable: true },
    { key: 'productive', label: 'Productive hours', visible: true, sortable: true },
    { key: 'idle', label: 'Idle hours', visible: true, sortable: true },
    { key: 'working', label: 'Total hours', visible: true, sortable: true }
  ];

  @ViewChild('dataTable') dataTable: ElementRef;

  constructor(private location: Location, private _projectService: ProjectService, private _router: Router, private _route: ActivatedRoute, public datePipe: DatePipe, private _cd: ChangeDetectorRef) {

    this.startDate = new Date(
      new Date().getFullYear(),
      new Date().getMonth() - 1,
      new Date().getDate()
    );

    this._route.params.subscribe((params) => {
      this.userId = params.userId
      if (this.userId) {
        this.getUserName();
      }
    })
    this.userId ? this.isUser = true : this.isUser = false;
    this.getAllUserList();
    this.getLogsData();

  }


  updateHeadersVisibility() {
    this.headers[2].visible = this.isUser; // Update the visibility of the Date header
  }

  sortData(sort: Sort) {
    if (this.timelineList.length > 0) {
      const data = this.timelineList.slice();
      if (!sort.active || sort.direction === "") {
        this.timelineList = data;
        return;
      }

      this.timelineList = data.sort((a, b) => {
        const isAsc = sort.direction === "asc";
        switch (sort.active) {
          case "name":
            return this.compare(a.name ? a.name.toLowerCase() : '', b.name ? b.name.toLowerCase() : '', isAsc);
          case "date":
            return this.compare(a.date, b.date, isAsc);
          case 'first_activity':
            return this.compare(a.first_activity, b.first_activity, isAsc);
          case 'last_activity':
            return this.compare(a.last_activity, b.last_activity, isAsc);
          case 'productive':
            return this.compare(a.productive, b.productive, isAsc);
          case 'idle':
            return this.compare(a.idle, b.idle, isAsc);
          case 'working':
            return this.compare(a.working, b.working, isAsc);
          default:
            return 0;
        }
      });

    }

  }

  getFieldValue(val: any, field: string, index: number): string {
    // Handle 'index' field case
    if (field === 'index') {
      return (index + 1).toString();
    }

    // Handle non-user case
    if (!this.isUser) {
      return val[field] || '-';
    }

    // Handle user case
    if (field === 'name') {
      return this.selectedValue?.name ? this.trimName(this.selectedValue.name) : '-';
    }

    return val[field] || '-';
  }

  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  getUserName() {
    this._projectService.getEmployeeData(this.userId).subscribe(
      (data: any) => {
        this.userName = this.trimName(data.name) + "'s" + ' Timesheet';
      }, err => {

      })
  }
  getLogsData() {
    this.loadWaiter = true;
    this._projectService
      .getUserReportLog(this.isUser, this.userId, this.datePipe.transform(this.selectDate, "yyyy-MM-dd"), this.datePipe.transform(this.startDate, "yyyy-MM-dd"), this.datePipe.transform(this.endDate, "yyyy-MM-dd"), this.isApplicationUsage)
      .subscribe(async (data: any) => {
        this.initDefaultHeaders()
        if (this.isApplicationUsage) {
          const uniqueKeys = Array.from(new Set(data.flatMap(Object.keys)));
          const keysArray = this.headers.map(header => header.key);
          const missingFromHeadersKeys = uniqueKeys
            .filter((key: string) => !keysArray.includes(key))
            .filter(key => key !== 'user_id');
          await this.updateDefaultHeaders(missingFromHeadersKeys)
          this.timelineList = data;
          this.filterTimelineList = data;
          this.loadWaiter = false;
        }
        else {
          this.timelineList = data;
          this.filterTimelineList = data;
          this.loadWaiter = false;
        }
      }, err => {
        this.timelineList = [];
        this.filterTimelineList = [];
        this.loadWaiter = false;
      })
  }

  onPaginateChange(event) {
  }

  onStartDateChange(event) {
    if (event.value) {
      this.startDate = event.value;
    }
  }

  initDefaultHeaders() {
    this.headers = [
      { key: 'index', label: 'Sr no', visible: true },
      { key: 'name', label: 'Employee name', visible: true, sortable: true },
      { key: 'date', label: 'Date', visible: this.isUser, sortable: true },
      { key: 'first_activity', label: 'First activity', visible: true, sortable: true },
      { key: 'last_activity', label: 'Last activity', visible: true, sortable: true },
      { key: 'productive', label: 'Productive hours', visible: true, sortable: true },
      { key: 'idle', label: 'Idle hours', visible: true, sortable: true },
      { key: 'working', label: 'Total hours', visible: true, sortable: true }
    ];
  }

  updateDefaultHeaders(missingFromHeadersKeys) {

    missingFromHeadersKeys.forEach(headerKey => {
      this.headers.push({ key: headerKey, label: headerKey, visible: true, sortable: true })
    });
  }

  onEndDateChange(event) {
    console.log(event)
    if (event.value) {
      this.endDate = event.value;
    }
    this.getLogsData();
  }

  stackDatePrevNextAction(prev) {
    if (prev) {
      this.selectDate = new Date(
        new Date(this.selectDate).getFullYear(),
        new Date(this.selectDate).getMonth(),
        new Date(this.selectDate).getDate() - 1
      );
      this.getLogsData();

    } else {
      if (this.datePipe.transform(this.selectDate, "yyyy-MM-dd") != this.datePipe.transform(new Date(), "yyyy-MM-dd")) {
        this.selectDate = new Date(
          new Date(this.selectDate).getFullYear(),
          new Date(this.selectDate).getMonth(),
          new Date(this.selectDate).getDate() + 1
        );
        this.getLogsData();
      }

    }
  }

  onSelectDateChange(event) {
    this.selectDate = event.value;
    this.getLogsData();
  }

  formateActivityTime(time) {
    const currentDate = time.split(':');
    return currentDate[0] + ':' + currentDate[1];
  }

  formateTime(time) {
    const currentDate = time.split(':');
    return currentDate[0] + " hr " + currentDate[1] + " min";
  }

  downloadAction() {
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(this.dataTable.nativeElement);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    var wscols = [
      { wch: 5 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 }
    ];

    ws["!cols"] = wscols;

    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, 'Supersee_timesheet.xlsx');
  }

  onUserSelectionChange() { }

  employeeSelected(value: any) {
    if (this.selectedUser == this.currentUser) {
      this.selectedValue = null;
      this.timelineList = [];
      this.userId = null;
      this.currentUser = '';
      this.selectedUser = this.userId;
      this.strTimesheet = 'Employee' + "'s" + ' Timesheet'
      this.userName = this.strTimesheet;
      this.selectedEmpName = this.userName;


      this._route.params.subscribe((params) => {
        if (params.userId) {
          this.location.go('/user-report');
        }
      })

      return
    }
    this.selectedValue = value;
    this.selectedEmpName = this.trimName(value.name) + "'s" + ' Timesheet';
    this.userName = this.selectedEmpName;
    this.userId = value.id;
    this.currentUser = value.id;
    this.timelineList = this.filterTimelineList;
    this.getLogsData();
  }

  filterEmployee(selected) {
    this.getLogsData();
  }

  getAllUserList() {
    this._projectService.getUserList(0, 0).subscribe((user: any) => {
      this.employeeList = user;
      this.filteredEmpList = user;
      if (!this.userId) {
        this.userId = this.employeeList[0].id;
        this.selectedValue = this.employeeList[0];
      } else {
        let filterVal = this.employeeList.filter((val) => {
          return val.id == this.userId;
        })
        if (filterVal.length) {
          this.selectedValue = filterVal[0];
        }
      }
      this.selectedUser = this.userId;
      this.currentUser = this.selectedUser;
      this.selectedEmployee.patchValue(this.userId);
      this._cd.detectChanges();
    })
  }

  isPersonalizesToggleChange(event) {
    console.log(event.checked, this.selectedValue, "event--")
    if (event.checked == true) {
      if (this.employeeList.length) {
        if (!this.selectedValue) {
          this.userId = this.employeeList[0].id;
          this.selectedValue = this.employeeList[0];
          this.strTimesheet = this.trimName(this.employeeList[0].name) + "'s" + ' Timesheet'
        } else {
          this.strTimesheet = this.trimName(this.selectedValue.name) + "'s" + ' Timesheet'
        }

        this.userName = this.strTimesheet;
        this.selectedEmpName = this.userName;
        this.selectedUser = this.userId;
        this.currentUser = this.selectedUser;
        this.selectedEmployee.patchValue(this.userId);
        if (this.selectedValue) {
          this.userId = this.selectedValue.id;
          this.getLogsData();
        } else {
          if (this.userId) {
            this.getLogsData();
          } else {

            this.timelineList = [];
            this.filterTimelineList = [];
          }
        }
      } else {
        this.getLogsData();
        this.timelineList = [];
        this.filterTimelineList = [];
        this.userId = null;
        this.strTimesheet = 'Employee' + "'s" + ' Timesheet'
        this.userName = this.strTimesheet;
        this.selectedEmpName = this.userName;
        this.selectedUser = this.userId;
        this.selectedValue = [];
      }

    } else {
      this.getLogsData();

      this._route.params.subscribe((params) => {
        if (params.userId) {
          this.location.go('/user-report');
        }
      })
      this.strTimesheet = 'Employee' + "'s" + ' Timesheet'
      this.userName = this.strTimesheet;

      this._cd.detectChanges();
    }
  }

  isApplicationUsageToggleChange(event) {
    this.getLogsData();
  }

  searchEmployee(val: any) {
    this.employeeList = this.filteredEmpList;
    let svalue = val.toLowerCase();
    this.employeeList = this.filteredEmpList.filter((ele) => {
      return ele.name.toLowerCase().includes(svalue);
    });
    if (val == "") {
      this.employeeList = this.filteredEmpList;
    }
  }

  compareUsers(user1: any, user2: any): boolean {
    return user1 && user2 ? user1 == user2 : user1 == user2;
  }

  trimName(name) {
    const trimmedName = name.length <= 20 ? name : name.slice(0, 20) + (name.length > 20 ? '...' : '');
    return trimmedName;
  }

  changeDateFormate(str: any) {
    return str ? this.datePipe.transform(new Date(str), "M/dd/yyyy") : '-'
  }

  isIconDisable() {
    if (this.datePipe.transform(this.selectDate, "yyyy-MM-dd") != this.datePipe.transform(new Date(), "yyyy-MM-dd")) {
      return false;
    } else {
      return true;
    }
  }

  getHeaderTitle() {
    return !this.isUser ? this.strTimesheet : (this.selectedEmpName || this.userName || this.strTimesheet);
  }

  trackByEmpId(index: number, emp: any): number {
    return emp.id;
  }

}


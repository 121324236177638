<div class="user_filter">
    <div class="dateTimeSection" id="application-usage-section">
        <span class="personalize-toggle">
            <mat-slide-toggle color="primary" [checked]="isApplicationUsage" (change)="toggleApplicationUsage($event)">
            </mat-slide-toggle>
        </span>
        Include Application Usage
    </div>
    <div class="dateTimeSection" id="website-usage-section">
        <span class="personalize-toggle">
            <mat-slide-toggle color="primary" [checked]="isWebsiteUsage" (change)="toggleWebsiteUsage($event)">
            </mat-slide-toggle>
        </span>
        <span>
            Include Website Usage
            <span style="margin-right: 2px;" class="beta-badge" *ngIf="sysConfig.isBeta"
                [matTooltip]="sysConfig.betaTooltip">
                <i>Beta</i>
            </span>
        </span>
    </div>

    <div class="dateTimeSection">
        <button mat-raised-button class="employee-add-btn" (click)="exportData()">
            Export
        </button>
    </div>
</div>
import {
  Component,
  ElementRef,
  HostListener,
  OnInit,
  Input,
  ViewChild,
  Renderer2
} from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { ProjectService } from "src/app/Services/project.service";
import { MatSidenav } from "@angular/material/sidenav";
import { BehaviorSubject, Observable } from "rxjs";
import { SharedService } from "src/app/Services/sharedService";
import { CacheService } from "src/app/Services/cache.service";
import { HttpClient } from '@angular/common/http';
import * as M from "materialize-css/dist/js/materialize";
import { MessagingServiceService } from "src/app/Services/messaging-service.service";
import { MatDialog } from "@angular/material/dialog";
import { WelcomeComponent } from "src/app/welcome/welcome.component";
import { map } from "rxjs/operators";

declare var $;
// import * as $ from "jquery";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.css"],
})
export class HeaderComponent implements OnInit {
  @Input() inputSideNav: MatSidenav;
  @ViewChild("search") seachfield;
  isEmployee = false;
  tabs: any = [];
  eventurl: any = [];
  empCount: any;
  iscontent = false;
  isSearchDiv = false;
  isNotification = false;
  isProfile = false;
  sideMenu = false;
  currentUser = JSON.parse(localStorage.getItem("logedInUser") || "{}");
  adminData;
  searchData: any = [];
  filterData: any = [];
  sideMenuMode = 'side';
  url: string = '../../assets/search.json';
  isTourActive = 'false';
  user;
  isTeamModal = false;
  isAdmin = true;

  constructor(
    private router: Router,
    private _projectService: ProjectService,
    private elementRef: ElementRef,
    private _sharedService: SharedService,
    private http: HttpClient,
    private _messagingService: MessagingServiceService,
    private dialog: MatDialog,
    private _sharedServices: SharedService,
    private _cacheService: CacheService,
    private renderer: Renderer2
  ) {
    this.isTeamModal = false;
    if (localStorage.getItem('isTour')) {
      this.isTourActive = localStorage.getItem('isTour');
    }
    // if(localStorage.getItem('selectedData')){
    //   localStorage.removeItem('selectedData');
    // }
    let isDashboardActive = false;
    if (this.router.url == "/") {
      isDashboardActive = true;
    }
    this.tabs = [
      {
        name: "Dashboard",
        route: "/dashboard",
        icon: "dashboard",
        isActive: isDashboardActive,
      },
      {
        name: "Employees",
        route: "/employees",
        icon: "person_apron",
      },
      {
        name: "Teams",
        route: "/teams",
        icon: "groups",
      },
    ];

    if (this.router.url == this.router.url) {
      this.tabs.some((el) => {
        if (el.route == this.router.url) {
          el.isActive = true;
        }
      });
    }

    this._projectService.screencastRequest.subscribe((value) => {
      this.iscontent = value;
      if (value == false) {
        this.iscontent = false;
      }
    });
    // this.router.events.subscribe((routerEvent: NavigationEnd) => {
    //   console.log("routerEvent.url ", routerEvent.url);
    //   this.eventurl = routerEvent.url;
    // });

    this.adminData = JSON.parse(localStorage.getItem('logedInUser'));
  }
  searchClick() {
    if (localStorage.getItem('isTour')) {
      this.isTourActive = localStorage.getItem('isTour');
    }
    if (this.isTourActive == 'false') {
      this.isSearchDiv = !this.isSearchDiv;
      this.isNotification = false;
      this.isProfile = false
    }
  }
  ngOnInit() {
    let tour = localStorage.getItem('isTour');

    this._sharedServices.toggleMenu().subscribe((result: any) => {

      if (window.screen.width <= 1200) {
        if (tour == 'false') {
          this.sideMenuMode = 'over';
        }
      } else {
        this.sideMenuMode = 'side';
      }
    });
    if (window.screen.width <= 1200 && tour == 'false') {
      this.sideMenuMode = 'over';
    }
    else if (window.screen.width >= 1200) {
      this.sideMenuMode = 'side';
    }

    $(document).ready(function () {
      $(".dropdown-trigger").dropdown();
      // $(".dropdown-trigger").dropdown();
    });
    this.getUSer();
    this.router.events.subscribe((route) => {
      let routeChange = route instanceof NavigationEnd;
      if (routeChange) {
        this.isSearchDiv = false;
      }
    })
  }
  getJsonList() {
    if (localStorage.getItem('isAdmin')) {
      if (localStorage.getItem('isAdmin') == 'true') {
        this.url = '../../assets/search.json';
      } else {
        this.url = '../../assets/seachManager.json';
      }
    } else {
      this.url = '../../assets/search.json';
    }
    this.http.get(this.url).subscribe(res => {
      this.searchData = res;
      this.filterData = res;
      this.getDevList()
      this.getTeam()
    });
  }
  getUSer() {
    let roleArr = [];
    this._projectService.getUser().subscribe((data: any) => {
      localStorage.setItem('user', JSON.stringify(data))
      let isExpired = this._sharedService.checkPlanExpired(data)
      if (isExpired) {
        this.router.navigateByUrl('/plan-expired');
      }
      roleArr = data.role;
      this.user = data;
      let adminRole = roleArr.filter((ele) => {
        return (ele.toLowerCase().includes("admin") || ele.toLowerCase().includes("company"));
      })
      if (adminRole.length == 0) {
        this.isAdmin = false;
        localStorage.setItem('isAdmin', 'false')
      } else {
        this.isAdmin = true;
        localStorage.setItem('isAdmin', 'true')
      }
      this.getJsonList();
    })
  }
  logoutCall() {
    localStorage.clear();
    this._cacheService.clear();
    this._sharedService.logInStateSubject.next(false);
    this.router.navigate(["/login"]);
    this._messagingService.resetData();
  }

  @HostListener("document:click")
  onClick($event: HTMLElement) {
    // if($('.notificationDiv').hasClass('hidediv') == false){
    // $('.notificationDiv').addClass('hidediv');
    // }
    const eventTarget = this.elementRef.nativeElement.contains(event.target);
    if (!eventTarget) {
      this.isSearchDiv = false;
      this.isNotification = false;
      this.isProfile = false;
    }
  }
  @HostListener('window:keydown', ['$event'])
  handleKeyDown(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      this.isSearchDiv = false;
      this.isNotification = false;
      this.isProfile = false;
    }
  }
  toggleSideMenu() {
    if (this.sideMenu == false) {
      this.sideMenu = true;
    } else {
      this.sideMenu = false;
    }
    this._sharedService.sideMenuSubject.next(this.sideMenu);
  }
  openModal(actionType, element: ElementRef) {
    this._projectService.getUser().subscribe(
      (data: any) => {
        const isExpired = this.expiringDate(data.meta.expires_at);
        if (isExpired == false) {
          if (actionType == 'team') {
            this.isTeamModal = true;
            this._sharedService.newTeamModalinstance = M.Modal.init(element);
            this._sharedService.newTeamModalinstance.open();
            this.isSearchDiv = false;
          } else if (actionType == 'employee') {
            if (this.user.meta.quota_remaining != false || this.user.meta.quota_remaining != 'false') {
              this._sharedService.modalinstance = M.Modal.init(element,
                {
                  complete: () => {
                  },
                },
                100
              );
              this.isSearchDiv = false;
              this._sharedService.modalinstance.open({
                oncancel: () => {
                  this.isTeamModal = false;
                },
              });
            }
          }
        }
      })

  }
  scrollToDashboardSection(actionType) {
    this.router.navigate(["/dashboard"]);
    this._sharedService.dashboardScrollerSubject.next(actionType);
    this.isSearchDiv = false;
  }
  scrollToSettingSection(actionType) {
    this.router.navigate(["/employee/setting"]);
    this._sharedService.settingScrollerSubject.next(actionType);
    this.isSearchDiv = false;
  }

  onKey(value?: any) {
    this.searchData = this.filterData;
    if (value.length > 0) {
      this.isEmployee = true;
    } else {
      this.isEmployee = false;
    }
    this.searchData = this.search(value);
  }

  search(value: string) {
    let filter = value.toLowerCase();
    let temp = this.searchData.map(section => {
      if (section.section_type == "Employee") {

        return {
          section_type: section.section_type,
          items: section.items.filter(item => item.action_name.toLowerCase().includes(value.toLowerCase())),
          data: section.data.filter(res => res.name.toLowerCase().includes(value.toLowerCase()))
        };
      } else if (section.section_type == "Teams") {

        return {
          section_type: section.section_type,
          items: section.items.filter(item => item.action_name.toLowerCase().includes(value.toLowerCase())),
          data: section.data.filter(res => res.title.rendered.toLowerCase().includes(value.toLowerCase()))
        };
      }
      else {
        return {
          section_type: section.section_type,
          items: section.items.filter(item => item.action_name.toLowerCase().includes(value.toLowerCase()))
        };
      }
    })
    return temp;
  }
  getDevList() {
    this._projectService
      .getUserList(0, 0)
      .subscribe((user: any) => {
        this.searchData.filter(section =>
          section.section_type === 'Employee').map((item: any) => item["data"] = user)
        this.filterData.filter(section =>
          section.section_type === 'Employee').map((item: any) => item["data"] = user)
      })
  }

  getTeam() {
    this._projectService.getTeamList().subscribe(
      (team: any) => {
        this.searchData.filter(section =>
          section.section_type === 'Teams').map((item: any) => item["data"] = team)
        this.filterData.filter(section =>
          section.section_type === 'Teams').map((item: any) => item["data"] = team)
        // this.teamList = team;
      },
      (err) => {
      }
    );
  }

  userDetail(id) {
    this.router.navigate([`/user-details/${id}`]);
    this.isSearchDiv = false;
    this.seachfield.nativeElement.value = "";
    this.getJsonList();
    this.isEmployee = false;
  }
  async openTeamPage(data) {
    // [routerLink]="['/teams']" 
    this.isSearchDiv = false;
    this.seachfield.nativeElement.value = "";
    localStorage.setItem('selectedData', JSON.stringify(data));
    this.router.navigate([`/teams`]);
    this._projectService.updateSelectedTeamData({ data: data })
    this.getJsonList();
    this.isEmployee = false;
    // this.router.navigate(['/teams', { teamData: JSON.stringify(data) }]);
  }
  moveTeamList() {
    if (localStorage.getItem('selectedData')) {
      localStorage.removeItem('selectedData');
    }
    this.isSearchDiv = false;
    this.seachfield.nativeElement.value = "";
    this.router.navigate([`/projects`]);
    this.getJsonList();
    this.isEmployee = false;
  }

  startTour() {
    this.openDialog(
      WelcomeComponent
    ).subscribe((response) => { });
    localStorage.setItem('tourStart', 'true')
  }

  openDialog(someComponent, data = {}): Observable<any> {
    const dialogRef = this.dialog.open(someComponent, {
      data,
      width: "100%",
      height: "100%",
    });
    return dialogRef.afterClosed();
  }

  moveToDownload() {
    window.open('https://supersee.io/download');
  }

  expiringDate(strDate) {
    const expirationDate = new Date(strDate);
    const timeDifference = expirationDate.getTime() - new Date().getTime();
    const daysDifference = timeDifference / (1000 * 60 * 60 * 24);
    let isExpirationNear = daysDifference <= 0;

    return isExpirationNear;
  }

  moveToPaymentHistory() {
    this.router.navigate([`/billings`]);
    this.isProfile = false;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {

    if (window.screen.width <= 1200) {
      this.sideMenuMode = 'over';
    } else if (window.screen.width > 1200) {
      this.sideMenuMode = 'side';
    }
  }
}

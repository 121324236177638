import { Injectable } from '@angular/core';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root', // This makes the service available application-wide
})
export class TimeConversionService {
  constructor() { }

  /**
   * Converts an IST timestamp to the system's local date and time.
   * @param istTimestamp - The timestamp in IST as a string (e.g., "YYYY-MM-DD HH:mm:ss").
   * @returns Local time as a string in the system's format.
   */
  convertISTToLocal(istTimestamp: string): string {
    const istDate = moment(istTimestamp, 'YYYY-MM-DD HH:mm:ss');
    // Convert IST to UTC
    const utcDate = istDate.utcOffset('+05:30', true); // '+05:30' is the IST offset
    // Convert UTC to system's local time
    return utcDate.local().format('YYYY-MM-DD HH:mm:ss');
  }
}

<div class="login_section">
	<!-- Left Section (Image) -->
	<div class="flex_section">
		<div class="flex_item" style="text-align: left;">
			<a href="https://supersee.io/" target="_blank">
				<img class="tagline" src="../../../assets/images/Tagline.webp">
			</a>
		</div>
		<div class="flex_item">
			<h2 class="tagline-text">
				Real Time Monitoring for higher visibility higher productivity
			</h2>
		</div>
		<div class="flex_item">
			<img class="features-image" src="../../../assets/images/features.webp">
		</div>
		<div class="flex_row_third">
			<ng-container *ngFor="let img of featureImages">
				<div [class]="img.classNameTop">
					<img [src]="img.src" [class]="img.className" />
				</div>
			</ng-container>
		</div>
	</div>

	<!-- Right Section (Form) -->
	<!-- Login -->
	<div class="form_section login" *ngIf="type == 'login'">

		<div class="flex_item welcome_section welcome_section_login">
			<h4>Hello!</h4>
			<h3>Welcome Back!</h3>
			<p>Let’s Login to Your Account</p>
		</div>

		<div class="flex_item custom-form">
			<form [formGroup]="loginForm">
				<div class="input_sections">
					<div class="input_box">
						<mat-label>Email</mat-label>
						<mat-form-field class="mat-form custom-mat-form-field" appearance="outline">
							<input matInput formControlName="username" placeholder="Enter your email">
						</mat-form-field>
						<mat-error style="text-align: left;" *ngIf="loginForm.get('username')?.hasError('required') &&
									(loginForm.get('username')?.dirty ||
								 loginForm.get('username')?.touched)">Please enter
							email</mat-error>
					</div>
					<div class="input_box">
						<mat-label class="form-label">Password</mat-label>
						<mat-form-field class="mat-form custom-mat-form-field" appearance="outline">
							<input matInput formControlName="password" placeholder="Enter Password"
								[type]="hide ? 'password' : 'text'" (keydown.enter)="loginOnEnter($event)">
							<button matSuffix class="passBtn" (click)="hide = !hide">
								<mat-icon>{{ hide ? "visibility_off" : "visibility" }}</mat-icon>
							</button>
						</mat-form-field>
						<mat-error *ngIf="loginForm.get('password')?.hasError('required') &&
									(loginForm.get('password')?.dirty ||
								 loginForm.get('password')?.touched)">Password is
							required</mat-error>
					</div>
				</div>
				<div class="button-section">
					<p class="forgot_password" [routerLink]="'/forgot-password'">
						<i>
							Forgot Password?
						</i>
					</p>
					<button mat-raised-button [disabled]="!loginForm.valid || isloading" (click)="userLogin()"
						class="btn signInBtn">Login</button>
				</div>
			</form>
		</div>

		<div class="flex_item link_section">
			<p>Don't have an account? <a (click)="goto('signup')" style="margin-left: 15px;"> Sign Up now </a></p>
		</div>
	</div>

	<!-- SignUp -->
	<div class="form_section signup" *ngIf="type == 'signup'">
		<div class=" flex_item welcome_section">
			<h3>Claim Your Free Trial</h3>
			<h5>Start your free trial of <span> 14 Days</span> today and experience the future of
				productivity!</h5>
			<p>No credit card required</p>
		</div>
		<div class="flex_item custom-form">
			<form [formGroup]="signupForm">
				<div class="input_sections">
					<div class="input_box">
						<mat-label class="form-label">Enter company name<sup>*</sup></mat-label>
						<mat-form-field class="mat-form" appearance="outline">
							<input matInput formControlName="company" placeholder="Enter company name">
						</mat-form-field>
						<mat-error *ngIf="
								   signupForm.get('company')?.hasError('required') &&
									(signupForm.get('company')?.dirty ||
								 signupForm.get('company')?.touched)">
							Please enter company name </mat-error>
					</div>
					<div class="input_box">
						<div class="row" style="margin: 0px !important;">
							<div class="col s12 m6 small_box" style="padding: 0px ">
								<mat-label>First name <sup>*</sup></mat-label>
								<mat-form-field class="mat-form" appearance="outline">
									<input matInput formControlName="firstName" placeholder="First name">
								</mat-form-field>
								<mat-error *ngIf="
										   signupForm.get('firstName')?.hasError('required') &&
											(signupForm.get('firstName')?.dirty ||
										 signupForm.get('firstName')?.touched)">
									Please enter first name </mat-error>
							</div>
							<div class="col s12 m6 small_box" style="padding: 0px ">
								<mat-label>Last name<sup>*</sup></mat-label>
								<mat-form-field class="mat-form" appearance="outline">
									<input matInput formControlName="lastName" placeholder="Last name">
								</mat-form-field>
								<mat-error
									*ngIf="signupForm.get('lastName')?.hasError('required') && (signupForm.get('lastName')?.dirty || signupForm.get('lastName')?.touched)">
									Please enter last name </mat-error>
							</div>
						</div>
					</div>
					<div class="input_box">
						<mat-label>Enter your work email<sup>*</sup></mat-label>
						<mat-form-field class="mat-form" appearance="outline">
							<input matInput formControlName="email" placeholder="Enter your work email">
						</mat-form-field>
						<mat-error
							*ngIf="signupForm.get('email')?.hasError('required') && (signupForm.get('email')?.dirty || signupForm.get('email')?.touched)">
							Please enter your work email </mat-error>
						<mat-error
							*ngIf="signupForm.get('email')?.hasError('pattern') && (signupForm.get('email')?.dirty || signupForm.get('email')?.touched)">
							Please enter a valid email address</mat-error>
					</div>
					<div class="input_box">
						<mat-label>Enter contact number<sup>*</sup></mat-label>

						<div class="row align-items-center" style="width: 100%;">
							<!-- Country Dropdown -->
							<div class="country-dropdown">
								<lib-country-list [selectedCountryConfig]="selectedCountryConfig"
									[countryListConfig]="countryListConfig" (onCountryChange)="onCountryChange($event)"
									[selectedCountryCode]="'in'">
								</lib-country-list>
							</div>
							<!-- Contact Number Input -->
							<div style="width: 100%;">
								<mat-form-field class="mat-form" appearance="outline">
									<input matInput type="number" #contact_Val formControlName="contact"
										placeholder="Enter your contact number">
								</mat-form-field>
								<mat-error
									*ngIf="signupForm.get('contact')?.hasError('required') && (signupForm.get('contact')?.dirty || signupForm.get('contact')?.touched)">
									Please enter contact number </mat-error>
								<mat-error
									*ngIf="signupForm.get('contact')?.hasError('pattern') && (signupForm.get('contact')?.dirty || signupForm.get('contact')?.touched)">
									Please enter valid number </mat-error>
							</div>
						</div>
					</div>
					<div class="input_box">
						<mat-label>Select Team Size<sup>*</sup></mat-label>
						<mat-form-field class="mat-form" appearance="outline">
							<mat-select formControlName="teamSize" placeholder="Select Team Size">
								<mat-option *ngFor="let option of teamSizeOptions" [value]="option.value">
									{{ option.label }}
								</mat-option>
							</mat-select>
						</mat-form-field>
						<mat-error
							*ngIf="signupForm.get('teamSize')?.hasError('required') && (signupForm.get('teamSize')?.dirty || signupForm.get('teamSize')?.touched)">
							Please select a team size
						</mat-error>
					</div>
					<div class="input_box">
						<mat-label>Enter password<sup>*</sup></mat-label>
						<mat-form-field class="mat-form" appearance="outline">
							<input matInput formControlName="password" placeholder="Enter Password"
								[type]="hide ? 'password' : 'text'" autocomplete="new-password">
							<button mat-icon-button matSuffix class="passBtn" (click)="hide = !hide"
								style="color: grey !important;">
								<mat-icon [matTooltip]="hide ? 'Unlock' : 'Lock'">
									<span class="material-symbols-outlined">
										{{ hide ? "visibility_off" : "visibility" }}
									</span>
								</mat-icon>
							</button>
						</mat-form-field>
						<mat-error
							*ngIf="signupForm.get('password')?.hasError('required') && (signupForm.get('password')?.dirty || signupForm.get('password')?.touched)">
							Please enter password. </mat-error>
						<mat-error *ngIf="signupForm.get('password')?.hasError('password')">{{
							signupForm.get('password')?.errors?.password }}</mat-error>
					</div>
					<div class="input_box">
						<mat-label>Confirm password<sup>*</sup></mat-label>
						<mat-form-field class="mat-form" appearance="outline">
							<input matInput formControlName="confirmPassword" [type]="visible ? 'password' : 'text'"
								placeholder="Enter Password">
							<button mat-icon-button matSuffix class="passBtn" (click)="visible = !visible"
								style="color: grey !important;">
								<mat-icon [matTooltip]="visible ? 'Unlock' : 'Lock'">
									<span class="material-symbols-outlined">{{ visible ? "visibility_off" :
										"visibility" }}
									</span>
								</mat-icon>
							</button>
						</mat-form-field>
						<mat-error
							*ngIf="
								signupForm.get('confirmPassword')?.hasError('required') && (signupForm.get('confirmPassword')?.dirty || signupForm.get('confirmPassword')?.touched)">
							Please enter confirm password </mat-error>
						<mat-error
							*ngIf="signupForm.get('confirmPassword')?.value?.length > 0 && signupForm.get('password')?.value != signupForm.get('confirmPassword')?.value  && (signupForm.get('confirmPassword')?.dirty || signupForm.get('confirmPassword')?.touched) && signupForm.errors?.mismatch">
							Password does not match </mat-error>
					</div>
				</div>
				<div class="button-section">
					<button mat-raised-button [disabled]="!signupForm.valid || selectedCode == '' || isDisable"
						(click)="signUpAction()" class="btn signInBtn" extended>
						<span>
							<span
								[ngStyle]="{'visibility': isDisable ?'hidden':'visible','float': 'inline-end','margin-left':'-50px'}">Sign
								Up</span>
						</span>
						<span class="signInBtn_loading">
							<span class="loading_text"
								[ngStyle]="{'visibility': !isDisable ?'hidden':'visible','margin-left':'-50px'}">Sign
								Up you in</span>
							<span class="signInBtn--loading"
								[ngStyle]="{'visibility': !isDisable ?'hidden':'visible','position':'relative','margin-left': '10px'}"></span>
						</span>
					</button>
				</div>
			</form>
		</div>
		<div class="flex_item link_section">
			<p>Already have an account? <a (click)="goto('login')" style="margin-left: 15px;">Log In</a>
			</p>
		</div>
	</div>
</div>

<app-loader *ngIf="loading"></app-loader>